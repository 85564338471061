import React from 'react'
import { Surface, Text, Button, useTheme } from 'react-native-paper'
import { View, Image } from 'react-native'
import { useTranslation } from 'react-i18next'

const OffersCard = (props) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { title, description, label, onPress, imageURL = '', disabled } = props

  return (
    <Surface
      style={{
        flexDirection: 'row',
        marginTop: 8,
      }}
    >
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 8,
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          backgroundColor: '#CBF0FF',
          borderRadius: 24,
          position: 'relative',
        }}
      >
        <View
          style={{
            flex: 1,
            marginRight: 30,
            justifyContent: 'flex-start',
            flexDirection: 'row',
            gap: 20,
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              backgroundColor: '#fff',
              borderRadius: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              source={imageURL}
              style={{
                resizeMode: 'contain',
                height: 36,
                width: 36,
              }}
            />
          </View>

          <View style={{ flex: 1 }}>
            <Text variant='bodyLarge' style={{ color: colors.neutral }}>
              {title}
            </Text>

            <Text
              variant='bodyMedium'
              style={{ color: colors.neutral, paddingVertical: 6 }}
            >
              {description}
            </Text>
            <Surface
              elevation={0}
              style={{
                backgroundColor: '#fff',
                borderRadius: 4,
              }}
            >
              <Text
                variant='labelMedium'
                style={{
                  color: colors.neutral,
                  paddingHorizontal: 3,
                  paddingVertical: 3,
                  textAlign: 'center',
                }}
              >
                {label}
              </Text>
            </Surface>
            <View style={{ paddingVertical: 10 }}>
              <Button
                mode='outlined'
                style={{
                  borderColor: colors.primary,

                  height: 32,
                  width: 115,
                  justifyContent: 'center',
                }}
                textColor={colors.neutral}
                onPress={onPress}
                disabled={disabled}
              >
                {t('ACTIONS.REDEEM')}
              </Button>
            </View>
          </View>
        </View>
        {disabled && (
          <View
            style={{
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              height: '100%',
              justifyContent: 'center',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <Text
              variant='headlineLarge'
              style={{ fontWeight: '400', color: colors.textPrimary }}
            >
              SOLD OUT
            </Text>
          </View>
        )}
      </View>
    </Surface>
  )
}

export default OffersCard
