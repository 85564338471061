import React from 'react'
import { Surface, Text, IconButton, useTheme, Button } from 'react-native-paper'
import { Image, StyleSheet, View } from 'react-native'
import { Container } from '@nowon/container'
import { Icon } from '@nowon/icons'
import { useTranslation } from 'react-i18next'
import Camera from '../../assets/camera.png'
import RapCitySmallLogo from '../../assets/rapCitySmallLogo.png'

export const PhotoChallenge = ({ navigation }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <Container>
      <Surface elevation={0} style={styles.topBar}>
        <Image
          source={RapCitySmallLogo}
          style={{ resizeMode: 'contain', width: 32, height: 29 }}
        />
        <Surface style={{ borderRadius: 50 }}>
          <IconButton
            size={10}
            icon={
              <Icon name='CloseIcon' width={10} color={colors.textPrimary} />
            }
            onPress={() => navigation.navigate('challenges')}
          />
        </Surface>
      </Surface>

      <Surface elevation={0} style={{ height: '100vh' }}>
        <Surface elevation={0} style={{ marginVertical: 21, gap: 20 }}>
          <Text variant='labelMedium' style={{ color: colors.primary }}>
            {t('CHALLENGES')} {'>'} {t('PHOTO_CHALLENGE')}
          </Text>
          <Text variant='headlineLarge' style={{ color: colors.primary }}>
            {t('PHOTO_CHALLENGE')}
          </Text>
        </Surface>

        <Surface elevation={0} style={{ gap: 17 }}>
          <Surface
            elevation={0}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              gap: 11,
            }}
          >
            <View
              style={[styles.avatar, { backgroundColor: colors.textPrimary }]}
            >
              <Image style={{ height: 48, width: 48 }} source={Camera} />
            </View>
            <Surface
              elevation={0}
              style={{ justifyContent: 'center', width: '72%', gap: 12 }}
            >
              <Text variant='titleMedium' style={{ color: colors.primary }}>
                {t('PHOTO_BOOTH')}
              </Text>

              <Button
                mode='outlined'
                contentStyle={{ height: 32 }}
                style={{ width: 108, borderColor: colors.primary }}
                labelStyle={{ fontSize: 14, fontWeight: 300 }}
              >
                {t('ACTIONS.VIEW_MAP')}
              </Button>
            </Surface>
          </Surface>
        </Surface>
        <Surface
          elevation={0}
          style={{
            alignItems: 'center',
            paddingBottom: '30%',
            gap: 12,
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          <View style={{ alignItems: 'center' }}>
            <Button
              mode='contained'
              contentStyle={{ height: 48, backgroundColor: '#6EC1E4' }}
              style={{
                borderRadius: 24,
                width: '186px',
              }}
              labelStyle={{
                color: 'white',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 400,
              }}
              onPress={() => {
                navigation.navigate('scanner')
              }}
            >
              {t('ACTIONS.SCAN_QR')}
            </Button>
          </View>
          <Text
            variant='titleMedium'
            style={{ textDecorationLine: 'Underline', color: colors.primary }}
          >
            {t('INVITE_YOUR_FRIENDS')}
          </Text>
        </Surface>
      </Surface>
    </Container>
  )
}
const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: '#ffffff',
    paddingHorizontal: '24px',
    paddingTop: '33px',
    paddingBottom: '33px',
    minWidth: '327px',
    minHeight: '510px',
    borderRadius: '16px',
    justifyContent: 'space-between',
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    alignItems: 'center',
  },
  avatar: {
    height: 80,
    width: 80,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
