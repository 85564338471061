import React, { useState } from 'react'
import { View, Image, ScrollView, Dimensions } from 'react-native'
import { ActivityIndicator, useTheme } from 'react-native-paper'

const ImageSlider = (props) => {
  const { images } = props
  let { height } = Dimensions.get('window')
  const { width } = Dimensions.get('window')
  const [isLoading, setIsLoading] = useState(true)
  height -= 80
  const [pictureIndex, setPictureIndex] = useState(0)
  const handleOnScroll = ({ nativeEvent }) => {
    if (width > 500) {
      if (520 - 60 < nativeEvent.contentOffset.x) {
        setPictureIndex(1)
      } else {
        setPictureIndex(0)
      }
    } else if (width - 60 < nativeEvent.contentOffset.x) {
      setPictureIndex(1)
    } else {
      setPictureIndex(0)
    }
  }
  const { colors } = useTheme()
  return (
    <View style={{ alignItems: 'center' }}>
      <View style={{ maxWidth: 520, width, height }}>
        {isLoading && (
          <View
            style={{
              position: 'absolute',
              top: '50%',
              alignSelf: 'center',
            }}
          >
            <ActivityIndicator color={{ color: colors.primary }} size='large' />
          </View>
        )}
        <ScrollView
          pagingEnabled
          horizontal
          showsHorizontalScrollIndicator={false}
          onScroll={handleOnScroll}
        >
          {images.map((image) => (
            <Image
              source={{ uri: image }}
              style={{ maxWidth: 520, width, height, resizeMode: 'contain' }}
              onLoad={() => setIsLoading(false)}
            />
          ))}
        </ScrollView>
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: 70,
          width: 70,
          height: 8,
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 4,
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: 35,
            height: 8,
            background: pictureIndex === 0 ? '#6EC1E4' : 'transparent',
            borderRadius: 4,
          }}
        />
        <View
          style={{
            width: 35,
            height: 8,
            background: pictureIndex === 1 ? '#6EC1E4' : 'transparent',
            borderRadius: 4,
          }}
        />
      </View>
    </View>
  )
}

export default ImageSlider
