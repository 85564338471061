/* eslint-disable import/no-relative-packages */
import React, { useState } from 'react'
import { Modal, Image, StyleSheet, View } from 'react-native'
import { Button, Surface } from 'react-native-paper'
import { Text } from '@nowon/components'
import Logo from '../core/src/assets/logo.png'
import IntroImage from '../core/src/assets/introImage.png'
import BlurWidget from '../components/src/Blur'
import { updateUserIsNewUser } from '../core/src/apiGraphql'

const SlidePage = ({ t, colors, slideContent }) => (
  <Surface
    elevation={0}
    style={{
      justifyContent: 'center',
      paddingHorizontal: 20,
    }}
  >
    {slideContent.headerImage !== null && (
      <Image
        source={slideContent.headerImage}
        style={{
          width: 122,
          height: 29,
          alignSelf: 'center',
        }}
        resizeMode='contain'
      />
    )}
    <Text
      variant='titleLarge'
      style={{ paddingVertical: 20, color: colors.primary }}
    >
      {t(slideContent.title)}
    </Text>
    <Text
      variant='labelMedium'
      colorVariant='textPrimary'
      style={{ marginBottom: 20 }}
    >
      {t(slideContent.description)}
    </Text>
    {slideContent.image !== null && (
      <Image
        source={slideContent.image}
        style={{
          height: 150,
          width: '100%',
          borderRadius: 8,
          alignSelf: 'center',
        }}
        resizeMode='contain'
      />
    )}
    <Button
      onPress={slideContent.function}
      mode='outlined'
      style={{
        borderRadius: 50,
        width: 140,
        borderColor: colors.primary,
        alignSelf: 'center',
      }}
      labelStyle={{
        color: 'white',
        fontSize: '16px',
        fontWeight: 400,
      }}
    >
      {t(slideContent.cta)}
    </Button>
  </Surface>
)

const IntroSlide = (props) => {
  const { modalVisible, setModalVisible, colors, t, userId, setUser } = props
  const [slideNumber, setSlideNumber] = useState(1)
  const slideContent = [
    {
      slide1: {
        title: 'INTO_SLIDE_1_TITLE',
        description: 'INTO_SLIDE_1_DESCRIPTION',
        cta: 'ACTIONS.NEXT',
        headerImage: Logo,
        image: null,
        function: () => setSlideNumber(2),
      },
      slide2: {
        title: 'INTO_SLIDE_2_TITLE',
        description: 'INTO_SLIDE_2_DESCRIPTION',
        cta: 'ACTIONS.GET_STARTED',
        headerImage: null,
        image: IntroImage,
        function: async () => {
          const isNewUserResponse = await updateUserIsNewUser({
            userId,
            isNewUser: true,
          })
          setUser(isNewUserResponse)
          setModalVisible(false)
          setSlideNumber(1)
        },
      },
    },
  ]
  return (
    <Modal
      animationType='slide'
      transparent
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View style={styles.containerMain}>
        <BlurWidget />
        <Surface
          elevation={0}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            marginHorizontal: 24,
            borderRadius: 24,
            paddingVertical: 32,
            // height: 500,
          }}
        >
          <SlidePage
            t={t}
            colors={colors}
            slideContent={
              slideNumber === 1
                ? slideContent[0].slide1
                : slideContent[0].slide2
            }
          />
        </Surface>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  containerMain: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    flex: 1,
    paddingLeft: 24,
    paddingRight: 24,
    paddingVertical: 32,
  },
})

export default IntroSlide
