import React from 'react'
import { View } from 'react-native'

const Container = ({ children, style }) => (
  <View
    style={[
      {
        paddingHorizontal: 24,
        backgroundColor: '#16181F',
        minHeight: '100vh',
        paddingVertical: 32,
        alignItems: 'center',
        position: 'relative',
        zIndex: -100,
      },
      style,
    ]}
  >
    <View style={{ maxWidth: 400, width: '100%', height: '100%' }}>
      {children}
    </View>
  </View>
)

export default Container
