/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import { Container } from '@nowon/container'
import { View, Image, TouchableOpacity, Platform } from 'react-native'
import { Surface, Text, Button, useTheme } from 'react-native-paper'
import { RedeemInfoCard } from '@nowon/card'
import { useQuery } from '@tanstack/react-query'
import { useIsFocused } from '@react-navigation/native'
import { Icon } from '@nowon/icons'
import GiftBox from '../../assets/giftBox.png'
import Tick from '../../assets/tick.png'
import {
  getOffers,
  updateRedeemedPoints,
  getUserById,
  createPurchasedProduct,
} from '../../apiGraphql'

export const Redeem = (props) => {
  const { navigation, user, t, setUser } = props
  const { colors } = useTheme()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isInSufficient, setIsInSufficient] = useState(false)
  const id = Number(props?.route?.params?.id)
  const [availablePoints, setAvailablePoints] = useState()
  const [earnedPoints, setEarnedPoints] = useState()
  const isFocused = useIsFocused()
  useEffect(() => {
    setAvailablePoints(props?.route?.params?.availablePoints)
    setEarnedPoints(props?.route?.params?.earnedPoints)
  }, [
    props?.route?.params?.availablePoints,
    props?.route?.params?.earnedPoints,
  ])

  const { data: offersDetail } = useQuery(
    [`getOffers-${id}`],
    async () => {
      const response = await getOffers()
      const filteredData = response?.find((r) => r.SK === id)
      return filteredData
    },
    {
      enabled: isFocused && !!id,
    },
    {
      initialData: {},
    },
  )
  const handleBackNavigation = (goBack) => {
    console.log({ isInSufficient })
    if ((Platform.OS === 'web' && isInSufficient) || goBack) {
      setIsVisible(false)
      window.history.go(-1)
    } else if (isInSufficient) {
      setIsVisible(false)
      navigation.goBack()
    } else if (isInSufficient === false) {
      setIsVisible(false)
    }
  }

  const submitRedeem = async () => {
    try {
      const redeemedPoints =
        Number(user?.redeemedPoints) + Number(offersDetail?.pointsRequired)
      const newAvailableValue = earnedPoints - redeemedPoints
      if (newAvailableValue >= 0) {
        setAvailablePoints(newAvailableValue)
        await createPurchasedProduct({
          points: Number(offersDetail?.pointsRequired),
          productId: offersDetail?.SK,
          userId: user?.userId,
          title: offersDetail?.title,
          offerType: offersDetail.offerType,
        })
        const response = await updateRedeemedPoints({
          userId: user?.userId,
          redeemedPoints:
            Number(user?.redeemedPoints) + Number(offersDetail?.pointsRequired),
        })
        const userResponse = await getUserById(user?.userId)
        setUser(userResponse)
        if (response) {
          setIsSuccess(true)
        }
      } else {
        setIsInSufficient(true)
      }
      setIsVisible(true)
    } catch (error) {}
  }

  return (
    <TouchableOpacity onPress={() => setIsVisible(false)}>
      <TouchableOpacity
        onPress={handleBackNavigation}
        style={{
          width: 32,
          height: 32,
          justifyContent: 'flex-end',
          borderRadius: 50,
          position: 'absolute',
          top: 10,
          right: 10,
        }}
      >
        <Icon name='CloseIcon' color='black' width={14} />
      </TouchableOpacity>
      <Container
        style={{
          backgroundColor: '#CBF0FF',
        }}
      >
        <View>
          <Surface
            elevation={0}
            style={{
              paddingTop: 22,
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Surface elevation={0}>
              <Surface
                elevation={0}
                style={{
                  borderRadius: 55,
                  height: 96,
                  width: 96,
                  marginBottom: 30,
                  backgroundColor: 'rgba(0, 0, 0,0.05)',
                  alignSelf: 'center',
                }}
              >
                <Image
                  source={!isSuccess ? GiftBox : Tick}
                  style={{
                    height: 96,
                    width: 96,
                    alignSelf: 'center',
                  }}
                />
              </Surface>
              <Text
                variant='headlineSmall'
                style={{
                  color: colors.neutral,
                  marginBottom: 31,
                  width: 327,
                  textAlign: 'center',
                }}
              >
                {!isSuccess ? t('REDEEM') : offersDetail?.redeemMessage}{' '}
                {offersDetail?.title}
              </Text>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: '31px',
                  marginBottom: '25px',
                }}
              >
                <View
                  style={{
                    paddingHorizontal: 25,
                    paddingVertical: 20,
                    borderRadius: '16px',
                    backgroundColor: 'rgba(0, 0, 0,0.05)',
                    width: '241px',
                    gap: 16,
                  }}
                >
                  <Surface
                    elevation={0}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        color: '#000000',
                      }}
                    >
                      {t('POINTS_AVAILABLE')}
                    </Text>

                    <Text
                      style={{
                        color: '#000000',
                      }}
                    >
                      {availablePoints}
                    </Text>
                  </Surface>
                  {!isSuccess && (
                    <Surface
                      elevation={0}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text
                        style={{
                          color: '#000000',
                        }}
                      >
                        {t('POINTS_REQUIRED')}
                      </Text>
                      <Text
                        style={{
                          color: '#000000',
                        }}
                      >
                        {Number(offersDetail?.pointsRequired)}
                      </Text>
                    </Surface>
                  )}
                </View>
              </View>
            </Surface>

            {!isSuccess && (
              <Button
                style={{
                  height: 48,
                  width: 186,
                  marginBottom: 24,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: colors.primary,
                }}
                mode='contained'
                onPress={() => submitRedeem()}
                labelStyle={{
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: 20,
                  color: colors.textPrimary,
                }}
              >
                {t('ACTIONS.REDEEM')}
              </Button>
            )}
            <Button
              style={{
                height: 48,
                width: 186,
                marginBottom: 64,
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: colors.primary,
              }}
              mode='outlined'
              onPress={() => handleBackNavigation(true)}
              labelStyle={{
                fontSize: 16,
                fontWeight: 400,
                lineHeight: 20,
                color: '#222222',
              }}
            >
              {!isSuccess ? t('ACTIONS.CANCEL') : t('ACTIONS.BACK')}
            </Button>
          </Surface>
        </View>
      </Container>
      <RedeemInfoCard
        isVisible={isVisible}
        colors={colors}
        t={t}
        isInSufficient={isInSufficient}
        redeemInfo={
          // eslint-disable-next-line no-nested-ternary
          offersDetail?.offerType === 'RAFFLE'
            ? 'RAFFLE'
            : offersDetail?.SK === 2 || offersDetail?.SK === 3
            ? 'POP_UP'
            : 'NOT_A_RAFFLE'
        }
        onClose={() => {
          handleBackNavigation()
        }}
      />
    </TouchableOpacity>
  )
}
