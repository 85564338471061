import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgPieIcon = (props) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12 24C5.3728 24 0 18.6272 0 12C0 5.3724 5.3728 0 12 0C18.6272 0 24 5.3724 24 12C24 18.6272 18.6272 24 12 24ZM12 1.6C6.2564 1.6 1.6 6.2564 1.6 12C1.6 17.7436 6.2564 22.4 12 22.4C17.7436 22.4 22.4 17.7436 22.4 12C22.4 6.2564 17.7436 1.6 12 1.6ZM12 20.8C7.14 20.8 3.2 16.86 3.2 12H12C12 8.176 12 4.4344 12 3.2C16.86 3.2 20.8 7.14 20.8 12C20.8 16.86 16.86 20.8 12 20.8Z'
      fill='#CCCCCC'
    />
  </Svg>
)

export default SvgPieIcon
