import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { TextInput, HelperText, useTheme } from 'react-native-paper'
import PropTypes from 'prop-types'

const PasswordTextInput = (props) => {
  const [isVisible, setIsVisible] = useState(true)
  const { colors } = useTheme()
  const [passwordValue, setPasswordValue] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(true)

  const validatePassword = (password) => {
    if (password) {
      const re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_=~+-])[A-Za-z\d!@#$%^&*_=~+-]{8,}$/
      return re.test(password)
    }
    return true
  }
  useEffect(() => {
    setIsVisible(!validatePassword(passwordValue))
  }, [passwordValue])
  function renderErrorText() {
    if (isVisible && props?.errorMessage && props.continueClicked) {
      return (
        <HelperText type='error' visible={!validatePassword(passwordValue)}>
          {props.errorMessage}
        </HelperText>
      )
    }
  }
  return (
    <View>
      <TextInput
        {...props}
        onChangeText={(text) => {
          setPasswordValue(text)
          props.onValidate(validatePassword(text))
          props.onChangeText(text)
        }}
        placeholderTextColor={colors.onSurfacePlaceholder}
        secureTextEntry={showPassword}
        right={
          <TextInput.Icon
            icon={showPassword ? 'eye-off' : 'eye'}
            onPress={() => setShowPassword(!showPassword)}
          />
        }
        theme={{
          colors: {
            onSurface: colors.surface,
          },
        }}
      />
      {renderErrorText()}
    </View>
  )
}

PasswordTextInput.defaultProps = {
  onValidate: () => {},
  errorMessage: 'Password is invalid',
}

PasswordTextInput.propTypes = {
  onValidate: PropTypes.func,
  errorMessage: PropTypes.string,
}

export default PasswordTextInput
