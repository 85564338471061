import React from 'react'
import { useTheme, Surface } from 'react-native-paper'
import { Image, View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import { Icon } from '@nowon/icons'
import CircularProgress from '@nowon/circularProgress'
import ProfileImage from 'core/src/assets/profileImage.png'

const UserCard = (props) => {
  const {
    userImage,
    adminImage,
    userName,
    percentage,
    matchedSongs,
    matchedGenres,
    matchedArtist,
  } = props
  const { t } = useTranslation()
  const { colors } = useTheme()

  const matchPercentArray = [
    {
      title: 'MATCH_SONGS',
      icon: 'MusicCordIcon',
      percentage: matchedSongs,
      circleColor: '#880EAE',
    },
    {
      title: 'MATCH_GENRE',
      icon: 'GenreIcon',
      percentage: matchedGenres,
      circleColor: '#B64D53',
    },
    {
      title: 'MATCH_ARTISTS',
      icon: 'ArtistIcon',
      percentage: matchedArtist,
      circleColor: '#2E7BA1',
    },
  ]

  return (
    <View style={{ paddingBottom: 20 }}>
      <Surface elevation={0} style={styles.container}>
        <Text
          variant='headlineMedium'
          style={{
            fontWeight: 600,
            color: colors.textPrimary,
          }}
        >
          {userName} {t('DNA')}
        </Text>
        <View style={{ width: '70%' }}>
          <View style={styles.imageContainer}>
            <View style={{ position: 'relative' }}>
              <Image
                source={adminImage || ProfileImage}
                style={{
                  height: 90,
                  width: 90,
                  borderRadius: 100,
                }}
              />
              <View style={{ position: 'absolute', bottom: 1, right: 0 }}>
                <Icon name='HeadPhoneIcon' color='white' width={30} />
              </View>
            </View>
            <View style={styles.percentageRound}>
              <Text
                variant='titleLarge'
                style={{
                  fontWeight: 400,
                  color: colors.textPrimary,
                }}
              >
                {percentage?.toFixed(0)}%
              </Text>
            </View>
            <View>
              <Image
                source={userImage || ProfileImage}
                style={{
                  height: 90,
                  width: 90,
                  borderRadius: 100,
                }}
              />
              <View style={{ position: 'absolute', bottom: 1, left: 0 }}>
                <Icon name='HeadPhoneIcon' color='white' width={30} />
              </View>
            </View>
          </View>
        </View>
      </Surface>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {matchPercentArray.map((item) => (
          <View style={{ gap: 10, alignItems: 'center' }}>
            <Text variant='labelMedium' style={{ color: '#ffffff33' }}>
              {t(item.title)}
            </Text>
            <View style={styles.matchPercent}>
              <Text variant='labelLarge'>{item.percentage?.toFixed(0)}%</Text>
              <CircularProgress
                bgColor='rgba(255, 255, 255, 0.2)'
                pgColor={item.circleColor}
                size={38}
                strokeWidth={3}
                iconName={item.icon}
                progressPercent={item.percentage}
              />
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  percentageRound: {
    height: 55,
    width: 55,
    backgroundColor: '#1DE9B6',
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 30,
  },
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'space-between',
    gap: 6,
  },
  container: {
    paddingBottom: 20,
    gap: 20,
    alignItems: 'center',
  },
  matchPercent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
})

export default UserCard
