import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgYoutubeIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 20 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M19.167 5.092a7.083 7.083 0 0 0-.759-3.442 2.433 2.433 0 0 0-1.433-.833A65.3 65.3 0 0 0 10 .559c-2.32-.04-4.64.043-6.95.25-.457.083-.88.297-1.216.616C1.084 2.117 1 3.3.917 4.3a40.242 40.242 0 0 0 0 5.4c.024.563.108 1.122.25 1.667.1.42.304.81.592 1.133.339.336.772.563 1.241.65a37.64 37.64 0 0 0 5.417.275c2.917.042 5.475 0 8.5-.233a2.4 2.4 0 0 0 1.275-.65c.233-.233.407-.52.508-.833a8.817 8.817 0 0 0 .434-2.834c.033-.466.033-3.283.033-3.783ZM8.117 9.375V4.217l4.933 2.592c-1.383.766-3.208 1.633-4.933 2.566Z'
      fill='#000'
    />
  </Svg>
)

export default SvgYoutubeIcon
