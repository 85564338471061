import React, { useEffect } from 'react'
import { Surface, Text, TextInput, Button, useTheme } from 'react-native-paper'
import { View } from 'react-native'
import { EmailTextInput, PasswordTextInput } from '@nowon/components'
import { BackgroundContainer } from '@nowon/container'
import BlurWidget from '@nowon/components/src/Blur'
import UseResetPassword from './useResetPassword'

export const ResetPassword = (props) => {
  const { colors } = useTheme()
  const {
    submitNewPassword,
    email,
    password,
    setEmail,
    setPassword,
    setConfirmationCode,
    confirmationCode,
  } = UseResetPassword()
  const { t } = props

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    setConfirmationCode(code);
  }, [])

  return (
    <BackgroundContainer>
      <>
        <BlurWidget borderRadius={16} />
        <Surface elevation={0} style={{ gap: 20 }}>
          <Text
            variant='headlineLarge'
            style={{
              color: colors.primary,
            }}
          >
            {t('RESET_PASSWORD')}
          </Text>
          <TextInput
            mode='outlined'
            placeholder={t('CONFIRMATION_CODE')}
            style={{
              backgroundColor: colors.neutralLight,
            }}
            placeholderTextColor={colors.onSurfacePlaceholder}
            theme={{
              colors: {
                onSurface: colors.surface,
              },
            }}
            value={confirmationCode}
            onChangeText={(text) => setConfirmationCode(text)}
          />
          <EmailTextInput
            mode='outlined'
            placeholder={t('EMAIL_ID')}
            style={{
              backgroundColor: colors.neutralLight,
            }}
            value={email}
            onChangeText={(text) => setEmail(text.toLowerCase().trim())}
            errorMessage={t('EMAIL_INVALID')}
          />
          <View>
            <PasswordTextInput
              mode='outlined'
              placeholder={t('PASSWORD')}
              secureTextEntry
              style={{
                backgroundColor: colors.neutralLight,
              }}
              value={password}
              onChangeText={(text) => setPassword(text)}
              errorMessage={t('PASSWORD_VALIDATION')}
            />
          </View>

          <Button
            mode='contained'
            contentStyle={{ height: 48, backgroundColor: colors.primary }}
            style={{
              borderRadius: 8,
              width: '287px',
            }}
            labelStyle={{
              color: colors.neutralLight,
              fontSize: '16px',
              fontWeight: 400,
            }}
            onPress={() => submitNewPassword()}
          >
            {t('ACTIONS.UPDATE')}
          </Button>
          <Surface
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            elevation={0}
          >
            <Text
              variant='labelLarge'
              style={{
                color: '#509AC4',
              }}
            >
              {t('SUPPORT')}
            </Text>
          </Surface>
        </Surface>
      </>
    </BackgroundContainer>
  )
}
