import { View, Image, TouchableOpacity, Platform } from 'react-native'
import React from 'react'
import { Icon } from '@nowon/icons'
import { Avatar } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import Logo from '../../assets/logo.png'
import ProfileImage from '../../assets/profileImage.png'
import RapCitySmallLogo from '../../assets/rapCitySmallLogo.png'

const Header = (props) => {
  const {
    hasClose = false,
    hasSmallLogo,
    hasRightClose,
    hasHeaderLogo = true,
    profileImg = '',
  } = props
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          height: 73,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 15,
          gap: 30,
          backgroundColor: '#16181F',
        }}
      >
        <HeaderLeft hasClose={hasClose} hasSmallLogo={hasSmallLogo} />
        <HeaderTitle hasHeaderLogo={hasHeaderLogo} />
        <HeaderRight hasRightClose={hasRightClose} profileImg={profileImg} />
      </View>

      <View
        style={{
          width: '100%',
          height: 0.2,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />
    </>
  )
}

const HeaderTitle = (props) => {
  const { hasHeaderLogo } = props
  const navigation = useNavigation()
  if (hasHeaderLogo) {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('home')
        }}
        style={{
          height: '30px',
          width: '150px',
        }}
      >
        <Image
          source={Logo}
          style={{
            width: '100%',
            height: '100%',
          }}
          resizeMode='contain'
        />
      </TouchableOpacity>
    )
  }
  return <View />
}

const HeaderLeft = (props) => {
  const { hasSmallLogo, hasClose } = props
  const navigation = useNavigation()
  if (hasSmallLogo) {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('home')
        }}
      >
        <Image
          source={RapCitySmallLogo}
          style={{ resizeMode: 'contain', width: 32, height: 29 }}
        />
      </TouchableOpacity>
    )
  }
  const handleBackNavigation = () => {
    if (Platform.OS === 'web' && hasClose) {
      window.history.go(-1)
    } else if (Platform.OS !== 'web' && hasClose) {
      navigation.goBack()
    } else {
      navigation.navigate('menu')
    }
  }
  return (
    <TouchableOpacity
      onPress={handleBackNavigation}
      style={{
        width: '50px',
      }}
    >
      <Icon
        name={hasClose ? 'CloseIcon' : 'MenuIcon'}
        color='white'
        width={28}
      />
    </TouchableOpacity>
  )
}

const HeaderRight = (props) => {
  const { hasRightClose, profileImg } = props
  const navigation = useNavigation()

  const handleBackNavigation = () => {
    if (Platform.OS === 'web') {
      window.history.go(-1)
    } else {
      navigation.goBack()
    }
  }

  if (hasRightClose) {
    return (
      <TouchableOpacity
        onPress={handleBackNavigation}
        style={{
          width: 32,
          height: 32,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 50,
        }}
      >
        <Icon name='CloseIcon' color='white' width={14} />
      </TouchableOpacity>
    )
  }
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('profile')
      }}
    >
      {/* <Image source={ProfileImage} style={{ width: 50, height: 50 }} /> */}
      <View
        style={{
          flexDirection: 'row-reverse',
          position: 'relative',
          width: 50,
          height: 50,
        }}
      >
        {profileImg ? (
          <Avatar.Image
            size={50}
            source={profileImg}
            style={{ backgroundColor: '#333' }}
          />
        ) : (
          <Avatar.Image size={50} source={ProfileImage} />
        )}
      </View>
    </TouchableOpacity>
  )
}

export default Header
