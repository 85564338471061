import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgMapIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 16 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M5.667 12.667 1 11.333v-10l4.667 1.334v10Zm4.666-1.334-4.666 1.334v-10l4.666-1.334v10ZM15 12.667l-4.667-1.334v-10L15 2.667v10Z'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
)

export default SvgMapIcon
