import React from 'react'
import { TouchableOpacity, ImageBackground } from 'react-native'
import { useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Icon } from '@nowon/icons'
import { Text } from '@nowon/components'
import Map from '../../assets/map.png'

const FestiveCard = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  return (
    <TouchableOpacity onPress={() => navigation.navigate('festivalMap')}>
      <ImageBackground
        source={Map}
        style={{
          height: 153,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        imageStyle={{ borderRadius: 24 }}
        resizeMode='cover'
      >
        <Text
          variant='brandBodyLarge'
          colorVariant='textPrimary'
          style={{
            flexDirection: 'row',
            paddingVertical: 35,
            justifyContent: 'center',
          }}
        >
          {t('VIEW_FESTIVE')}
          <Icon
            name='ArrowRightIcon'
            width={19}
            height={11}
            color={colors.textPrimary}
          />
        </Text>
      </ImageBackground>
    </TouchableOpacity>
  )
}

export default FestiveCard
