import React, { useEffect } from 'react'
import {
  getContestArtistProfiles,
  getVoteTracker,
} from '../apiGraphql'
import { useQuery } from '@tanstack/react-query'
import { store } from '../store/storeProvider'
import { isVoted } from '../utils/contestHelper'
import { orderBy } from 'lodash'

const ContestLayout = ({ user, children }) => {
  const { data: voteTracker } = useQuery(
    ['getVoteTracker'],
    async () => {
      const response = await getVoteTracker(user?.userId)

      store.dispatch({
        type: 'SET_VOTE_TRACKER',
        payload: response,
      })

      return response
    },
    {
      enabled: !!user,
    },
  )

  const { data: artistProfilesData } = useQuery(
    ['getContestArtistProfiles'],
    async () => {
      const response = await getContestArtistProfiles()
      if (response) {
        const ordered = orderBy(response, 'name')

        store.dispatch({
          type: 'SET_ARTIST_PROFILES',
          payload: ordered,
        })

        return ordered
      }

      return []
    },
  )

  useEffect(() => {
    if (
      voteTracker &&
      voteTracker.latestVotedAt &&
      isVoted(voteTracker.latestVotedAt)
    ) {
      if (voteTracker && voteTracker.latestVotedArtistId && artistProfilesData) {
        const artist = artistProfilesData.find(
          (x) => x.guid === voteTracker.latestVotedArtistId,
        )

        store.dispatch({
          type: 'SET_VOTED_ARTIST',
          payload: artist,
        })
      }
    }
  }, [voteTracker, artistProfilesData])

  useEffect(() => {
    const endDateTime = new Date(process.env.REACT_APP_CONTEST_END_DATE);
    if (new Date() > endDateTime) {
      store.dispatch({
        type: 'SET_IS_ENDED',
        payload: true,
      })
    }
  }, [])

  return (
    <>{children}</>
  )
}

export default ContestLayout
