// this is an auto generated file. This will be overwritten

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $userId: String!
    $userName: String
    $firstName: String
    $lastName: String
    $mobileNumber: String
    $dateOfBirth: String
    $country: String
    $doorNumber: String
    $city: String
    $street: String
    $zipCode: String
    $gender: String
  ) {
    updateUser(
      userId: $userId
      userName: $userName
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      dateOfBirth: $dateOfBirth
      country: $country
      doorNumber: $doorNumber
      city: $city
      street: $street
      zipCode: $zipCode
      gender: $gender
    ) {
      challengesAchieved
      city
      country
      dateOfBirth
      doorNumber
      earnedPoints
      firstName
      email
      gender
      lastName
      photoFolderCode
      mobileNumber
      referralCode
      redeemedPoints
      street
      ticketNumber
      totalChallenges
      userDeletionRequested
      userId
      userName
      zipCode
      isNewUser
    }
  }
`
export const updateOnboardingAnswers = /* GraphQL */ `
  mutation UpdateOnboardingAnswers(
    $userId: String!
    $questionId: Int!
    $answers: [String]!
  ) {
    updateOnboardingAnswers(
      userId: $userId
      questionId: $questionId
      answers: $answers
    ) {
      questionId
      questionType
      questionText
      status
      answerOptions
      answers
      additionalInfo
      additionalInfoTriggerAnswerOption
    }
  }
`

export const updateChallengeStatusToCompletedById = `mutation  updateChallengeStatusToCompletedById($challengeId: Int!, $userId: String!, $year: String) {
  updateChallengeStatusToCompletedById(challengeId: $challengeId, userId: $userId, year: $year)
 {
  challengeId
  challengeType
  couponCode
  description
  discount
  expiryDate
  status
  title
  totalPoints
  }
}
`
export const updateChallengeStatusByQrCode = `mutation updateChallengeStatusByQrCode($qrCode: String!, $userId: String!){
   updateChallengeStatusByQrCode(qrCode: $qrCode, userId: $userId)
 {
    challengeId
    challengeType
    couponCode
    description
    discount
    expiryDate
    status
    title
    totalPoints
  }
}
`
export const updateRedeemedPoints = ` mutation updateRedeemedPoints($userId: String!, $redeemedPoints: Int!){
   updateRedeemedPoints(userId: $userId, redeemedPoints: $redeemedPoints)
 {
    challengesAchieved
    couponsWon
    dateOfBirth
    earnedPoints
    email
    firstName
    lastName
    mobileNumber
    redeemedPoints
    referralCode
    ticketNumber
    totalChallenges
    userId
  }
}`

export const createPurchasedProduct = `
mutation createPurchasedProduct($productId: Int!, $points: Int!, $title: String!, $userId: String!, $offerType: String!) {
  createPurchasedProduct(productId: $productId, title: $title, userId: $userId, points: $points, offerType: $offerType) {
    points
    status
    redeemedDate
    title
    offerType
  }
}
`

export const updateNotificationStatusToRead = `
mutation UpdateNotificationStatusToRead($userId: String!, $notificationId: Int) {
  updateNotificationStatusToRead(userId:  $userId, notificationId: $notificationId) {
    description
    notificationId
    notificationType
    status
    title
  }
}
`

export const updateReferredByForUser = /* GraphQL */ `
  mutation updateReferredByForUser($referredBy: String!, $userId: String!) {
    updateReferredByForUser(userId: $userId, referredBy: $referredBy) {
      challengesAchieved
      couponsWon
      earnedPoints
      dateOfBirth
      email
      firstName
      lastName
      mobileNumber
      redeemedPoints
      referralCode
      ticketNumber
      totalChallenges
      userId
    }
  }
`

export const updateUserDeletionRequested = /* GraphQL */ `
  mutation updateUserDeletionRequested(
    $userDeletionRequested: Boolean!
    $userId: String!
  ) {
    updateUserDeletionRequested(
      userDeletionRequested: $userDeletionRequested
      userId: $userId
    ) {
      challengesAchieved
      couponsWon
      dateOfBirth
      earnedPoints
      email
      firstName
      lastName
      mobileNumber
      redeemedPoints
      referralCode
      ticketNumber
      totalChallenges
      userDeletionRequested
      userId
    }
  }
`
export const updateUserArtistsPreference = /* GraphQL */ `
  mutation updateUserArtistsPreference(
    $userId: String!
    $favouriteArtists: [String]
    $preferredArtistRegion: [String]
  ) {
    updateUserArtistsPreference(
      userId: $userId
      favouriteArtists: $favouriteArtists
      preferredArtistRegion: $preferredArtistRegion
    ) {
      challengesAchieved
      city
      couponsWon
      country
      dateOfBirth
      doorNumber
      earnedPoints
      email
      favouriteArtists
      gender
      firstName
      isNewUser
      isSpotifyConnected
      zipCode
      userName
      userId
      userDeletionRequested
      totalChallenges
      ticketNumber
      street
      redeemedPoints
      referralCode
      preferredArtistRegion
      photoFolderCode
      mobileNumber
      lastName
    }
  }
`
export const updateUserTicketNumber = `
  mutation updateUserTicketNumber(
     $userId: String!
     $ticketNumber: String!
  ) {
  updateUserTicketNumber(userId: $userId,ticketNumber: $ticketNumber){
    challengesAchieved
    city
    country
    dateOfBirth
    doorNumber
    earnedPoints
    firstName
    email
    gender
    lastName
    photoFolderCode
    mobileNumber
    referralCode
    redeemedPoints
    street
    ticketNumber
    totalChallenges
    userDeletionRequested
    userId
    userName
    zipCode
    isNewUser
    isSpotifyConnected
    }
 }
 `
export const updateUserIsNewUser = `
  mutation updateUserIsNewUser(
    $userId: String!
    $isNewUser: Boolean!
  ) {
    updateUserIsNewUser(userId: $userId,isNewUser: $isNewUser){
      challengesAchieved
      city
      country
      dateOfBirth
      doorNumber
      earnedPoints
      firstName
      email
      gender
      lastName
      photoFolderCode
      mobileNumber
      referralCode
      redeemedPoints
      street
      ticketNumber
      totalChallenges
      userDeletionRequested
      userId
      userName
      zipCode
      isNewUser
      isSpotifyConnected
    }
 }
 `

export const updateIsSpotifyConnected = `
  mutation updateIsSpotifyConnected(
    $userId: String!
    $isSpotifyConnected: Boolean!
  ) {
    updateIsSpotifyConnected(userId: $userId,isSpotifyConnected: $isSpotifyConnected){
      isSpotifyConnected
    }
 }
 `

export const deleteSpotifyUser = `
 mutation deleteSpotifyUser($userId: String!) {
  deleteSpotifyUser(userId: $userId) {
    userId
  }
}`

export const createVoteTracker = `
  mutation createVoteTracker($userId: String!) {
    createVoteTracker(userId: $userId) {
      userId
    }
  }
`

export const updateVoteTracker = `
  mutation updateVoteTracker($userId: String!, $latestVotedAt: String!, $latestVotedArtistId: String!) {
    updateVoteTracker(userId: $userId, latestVotedAt: $latestVotedAt, latestVotedArtistId: $latestVotedArtistId) {
      id
      userId
      latestVotedAt
      latestVotedArtistId
    }
  }
`
export const createVote = `
  mutation createVote($guid: String!, $artistId: String!, $voteTrackerId: String!) {
    createVote(guid: $guid, artistId: $artistId, voteTrackerId: $voteTrackerId) {
      id
      voteTrackerId
      artistId
    }
  }
`
