import React, { useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import {
  useTheme,
  Text,
  Button,
  TextInput,
  Surface,
  RadioButton,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { EmailTextInput } from '@nowon/components'

export const UserDetails = (props) => {
  const {
    handleUpdateProfile,
    userName,
    setUserName,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    mobileNumber,
    setMobileNumber,
    dateOfBirth,
    setDateOfBirth,
    street,
    setStreet,
    doorNumber,
    setDoorNumber,
    zipCode,
    setZipCode,
    city,
    setCity,
    country,
    setCountry,
    gender,
    setGender,
    ticketNumber,
    setTicketNumber,
    isFromProfile,
    email,
  } = props
  const { t } = useTranslation()
  const { colors } = useTheme()
  const datePicker = useRef()
  const handleSubmit = () => {
    if (
      userName &&
      firstName &&
      lastName &&
      gender &&
      mobileNumber &&
      dateOfBirth &&
      zipCode &&
      street &&
      doorNumber &&
      country &&
      city
    ) {
      handleUpdateProfile()
    }
  }
  return (
    <View style={styles.cardContainer}>
      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('Q_USER_NAME')} *
        </Text>
        <TextInput
          mode='outlined'
          placeholder={t('USER_NAME')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={userName}
          onChange={(value) => setUserName(value.target.value)}
        />
      </Surface>
      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('FIRST_NAME')} *
        </Text>
        <TextInput
          mode='outlined'
          placeholder={t('FIRST_NAME')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={firstName}
          onChange={(value) => setFirstName(value.target.value)}
        />
      </Surface>
      {isFromProfile && (
        <Surface elevation={0}>
          <Text
            variant='labelLarge'
            style={{
              color: colors.neutral,
            }}
          >
            {t('EMAIL_ID')} *
          </Text>
          <EmailTextInput
            mode='outlined'
            style={{
              backgroundColor: 'transparent',
              height: 35,
              marginTop: -6,
            }}
            placeholderTextColor='#888787'
            placeholder={email}
            disabled
          />
        </Surface>
      )}
      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('LAST_NAME')} *
        </Text>
        <TextInput
          mode='outlined'
          placeholder={t('LAST_NAME')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={lastName}
          onChange={(value) => setLastName(value.target.value)}
        />
      </Surface>
      {isFromProfile && (
        <Surface elevation={0}>
          <Text
            variant='labelLarge'
            style={{
              color: colors.neutral,
            }}
          >
            {t('TICKET_NUMBER')}
          </Text>
          <TextInput
            mode='outlined'
            placeholder='Ticket number'
            style={{
              backgroundColor: 'transparent',
              height: 40,
            }}
            placeholderTextColor={colors.onSurfacePlaceholder}
            theme={{
              colors: {
                onSurface: colors.surface,
              },
            }}
            value={ticketNumber}
            onChange={(value) => setTicketNumber(value.target.value)}
          />
        </Surface>
      )}

      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('Q_D0B')} *
        </Text>
        <input
          type='date'
          style={{
            height: 40,
            backgroundColor: 'transparent',
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: 'rgb(185,189,191)',
            color: colors.surface,
            marginTop: 5,
            boxShadow: 'none',
            fontSize: 14,
            fontFamily: 'Lexend Deca',
          }}
          value={dateOfBirth}
          id='dateOfBirth'
          onChange={(value) => setDateOfBirth(value.target.value)}
          ref={datePicker}
        />
      </Surface>
      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('Q_GENDER')} *
        </Text>
        <Surface
          elevation={0}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <RadioButton
            value='Male'
            status={gender === 'Male' ? 'checked' : 'unchecked'}
            onPress={() => setGender('Male')}
          />
          <Text
            variant='labelLarge'
            style={{
              color: colors.neutral,
            }}
          >
            {t('MALE')}
          </Text>
        </Surface>

        <Surface
          elevation={0}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <RadioButton
            value='Female'
            status={gender === 'Female' ? 'checked' : 'unchecked'}
            onPress={() => setGender('Female')}
          />
          <Text
            variant='labelLarge'
            style={{
              color: colors.neutral,
            }}
          >
            {t('FEMALE')}
          </Text>
        </Surface>
        <Surface
          elevation={0}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <RadioButton
            value='various'
            status={gender === 'various' ? 'checked' : 'unchecked'}
            onPress={() => setGender('various')}
          />
          <Text
            variant='labelLarge'
            style={{
              color: colors.neutral,
            }}
          >
            {t('VARIOUS')}
          </Text>
        </Surface>
      </Surface>

      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('Q_NUMBER')} *
        </Text>
        <TextInput
          mode='outlined'
          placeholder={t('MOBILE_NUMBER')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={mobileNumber}
          onChange={(value) => setMobileNumber(value.target.value)}
        />
      </Surface>
      <Surface elevation={0}>
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
          }}
        >
          {t('Q_ADDRESS')} *
        </Text>
        <TextInput
          mode='outlined'
          placeholder={t('STREET')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={street}
          onChange={(value) => setStreet(value.target.value)}
        />
        <TextInput
          mode='outlined'
          placeholder={t('NR')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={doorNumber}
          onChange={(value) => setDoorNumber(value.target.value)}
        />
        <TextInput
          mode='outlined'
          placeholder={t('ZIP')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={zipCode}
          onChange={(value) => setZipCode(value.target.value)}
        />
        <TextInput
          mode='outlined'
          placeholder={t('CITY')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={city}
          onChange={(value) => setCity(value.target.value)}
        />
        <TextInput
          mode='outlined'
          placeholder={t('LAND')}
          style={{
            backgroundColor: 'transparent',
            height: 40,
          }}
          placeholderTextColor={colors.onSurfacePlaceholder}
          theme={{
            colors: {
              onSurface: colors.surface,
            },
          }}
          value={country}
          onChange={(value) => setCountry(value.target.value)}
        />
      </Surface>
      <Button
        mode='outlined'
        contentStyle={{ height: 32 }}
        style={{
          borderRadius: 24,
          alignSelf: 'center',
          marginTop: 32,
          borderColor:
            userName &&
            firstName &&
            lastName &&
            gender &&
            mobileNumber &&
            dateOfBirth &&
            zipCode &&
            street &&
            doorNumber &&
            country &&
            city
              ? colors.primary
              : 'grey',
          width: 160,
        }}
        labelStyle={{
          color:
            userName &&
            firstName &&
            lastName &&
            gender &&
            mobileNumber &&
            dateOfBirth &&
            zipCode &&
            street &&
            doorNumber &&
            country &&
            city
              ? colors.neutral
              : 'grey',
          fontSize: '16px',
          lineHeight: '20px',
          fontWeight: 400,
        }}
        onPress={handleSubmit}
      >
        {t('ACTIONS.OK')}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: 'rgba(255,255,255,0.9)',
    backdropFilter: 'blur(10px)',
    paddingHorizontal: 24,
    paddingTop: 33,
    paddingBottom: 33,
    minWidth: 327,
    borderRadius: 16,
    justifyContent: 'space-between',
    gap: 16,
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 18,
    paddingBottom: 25,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.2)',
    paddingHorizontal: 24,
  },
})
