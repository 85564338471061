/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { Button, Card, ProgressBar, Surface, Text } from 'react-native-paper'
import { Auth } from 'aws-amplify'
import { Container } from '@nowon/container'
import { Icon } from '@nowon/icons'
import { PreferenceSection } from '@nowon/card'
import { useQuery } from '@tanstack/react-query'
import { useIsFocused } from '@react-navigation/native'
import logo from '../../assets/logo.png'
import logosmall from '../../assets/logosmall.png'
import {
  updateUser,
  getAllChallengesByUserId,
  updateUserTicketNumber,
} from '../../apiGraphql'
import {
  useOnboardingQuestions,
  useUpdateOnboardingAnswers,
} from './useOnboardingQuestions'
import { MULTI_SELECT } from '../../constant'
import { UserDetails } from '../../components/onboard/userDetails'

export const Onboard = (props) => {
  const { navigation, user, t, colors, setUser } = props
  const questionId = Number(props.route.params?.questionId) || 0
  const [question, setQuestion] = useState({})
  const [selectedOption, setSelectedOption] = useState([])
  const { data: allQuestion } = useOnboardingQuestions(user?.userId)
  const { mutate: updateAnswer } = useUpdateOnboardingAnswers(user?.userId)
  const [visible, setVisible] = useState(false)
  const [userName, setUserName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [street, setStreet] = useState('')
  const [doorNumber, setDoorNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [gender, setGender] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')
  const [newArray, setNewArray] = useState([])
  const isRendered = useIsFocused()

  useEffect(() => {
    if (!user) return
    setUserName(user?.userName)
    setFirstName(user?.firstName)
    setLastName(user?.lastName)
    setMobileNumber(user?.mobileNumber)
    setDateOfBirth(user?.dateOfBirth)
    setStreet(user?.street)
    setDoorNumber(user?.doorNumber)
    setZipCode(user?.zipCode)
    setCity(user?.city)
    setCountry(user?.country)
    setGender(user?.gender)
    setTicketNumber(user?.ticketNumber)
  }, [user])

  const handleUpdateProfile = async () => {
    try {
      const response = await updateUser({
        userName,
        firstName,
        lastName,
        mobileNumber,
        dateOfBirth,
        userId: user?.userId,
        street,
        doorNumber,
        zipCode,
        city,
        country,
        gender,
      })
      setUser(response)
      if (userName) {
        await updateAnswer({
          questionId,
          answers: [userName],
        })
        navigation.navigate('onboardComplete')
      } else {
        navigation.navigate('home')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleStart = () => {
    const filteredQuestion = allQuestion?.filter(
      (aQ) => aQ.status === 'PENDING',
    )
    if (filteredQuestion) {
      navigation.push('onboard', {
        questionId: filteredQuestion[0]?.questionId,
      })
    }
  }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const progressStatus = (questionId / allQuestion?.length) * 100
  const currentProgress =
    progressStatus === 100 ? 1.0 : parseFloat('0.'.concat(progressStatus))

  useEffect(() => {
    if (!questionId || !allQuestion) return
    const filteredQuestion = allQuestion.find(
      // eslint-disable-next-line eqeqeq
      (aq) => aq?.questionId == questionId,
    )
    setQuestion(filteredQuestion)
    setSelectedOption(filteredQuestion?.answers)
  }, [allQuestion, questionId])

  const handleSelectOption = async (item) => {
    if (question.questionType === MULTI_SELECT) {
      setSelectedOption([...selectedOption, item])
    } else if (question?.additionalInfoTriggerAnswerOption === item) {
      setSelectedOption(item)
      await updateAnswer({
        questionId,
        answers: selectedOption,
      })
    } else {
      setSelectedOption(item)
      await updateAnswer({
        questionId,
        answers: item,
      })
      if (allQuestion?.length > questionId) {
        navigation.push('onboard', {
          questionId: questionId + 1,
        })
      }
      if (allQuestion?.length === questionId) {
        navigation.push('onboardComplete')
      }
    }
  }

  const handleNext = async () => {
    await updateAnswer({
      questionId,
      answers: selectedOption,
    })
    if (allQuestion?.length > questionId) {
      navigation.push('onboard', {
        questionId: questionId + 1,
      })
    }
    if (allQuestion?.length === questionId) {
      navigation.navigate('onboardComplete')
    }
  }

  const handleTicketSubmit = async (ticketNum, continueClicked) => {
    await Auth.currentAuthenticatedUser()
    if (ticketNum?.length > 0 && continueClicked) {
      const response = await updateUserTicketNumber({
        userId: user?.userId,
        ticketNumber: ticketNum,
      })
      setUser(response)
      if (allQuestion?.length > questionId) {
        navigation.push('onboard', {
          questionId: questionId + 1,
        })
      }
      if (allQuestion?.length === questionId) {
        navigation.push('onboardComplete')
      }
    }
    if (!continueClicked) {
      if (allQuestion?.length > questionId) {
        navigation.push('onboard', {
          questionId: questionId + 1,
        })
      }
      if (allQuestion?.length === questionId) {
        navigation.push('onboardComplete')
      }
    }
  }
  const { data: challengeData } = useQuery(
    ['getAllChallengesByUserId'],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  useEffect(() => {
    if (challengeData?.length > 0) {
      const newArrays = challengeData?.find(
        (el) => el?.challengeType === 'ONBOARDING',
      )
      setNewArray(newArrays)
    }
  }, [challengeData])

  if (questionId === 0) {
    return (
      <Container style={{ paddingTop: 100, paddingBottom: 40 }}>
        <Image
          source={logo}
          style={{
            height: 46,
            width: 173,
            alignSelf: 'center',
          }}
        />
        <View style={{ flex: 1 }} />
        <Text
          variant='titleLarge'
          style={{
            color: colors.primary,
            marginBottom: 18,
          }}
        >
          {t('WELCOME_ONBOARD')}
        </Text>
        <Surface
          style={{
            padding: 24,
            width: '100%',
            backgroundColor: colors.primary,
            borderRadius: 32,
          }}
        >
          <Text
            variant='headlineSmall'
            style={{
              color: colors.neutral,
              marginBottom: 24,
              fontFamily: 'Adopekid-Kanit-RC-Stencil',
            }}
          >
            {t('PREFERENCE')}
          </Text>
          <Surface
            elevation={0}
            style={{
              gap: 16,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text
              variant='titleSmall'
              style={{
                color: colors.neutral,
                alignSelf: 'center',
              }}
            >
              {`${t('GET')} ${newArray?.totalPoints} ${t('GET_STARTED_BY')}`}
            </Text>
            {allQuestion?.length > 0 && (
              <TouchableOpacity
                onPress={() => {
                  handleStart()
                }}
                style={{
                  height: 48,
                  width: 48,
                  borderRadius: 24,
                  backgroundColor: colors.primary,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  name='ArrowRightIcon'
                  width={27}
                  height={16}
                  color='#fff'
                />
              </TouchableOpacity>
            )}
          </Surface>
        </Surface>
        <Text
          variant='labelLarge'
          style={{
            marginTop: 23,
            marginBottom: 23,
            alignSelf: 'center',
            color: colors.primary,
          }}
          onPress={() => navigation.navigate('home')}
        >
          {t('SKIP_FOR_NOW')}
        </Text>
      </Container>
    )
  }

  return (
    <Card elevation={0} onPress={() => setVisible(false)}>
      <Container>
        <Surface elevation={0}>
          <Surface
            elevation={0}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginVertical: 40,
            }}
          >
            <Image
              source={logosmall}
              style={{
                height: 29,
                width: 32,
              }}
            />
            <Button
              style={{
                height: 24,
                width: 70,
                borderRadius: 12,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              labelStyle={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 17,
                color: colors.primary,
              }}
              onPress={() => navigation.push('home')}
            >
              {t('ACTIONS.SKIP')}
            </Button>
          </Surface>
          <ProgressBar
            progress={currentProgress}
            color={colors.primary}
            style={{
              marginBottom: 34,
            }}
          />
          <Text
            variant='titleLarge'
            style={{
              color: colors.primary,
              marginBottom: 13,
            }}
          >
            {t('PERFERENCES')}
          </Text>
          <PreferenceSection
            question={question}
            selectedOption={selectedOption}
            handleSelectOption={handleSelectOption}
            handleTicketSubmit={handleTicketSubmit}
            visible={visible}
            setVisible={setVisible}
          />
          {question.questionType === 'USER-DETAILS' && (
            <UserDetails
              handleUpdateProfile={handleUpdateProfile}
              userName={userName}
              setUserName={setUserName}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              street={street}
              setStreet={setStreet}
              doorNumber={doorNumber}
              setDoorNumber={setDoorNumber}
              zipCode={zipCode}
              setZipCode={setZipCode}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              gender={gender}
              setGender={setGender}
            />
          )}
          {question.questionType === MULTI_SELECT && (
            <View style={{ alignItems: 'flex-end' }}>
              <Button
                mode='outlined'
                contentStyle={{ height: 32 }}
                style={{
                  borderRadius: 50,
                  width: 95,
                }}
                labelStyle={{
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                onPress={() => handleNext()}
              >
                {t('ACTIONS.NEXT')}
              </Button>
            </View>
          )}
        </Surface>
      </Container>
    </Card>
  )
}
