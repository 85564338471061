import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgHeartIcon = (props) => {
  if (props.filled) {
    return (
      <Svg
        width='24'
        height='21'
        viewBox='0 0 24 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <Path
          d='M12.4735 4.22851L12 5.17553L11.5265 4.22851C9.9454 1.0664 7.18823 0.176347 4.83403 0.647047C2.43404 1.1269 0.529402 2.98467 0.529402 5.3135C0.529402 7.69409 2.01641 9.29992 4.37217 11.4229C4.62189 11.648 4.8801 11.8778 5.14543 12.1141C7.2145 13.9562 9.7161 16.1833 12 19.5066C14.284 16.1835 16.7855 13.9564 18.8545 12.1143C19.1199 11.878 19.3782 11.648 19.628 11.4229C21.9837 9.29993 23.4706 7.6941 23.4706 5.3135C23.4706 2.98466 21.5659 1.12688 19.166 0.647012C16.8118 0.176302 14.0546 1.06635 12.4735 4.22851Z'
          fill='#FF4C77'
          stroke='#FF4C77'
          stroke-width='1.0588'
        />
      </Svg>
    )
  }

  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox='0 0 24 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <Path
        d='M12.4735 4.22851L12 5.17553L11.5265 4.22851C9.9454 1.0664 7.18823 0.176347 4.83402 0.647047C2.43404 1.1269 0.5294 2.98467 0.5294 5.3135C0.5294 7.69409 2.01641 9.29992 4.37217 11.4229C4.62189 11.648 4.8801 11.8778 5.14543 12.1141C7.2145 13.9562 9.7161 16.1833 12 19.5066C14.284 16.1835 16.7855 13.9564 18.8545 12.1143C19.1199 11.878 19.3782 11.648 19.628 11.4229C21.9837 9.29993 23.4706 7.6941 23.4706 5.3135C23.4706 2.98466 21.5659 1.12688 19.166 0.647012C16.8118 0.176302 14.0546 1.06635 12.4735 4.22851Z'
        stroke='#FF4C77'
        stroke-width='1.0588'
      />
    </Svg>
  )
}

export default SvgHeartIcon
