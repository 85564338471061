import React, { useEffect, useState, Suspense } from 'react'
import { Hub, Auth } from 'aws-amplify'
import { useNavigation } from '@react-navigation/native'
import { HomeStackNavigator } from './StackNavigator'
import { setItemAsync } from '../utils/SecureStore'
import * as RootNavigator from './RootNavigator'

export const AppNavigator = () => {
  const [user, setUser] = useState(null)
  const [customState, setCustomState] = useState(null)
  const navigation = useNavigation()
  const setCurrentUser = async () => {
    try {
      const response = await Auth.currentAuthenticatedUser()
      if (response) {
        setUser(response)
      }
    } catch (error) {
      setUser(null)
    }
  }

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        const response = await Auth.currentAuthenticatedUser()
        if (response) {
          setUser(response)
        } else if (
          ['inviteeHome', 'register', 'verificationComplete', 'resetPassword'].indexOf(
            RootNavigator.getCurrent().getCurrentRoute().name,
          ) === -1
        ) {
          RootNavigator.reset('login')
        }
      } catch (error) {
        setUser(null)
        if (
          ['inviteeHome', 'verificationComplete', 'register', 'resetPassword'].indexOf(
            RootNavigator.getCurrent().getCurrentRoute().name,
          ) === -1
        ) {
          RootNavigator.reset('login')
        }
        console.log(error)
      }
    }

    getCurrentUser()
  }, [])
  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          await setCurrentUser()
          break
        case 'signUp':
          await setCurrentUser()
          break
        case 'signOut':
          await setCurrentUser()
          navigation.navigate('login')
          break
        case 'cognitoHostedUI':
          setCustomState(data)
          if (data) {
            await setItemAsync(
              'SIGNING_RESPONSE',
              JSON.stringify(data?.signInUserSession?.accessToken?.payload),
            )
          }
          await setCurrentUser()
          break
        case 'cognitoHostedUI_failure':
          break
        case 'autoSignIn':
          navigation.navigate('verification', { VerificationType: 'Email' })
          break
        default:
          break
      }
    })
  }, [])
  return (
    <Suspense>
      <HomeStackNavigator />
    </Suspense>
  )
}
