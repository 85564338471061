/**
 * Do not edit directly
 * Generated on Thu, 06 Oct 2022 05:16:22 GMT
 */

export const MdSysColorPrimary = '#006783'
export const MdSysColorOnPrimary = '#ffffff'
export const MdSysColorPrimaryContainer = '#bde9ff'
export const MdSysColorOnPrimaryContainer = '#001f2a'
export const MdSysColorSecondary = '#4259a9'
export const MdSysColorOnSecondary = '#ffffff'
export const MdSysColorSecondaryContainer = '#dce1ff'
export const MdSysColorOnSecondaryContainer = '#001550'
export const MdSysColorTertiary = '#00639d'
export const MdSysColorOnTertiary = '#ffffff'
export const MdSysColorTertiaryContainer = '#cfe5ff'
export const MdSysColorOnTertiaryContainer = '#001d33'
export const MdSysColorError = '#ba1a1a'
export const MdSysColorErrorContainer = '#ffdad6'
export const MdSysColorOnError = '#ffffff'
export const MdSysColorOnErrorContainer = '#410002'
export const MdSysColorBackground = '#fffbff'
export const MdSysColorOnBackground = '#3e001d'
export const MdSysColorSurface = '#fffbff'
export const MdSysColorOnSurface = '#3e001d'
export const MdSysColorSurfaceVariant = '#dce4e9'
export const MdSysColorOnSurfaceVariant = '#40484c'
export const MdSysColorOutline = '#70787d'
export const MdSysColorInverseOnSurface = '#ffecf0'
export const MdSysColorInverseSurface = '#5e1133'
export const MdSysColorInversePrimary = '#6EC1E4'
export const MdSysColorShadow = '#000000'
export const MdSysColorSurfaceTint = '#006783'
export const MdSysColorSurfaceTintColor = '#006783'
export const MdSysColorPrimaryLight = '#006783'
export const MdSysColorOnPrimaryLight = '#ffffff'
export const MdSysColorPrimaryContainerLight = '#bde9ff'
export const MdSysColorOnPrimaryContainerLight = '#001f2a'
export const MdSysColorSecondaryLight = '#4259a9'
export const MdSysColorOnSecondaryLight = '#ffffff'
export const MdSysColorSecondaryContainerLight = '#dce1ff'
export const MdSysColorOnSecondaryContainerLight = '#001550'
export const MdSysColorTertiaryLight = '#00639d'
export const MdSysColorOnTertiaryLight = '#ffffff'
export const MdSysColorTertiaryContainerLight = '#cfe5ff'
export const MdSysColorOnTertiaryContainerLight = '#001d33'
export const MdSysColorErrorLight = '#ba1a1a'
export const MdSysColorErrorContainerLight = '#ffdad6'
export const MdSysColorOnErrorLight = '#ffffff'
export const MdSysColorOnErrorContainerLight = '#410002'
export const MdSysColorBackgroundLight = '#fffbff'
export const MdSysColorOnBackgroundLight = '#3e001d'
export const MdSysColorSurfaceLight = '#fffbff'
export const MdSysColorOnSurfaceLight = '#3e001d'
export const MdSysColorSurfaceVariantLight = '#dce4e9'
export const MdSysColorOnSurfaceVariantLight = '#40484c'
export const MdSysColorOutlineLight = '#70787d'
export const MdSysColorInverseOnSurfaceLight = '#ffecf0'
export const MdSysColorInverseSurfaceLight = '#5e1133'
export const MdSysColorInversePrimaryLight = '#6EC1E4'
export const MdSysColorInversePrimary01 = '#6EE4E4'
export const MdSysColorInversePrimary02 = '#99B5D4'
export const MdSysColorInversePrimary03 = '#CCCCCC'
export const MdSysColorShadowLight = '#000000'
export const MdSysColorSurfaceTintLight = '#006783'
export const MdSysColorSurfaceTintColorLight = '#006783'
export const MdSysColorPrimaryDark = '#6EC1E4'
export const MdSysColorOnPrimaryDark = '#003546'
export const MdSysColorPrimaryContainerDark = '#004d64'
export const MdSysColorOnPrimaryContainerDark = '#bde9ff'
export const MdSysColorSecondaryDark = '#b6c4ff'
export const MdSysColorOnSecondaryDark = '#092978'
export const MdSysColorSecondaryContainerDark = '#284190'
export const MdSysColorOnSecondaryContainerDark = '#dce1ff'
export const MdSysColorTertiaryDark = '#98cbff'
export const MdSysColorOnTertiaryDark = '#003354'
export const MdSysColorTertiaryContainerDark = '#004a77'
export const MdSysColorOnTertiaryContainerDark = '#cfe5ff'
export const MdSysColorErrorDark = '#E95A1D'
export const MdSysColorErrorContainerDark = '#93000a'
export const MdSysColorOnErrorDark = '#690005'
export const MdSysColorOnErrorContainerDark = '#ffdad6'
export const MdSysColorBackgroundDark = '#3e001d'
export const MdSysColorOnBackgroundDark = '#ffd9e2'
export const MdSysColorSurfaceDark = '#3e001d'
export const MdSysColorOnSurfaceDark = '#ffd9e2'
export const MdSysColorSurfaceVariantDark = '#40484c'
export const MdSysColorOnSurfaceVariantDark = '#c0c8cd'
export const MdSysColorOutlineDark = '#8a9297'
export const MdSysColorInverseOnSurfaceDark = '#3e001d'
export const MdSysColorInverseSurfaceDark = '#ffd9e2'
export const MdSysColorInversePrimaryDark = '#006783'
export const MdSysColorShadowDark = '#000000'
export const MdSysColorSurfaceTintDark = '#6EC1E4'
export const MdSysColorSurfaceTintColorDark = '#6EC1E4'
export const MdRefPalettePrimary0 = '#000000'
export const MdRefPalettePrimary10 = '#001f2a'
export const MdRefPalettePrimary20 = '#003546'
export const MdRefPalettePrimary30 = '#004d64'
export const MdRefPalettePrimary40 = '#006783'
export const MdRefPalettePrimary50 = '#0081a4'
export const MdRefPalettePrimary60 = '#059dc7'
export const MdRefPalettePrimary70 = '#40b8e3'
export const MdRefPalettePrimary80 = '#6EC1E4'
export const MdRefPalettePrimary90 = '#bde9ff'
export const MdRefPalettePrimary95 = '#e0f4ff'
export const MdRefPalettePrimary99 = '#fafcff'
export const MdRefPalettePrimary100 = '#ffffff'
export const MdRefPaletteSecondary0 = '#000000'
export const MdRefPaletteSecondary10 = '#001550'
export const MdRefPaletteSecondary20 = '#092978'
export const MdRefPaletteSecondary30 = '#284190'
export const MdRefPaletteSecondary40 = '#4259a9'
export const MdRefPaletteSecondary50 = '#5c73c4'
export const MdRefPaletteSecondary60 = '#768ce0'
export const MdRefPaletteSecondary70 = '#91a7fd'
export const MdRefPaletteSecondary80 = '#b6c4ff'
export const MdRefPaletteSecondary90 = '#dce1ff'
export const MdRefPaletteSecondary95 = '#eff0ff'
export const MdRefPaletteSecondary99 = '#fefbff'
export const MdRefPaletteSecondary100 = '#ffffff'
export const MdRefPaletteTertiary0 = '#000000'
export const MdRefPaletteTertiary10 = '#001d33'
export const MdRefPaletteTertiary20 = '#003354'
export const MdRefPaletteTertiary30 = '#004a77'
export const MdRefPaletteTertiary40 = '#00639d'
export const MdRefPaletteTertiary50 = '#267cbc'
export const MdRefPaletteTertiary60 = '#4896d7'
export const MdRefPaletteTertiary70 = '#66b1f4'
export const MdRefPaletteTertiary80 = '#98cbff'
export const MdRefPaletteTertiary90 = '#cfe5ff'
export const MdRefPaletteTertiary95 = '#e8f1ff'
export const MdRefPaletteTertiary99 = '#fcfcff'
export const MdRefPaletteTertiary100 = '#ffffff'
export const MdRefPaletteNeutral0 = '#000000'
export const MdRefPaletteNeutral10 = '#3e001d'
export const MdRefPaletteNeutral20 = '#5e1133'
export const MdRefPaletteNeutral30 = '#7b2949'
export const MdRefPaletteNeutral40 = '#984061'
export const MdRefPaletteNeutral50 = '#b6587a'
export const MdRefPaletteNeutral60 = '#d57193'
export const MdRefPaletteNeutral70 = '#f48bae'
export const MdRefPaletteNeutral80 = '#ffb1c8'
export const MdRefPaletteNeutral90 = '#ffd9e2'
export const MdRefPaletteNeutral95 = '#ffecf0'
export const MdRefPaletteNeutral99 = '#fffbff'
export const MdRefPaletteNeutral100 = '#ffffff'
export const MdRefPaletteNeutralVariant0 = '#000000'
export const MdRefPaletteNeutralVariant10 = '#151d20'
export const MdRefPaletteNeutralVariant20 = '#2a3235'
export const MdRefPaletteNeutralVariant30 = '#40484c'
export const MdRefPaletteNeutralVariant40 = '#586064'
export const MdRefPaletteNeutralVariant50 = '#70787d'
export const MdRefPaletteNeutralVariant60 = '#8a9297'
export const MdRefPaletteNeutralVariant70 = '#a4acb1'
export const MdRefPaletteNeutralVariant80 = '#c0c8cd'
export const MdRefPaletteNeutralVariant90 = '#dce4e9'
export const MdRefPaletteNeutralVariant95 = '#eaf2f7'
export const MdRefPaletteNeutralVariant99 = '#fafcff'
export const MdRefPaletteNeutralVariant100 = '#ffffff'
export const MdRefPaletteError0 = '#000000'
export const MdRefPaletteError10 = '#410002'
export const MdRefPaletteError20 = '#690005'
export const MdRefPaletteError30 = '#93000a'
export const MdRefPaletteError40 = '#ba1a1a'
export const MdRefPaletteError50 = '#de3730'
export const MdRefPaletteError60 = '#ff5449'
export const MdRefPaletteError70 = '#ff897d'
export const MdRefPaletteError80 = '#E95A1D'
export const MdRefPaletteError90 = '#ffdad6'
export const MdRefPaletteError95 = '#ffedea'
export const MdRefPaletteError99 = '#fffbff'
export const MdRefPaletteError100 = '#ffffff'
export const MdSysTypescaleLabelSmallFamily = 'Roboto'
export const MdSysTypescaleLabelSmallWeight = 'Medium'
export const MdSysTypescaleLabelMediumFamily = 'Roboto'
export const MdSysTypescaleLabelMediumWeight = 'Medium'
export const MdSysTypescaleLabelLargeFamily = 'Roboto'
export const MdSysTypescaleLabelLargeWeight = 'Medium'
export const MdSysTypescaleBodySmallFamily = 'Roboto'
export const MdSysTypescaleBodySmallWeight = 'Regular'
export const MdSysTypescaleBodyMediumFamily = 'Roboto'
export const MdSysTypescaleBodyMediumWeight = 'Regular'
export const MdSysTypescaleBodyLargeFamily = 'Roboto'
export const MdSysTypescaleBodyLargeWeight = 'Regular'
export const MdSysTypescaleHeadlineSmallFamily = 'Roboto'
export const MdSysTypescaleHeadlineSmallWeight = 'Regular'
export const MdSysTypescaleHeadlineMediumFamily = 'Roboto'
export const MdSysTypescaleHeadlineMediumWeight = 'Regular'
export const MdSysTypescaleHeadlineLargeFamily = 'Roboto'
export const MdSysTypescaleHeadlineLargeWeight = 'Regular'
export const MdSysTypescaleDisplaySmallFamily = 'Roboto'
export const MdSysTypescaleDisplaySmallWeight = 'Regular'
export const MdSysTypescaleDisplayMediumFamily = 'Roboto'
export const MdSysTypescaleDisplayMediumWeight = 'Regular'
export const MdSysTypescaleDisplayLargeFamily = 'Roboto'
export const MdSysTypescaleDisplayLargeWeight = 'Regular'
export const MdSysTypescaleTitleSmallFamily = 'Roboto'
export const MdSysTypescaleTitleSmallWeight = 'Medium'
export const MdSysTypescaleTitleMediumFamily = 'Roboto'
export const MdSysTypescaleTitleMediumWeight = 'Medium'
export const MdSysTypescaleTitleLargeFamily = 'Roboto'
export const MdSysTypescaleTitleLargeWeight = 'Regular'
export const MdSysTypescaleLabelSmallSize = '11rem'
export const MdSysTypescaleLabelMediumSize = '12rem'
export const MdSysTypescaleLabelLargeSize = '14rem'
export const MdSysTypescaleBodySmallSize = '12rem'
export const MdSysTypescaleBodyMediumSize = '14rem'
export const MdSysTypescaleBodyLargeSize = '16rem'
export const MdSysTypescaleHeadlineSmallSize = '24rem'
export const MdSysTypescaleHeadlineMediumSize = '28rem'
export const MdSysTypescaleHeadlineLargeSize = '32rem'
export const MdSysTypescaleDisplaySmallSize = '36rem'
export const MdSysTypescaleDisplayMediumSize = '45rem'
export const MdSysTypescaleDisplayLargeSize = '57rem'
export const MdSysTypescaleTitleSmallSize = '14rem'
export const MdSysTypescaleTitleMediumSize = '16rem'
export const MdSysTypescaleTitleLargeSize = '22rem'
