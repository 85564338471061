import React from 'react'
import { Surface, Text, useTheme, Button } from 'react-native-paper'
import { Image, Linking } from 'react-native'
import { useTranslation } from 'react-i18next'

const CouponCard = (props) => {
  const {
    image,
    value,
    subHeading,
    content,
    couponCode,
    sponsorURL,
    backgroundColor = '#FFFFFF',
  } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <Surface
      elevation={0}
      style={{
        width: '100%',
        backgroundColor,
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 40,
        paddingBottom: 15,
      }}
    >
      <Image
        source={image}
        style={{
          height: 100,
          width: 100,
        }}
      />
      <Text
        variant='displaySmall'
        style={{
          color: colors.neutral,
          textAlign: 'center',
        }}
      >
        {value}
      </Text>
      <Text
        variant='titleMedium'
        style={{
          color: colors.neutral,
          marginBottom: 15,
        }}
      >
        {subHeading}
      </Text>
      {couponCode && (
        <Text
          variant='titleSmall'
          style={{
            color: colors.neutral,
            marginBottom: 10,
          }}
        >
          {`${t('COUPON_CODE')} : ${couponCode}`}
        </Text>
      )}
      {content && (
        <Text
          variant='labelLarge'
          style={{
            color: colors.neutral,
            marginBottom: 10,
            width: 249,
            textAlign: 'center',
            fontWeight: 300,
          }}
        >
          {content}
        </Text>
      )}

      {sponsorURL && (
        <Button
          mode='outlined'
          style={{
            backgroundColor: '#fff',
            width: '45%',
            height: '32px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            Linking.openURL(sponsorURL)
          }}
        >
          {t('ACTIONS.VISIT_SPONSOR')}
        </Button>
      )}
    </Surface>
  )
}

export default CouponCard
