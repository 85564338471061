import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgHelpIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 20 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M10.671.094C5.796.125 1.827 4.125 1.811 9c0 1.563.407 3.047 1.126 4.313-.203.453-.469.89-.813 1.296a4.619 4.619 0 0 1-1.687 1.172c-.11.047-.047.11.015.14.11.048.22.11.344.157.234.094.469.172.703.235.406.125.86.171 1.297.187a8.14 8.14 0 0 0 1.375-.094 10.09 10.09 0 0 0 1.188-.28 8.895 8.895 0 0 0 5.375 1.796 8.902 8.902 0 0 0 8.906-8.906c0-4.953-4.031-8.953-8.969-8.922Zm.016 14.062a.934.934 0 0 1-.922-.922c0-.515.422-.921.922-.921.515-.016.922.39.937.906a.92.92 0 0 1-.937.937Zm1.625-5.015c-.313.28-.672.547-.703 1.015-.032.344-.047.688-.047 1.032.015.187-.063.25-.25.234-.219-.016-.438 0-.64 0h-.72c-.125 0-.187-.047-.187-.188-.016-.671-.031-1.562.265-2.171.36-.797 1.235-1.047 1.735-1.735 1.25-1.703-1.89-2.844-2.531-.734-.094.312-.11.312-.438.265-.406-.046-.812-.109-1.219-.156-.156-.015-.218-.078-.203-.234.25-2.656 4-3.422 5.813-1.828.547.468.906 1.172.906 1.89-.016 1.157-1.031 1.922-1.781 2.61Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgHelpIcon
