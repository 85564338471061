import React from 'react'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { Container } from '@nowon/container'
import { useQuery } from '@tanstack/react-query'
import { useIsFocused } from '@react-navigation/native'
import { Icon } from '@nowon/icons'
import { getAllChallengesByUserId } from '../../apiGraphql'

export const OnboardComplete = (props) => {
  const { navigation, user, colors, t } = props
  const isRendered = useIsFocused()
  const { data: challengeData } = useQuery(
    ['getAllChallengesByUserId'],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  let newArray = []
  if (challengeData?.length > 0)
    newArray = challengeData?.filter((el) => el.challengeType === 'ONBOARDING')
  return (
    <Container
      style={{
        backgroundColor: '#CBF0FF',
        paddingTop: 140,
        paddingBottom: 60,
      }}
    >
      <View
        style={{
          width: 96,
          height: 96,
          borderRadius: 50,
          backgroundColor: '#6EE4E4',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          marginBottom: 38,
        }}
      >
        <Icon name='TickIcon' width={54} height={33} color='#fff' />
      </View>
      <Text
        variant='headlineLarge'
        style={{
          color: colors.neutral,
          marginBottom: 24,
          textAlign: 'center',
        }}
      >
        {t('AWESOME')},{' '}
        {user?.userName || user?.firstName || t('RAPCITY_MEMBER')}
      </Text>
      <Text
        variant='titleMedium'
        style={{
          color: colors.neutral,
          width: '100%',
          textAlign: 'center',
        }}
      >
        {`${t('COMPLETED_ONBOARDING')}${newArray?.[0]?.totalPoints} ${t(
          'REWARD_POINTS',
        )} ${t('RECEIVE')}`}
      </Text>
      <View style={{ flex: 1 }} />
      <Button
        style={{
          height: 48,
          width: 186,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: colors.primary,
          alignSelf: 'center',
        }}
        mode='outlined'
        onPress={() => navigation.navigate('home')}
        labelStyle={{
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 20,
          color: colors.neutral,
        }}
      >
        {t('ACTIONS.CONTINUE')}
      </Button>
    </Container>
  )
}
