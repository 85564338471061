import React from 'react'
import { Button, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import { View, Linking, TouchableOpacity, ImageBackground } from 'react-native'
import { buyTicketUrl } from '../../config'
import RapcityEvent from '../../assets/TobeAnnounceImage.jpg'

const TicketCard = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <TouchableOpacity onPress={() => Linking.openURL(buyTicketUrl)}>
      <ImageBackground
        source={RapcityEvent}
        style={{ height: 216, width: '100%' }}
        imageStyle={{ borderRadius: 24 }}
        resizeMode='cover'
      >
        <Button
          mode='outlined'
          onPress={() => Linking.openURL(buyTicketUrl)}
          style={{
            backgroundColor: '#fff',
            height: 28,
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.primary,
            border: 'none',
            position: 'absolute',
            right: 12,
            bottom: 10
          }}
        >
          {t('ACTIONS.BUY_TICKET')}
        </Button>
      </ImageBackground>
    </TouchableOpacity>
  )
}

export default TicketCard
