// Imports: Dependencies
import { combineReducers } from 'redux'

import loadingReducer from './loadingReducer'
import contestReducer from './contestReducer'

// Redux: Root Reducer
const rootReducer = combineReducers({
  loading: loadingReducer,
  contestReducer: contestReducer
})
// Exports
export default rootReducer
