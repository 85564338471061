import React from 'react'

import { BackgroundContainer } from '@nowon/container'

import { EmailVerificationCard } from '../../components/auth/EmailVerificationCard'

export const VerificationComplete = (props) => (
  <BackgroundContainer backgroundColor={props.colors.primary}>
    <EmailVerificationCard navigation={props.navigation} />
  </BackgroundContainer>
)
