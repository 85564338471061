import { View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Container } from '@nowon/container'
import { Surface, Text } from 'react-native-paper'
import { RWebShare } from 'react-web-share'
import { useQuery } from '@tanstack/react-query'
import { useIsFocused } from '@react-navigation/native'
import { appUrl } from '../../config'
import { getAllChallengesByUserId } from '../../apiGraphql'

export const InviteFriend = (props) => {
  const { user, colors, t } = props
  const isRendered = useIsFocused()
  const [invitePoints, setInvitePoints] = useState()
  const { data: challengeData } = useQuery(
    ['getAllChallengesByUserId'],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  useEffect(() => {
    console.log('effect')
    const inviteChallengeResponse = challengeData?.find(
      (item) => item?.challengeType === 'INVITE_FRIEND',
    )
    setInvitePoints(inviteChallengeResponse?.totalPoints)
    console.log(inviteChallengeResponse)
  }, [])

  return (
    <Container>
      <Surface elevation={0} style={{ paddingBottom: 27 }}>
        <Surface elevation={0}>
          <View>
            <Text variant='headlineLarge' style={{ color: colors.primary }}>
              {t('INVITE_FRIENDS')}
            </Text>
          </View>
          <View
            style={{
              marginTop: '16px',
            }}
          >
            <Text
              variant='titleLarge'
              style={{
                flexDirection: 'row',
                color: colors.primary,
              }}
            >
              {t('INVITE_REWARD')}
            </Text>
          </View>
        </Surface>
        <RWebShare
          data={{
            text: 'Join the Rap City Family!',
            url: `${appUrl}/register?referralCode=${user?.referralCode}`,
            title: 'Invite Friends',
          }}
          onClick={() => console.log('shared successfully!')}
        >
          <View
            style={{
              width: '186px',
              height: '48px',
              marginTop: '26px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 24,
              color: colors.neutralLight,
              fontFamily: 'Adopekid-Kanit-RC-Stencil',
              backgroundColor: colors.primary,
              cursor: 'pointer',
            }}
          >
            {t('ACTIONS.INVITE')}
          </View>
        </RWebShare>
      </Surface>
    </Container>
  )
}
