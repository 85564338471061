import React from 'react'
import { View, TouchableOpacity, ImageBackground } from 'react-native'
import { useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import ContestBackgroundImage from '../../assets/Home-contestBackground.png'
import { Icon } from '@nowon/icons'
import { useNavigation } from '@react-navigation/native'

const ContestCard = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('contest')
      }}
    >
      <ImageBackground
        source={ContestBackgroundImage}
        style={{ height: 100, width: '100%' }}
        imageStyle={{ borderRadius: 24 }}
        resizeMode='cover'
      >
        <View
          style={{
            paddingHorizontal: 24,
            paddingVertical: 10,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '70%',
            }}
          >
            <Text
              variant='titleLarge'
              style={{
                flexDirection: 'row',
                color: colors.textPrimary,
                justifyContent: 'center',
                fontFamily: 'Adopekid-Kanit-RC-Stencil',
              }}
            >
              {t('HOME.VOTE_CONTEST_TITLE')}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 8,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: 'NunitoSans-Regular',
                    fontSize: 14,
                  }}
                >
                  {t('HOME.GET_INTO_THE_CONTEST')}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '30%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.24)',
                width: 48,
                height: 48,
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => {
                navigation.navigate('contest')
              }}
            >
              <Icon
                name='ArrowRightIcon'
                width={27}
                height={19}
                color={colors.textPrimary}
              />
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  )
}

export default ContestCard
