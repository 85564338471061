import React from 'react'
import SvgAlertIcon from './AlertIcon'
import SvgArrowRightIcon from './ArrowRightIcon'
import SvgArtistIcon from './ArtistIcon'
import SvgCameraIcon from './CameraIcon'
import SvgChangePasswordIcon from './ChangePasswordIcon'
import SvgChevronRightIcon from './ChevronRightIcon'
import SvgCloseIcon from './CloseIcon'
import SvgCouponsWon from './CouponsWon'
import SvgDeleteUserIcon from './DeleteUserIcon'
import SvgDiamondIcon from './DiamondIcon'
import SvgDnaIcon from './DnaIcon'
import SvgDownloadIcon from './DownloadIcon'
import SvgEditIcon from './EditIcon'
import SvgEventsIcon from './EventsIcon'
import SvgExclamatoryIcon from './ExclamatoryIcon'
import SvgFaceBookIcon from './FaceBookIcon'
import SvgFaceBookSolidIcon from './FaceBookSolidIcon'
import SvgFlagIcon from './FlagIcon'
import SvgGenreIcon from './GenreIcon'
import SvgGiftIcon from './GiftIcon'
import SvgGoogleIcon from './GoogleIcon'
import SvgHeadPhoneIcon from './HeadPhoneIcon'
import SvgHelpIcon from './HelpIcon'
import SvgHomeIcon from './HomeIcon'
import SvgInstagramLogo from './InstagramLogo'
import SvgInstagramSolidIcon from './InstagramSolidIcon'
import SvgInviteFriendsIcon from './InviteFriendsIcon'
import SvgLogOutIcon from './LogOutIcon'
import SvgLoveIcon from './LoveIcon'
import SvgMapIcon from './MapIcon'
import SvgMenuIcon from './MenuIcon'
import SvgMusicCordIcon from './MusicCordIcon'
import SvgMusicIcon from './MusicIcon'
import SvgProfileIcon from './ProfileIcon'
import SvgScannerIcon from './ScannerIcon'
import SvgShareIcon from './ShareIcon'
import SvgShieldIcon from './ShieldIcon'
import SvgSpotifyIcon from './SpotifyIcon'
import SvgStarIcon from './StarIcon'
import SvgThumbsUpIcon from './ThumbsUpIcon'
import SvgTickIcon from './TickIcon'
import SvgWaveIcon from './WaveIcon'
import SvgYoutubeIcon from './YoutubeIcon'
import SvgPieIcon from './PieIcon'
import SvgLighBuldIcon from './LightBulbIcon'
import SvgHeartIcon from './HeartIcon'

export const Icon = (props) => {
  if (props.name === 'AlertIcon') {
    return <SvgAlertIcon {...props} />
  }
  if (props.name === 'ArrowRightIcon') {
    return <SvgArrowRightIcon {...props} />
  }
  if (props.name === 'ArtistIcon') {
    return <SvgArtistIcon {...props} />
  }
  if (props.name === 'CameraIcon') {
    return <SvgCameraIcon {...props} />
  }
  if (props.name === 'ChangePasswordIcon') {
    return <SvgChangePasswordIcon {...props} />
  }
  if (props.name === 'ChevronRightIcon') {
    return <SvgChevronRightIcon {...props} />
  }
  if (props.name === 'CloseIcon') {
    return <SvgCloseIcon {...props} />
  }
  if (props.name === 'CouponsWon') {
    return <SvgCouponsWon {...props} />
  }
  if (props.name === 'DeleteUserIcon') {
    return <SvgDeleteUserIcon {...props} />
  }
  if (props.name === 'DiamondIcon') {
    return <SvgDiamondIcon {...props} />
  }
  if (props.name === 'DnaIcon') {
    return <SvgDnaIcon {...props} />
  }
  if (props.name === 'DownloadIcon') {
    return <SvgDownloadIcon {...props} />
  }
  if (props.name === 'EditIcon') {
    return <SvgEditIcon {...props} />
  }
  if (props.name === 'EventsIcon') {
    return <SvgEventsIcon {...props} />
  }
  if (props.name === 'ExclamatoryIcon') {
    return <SvgExclamatoryIcon {...props} />
  }
  if (props.name === 'FaceBookIcon') {
    return <SvgFaceBookIcon {...props} />
  }
  if (props.name === 'FaceBookSolidIcon') {
    return <SvgFaceBookSolidIcon {...props} />
  }
  if (props.name === 'FlagIcon') {
    return <SvgFlagIcon {...props} />
  }
  if (props.name === 'GenreIcon') {
    return <SvgGenreIcon {...props} />
  }
  if (props.name === 'GiftIcon') {
    return <SvgGiftIcon {...props} />
  }
  if (props.name === 'GoogleIcon') {
    return <SvgGoogleIcon {...props} />
  }
  if (props.name === 'HeadPhoneIcon') {
    return <SvgHeadPhoneIcon {...props} />
  }
  if (props.name === 'HelpIcon') {
    return <SvgHelpIcon {...props} />
  }
  if (props.name === 'HomeIcon') {
    return <SvgHomeIcon {...props} />
  }
  if (props.name === 'InstagramLogo') {
    return <SvgInstagramLogo {...props} />
  }
  if (props.name === 'InstagramSolidIcon') {
    return <SvgInstagramSolidIcon {...props} />
  }
  if (props.name === 'InviteFriendsIcon') {
    return <SvgInviteFriendsIcon {...props} />
  }
  if (props.name === 'LogOutIcon') {
    return <SvgLogOutIcon {...props} />
  }
  if (props.name === 'LoveIcon') {
    return <SvgLoveIcon {...props} />
  }
  if (props.name === 'MapIcon') {
    return <SvgMapIcon {...props} />
  }
  if (props.name === 'MenuIcon') {
    return <SvgMenuIcon {...props} />
  }
  if (props.name === 'MusicCordIcon') {
    return <SvgMusicCordIcon {...props} />
  }
  if (props.name === 'MusicIcon') {
    return <SvgMusicIcon {...props} />
  }
  if (props.name === 'ProfileIcon') {
    return <SvgProfileIcon {...props} />
  }
  if (props.name === 'ScannerIcon') {
    return <SvgScannerIcon {...props} />
  }
  if (props.name === 'ShareIcon') {
    return <SvgShareIcon {...props} />
  }
  if (props.name === 'ShieldIcon') {
    return <SvgShieldIcon {...props} />
  }
  if (props.name === 'SpotifyIcon') {
    return <SvgSpotifyIcon {...props} />
  }
  if (props.name === 'StarIcon') {
    return <SvgStarIcon {...props} />
  }
  if (props.name === 'ThumbsUpIcon') {
    return <SvgThumbsUpIcon {...props} />
  }
  if (props.name === 'TickIcon') {
    return <SvgTickIcon {...props} />
  }
  if (props.name === 'WaveIcon') {
    return <SvgWaveIcon {...props} />
  }
  if (props.name === 'YoutubeIcon') {
    return <SvgYoutubeIcon {...props} />
  }
  if (props.name === 'PieIcon') {
    return <SvgPieIcon {...props} />
  }
  if (props.name === 'LightBuldIcon') {
    return <SvgLighBuldIcon {...props} />
  }
  if (props.name === 'HeartIcon') {
    return <SvgHeartIcon {...props} />
  }
}
