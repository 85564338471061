import { useEffect, useState } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import awsConfigure from 'web-cra/awsConfig'
// import { PASSWORD_UPDATED } from '../../constant'
import { useNavigation } from '@react-navigation/native'

Amplify.configure(awsConfigure)

const UseResetPassword = () => {
  const [email, setEmail] = useState('')
  const [confirmationCode, setConfirmationCode] = useState(null)
  const [verifyEmailId, setVerifyEmailId] = useState(false)
  const [password, setPassword] = useState()
  const navigation = useNavigation()

  function validateEmailId(emailId) {
    // eslint-disable-next-line prefer-regex-literals
    const mailFormat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    )
    setVerifyEmailId(mailFormat.test(emailId.toLowerCase()))
  }

  useEffect(() => {
    validateEmailId(email)
  }, [email])

  async function submitNewPassword() {
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, password)
      navigation.navigate('verification', {
        VerificationType: 'PasswordUpdated',
      })
    } catch (error) {
      console.log({ error })
    }
  }
  return {
    validateEmailId,
    verifyEmailId,
    setEmail,
    submitNewPassword,
    setPassword,
    setConfirmationCode,
    confirmationCode,
  }
}

export default UseResetPassword
