import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgScannerIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 30 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M6.563.75A2.813 2.813 0 0 0 3.75 3.563V8.25a.937.937 0 1 0 1.875 0V3.562a.937.937 0 0 1 .938-.937h4.687a.937.937 0 0 0 0-1.875H6.562Zm4.687 20.625H6.562a.938.938 0 0 1-.937-.938V15.75a.937.937 0 0 0-1.875 0v4.688a2.812 2.812 0 0 0 2.813 2.812h4.687a.938.938 0 0 0 0-1.875Zm14.063-6.563a.938.938 0 0 0-.938.938v4.688a.938.938 0 0 1-.938.937H18.75a.938.938 0 0 0 0 1.875h4.688a2.812 2.812 0 0 0 2.812-2.813V15.75a.938.938 0 0 0-.938-.938Zm0-5.624a.937.937 0 0 0 .937-.938V3.562A2.812 2.812 0 0 0 23.437.75H18.75a.938.938 0 0 0 0 1.875h4.688a.937.937 0 0 1 .937.938V8.25a.938.938 0 0 0 .938.938Zm2.812 1.874H1.875a.938.938 0 0 0 0 1.876h26.25a.938.938 0 0 0 0-1.876Z'
      fill='#6EC1E4'
    />
  </Svg>
)

export default SvgScannerIcon
