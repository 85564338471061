import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgCouponsWon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='m17.3 1.67 4.919 15.572a1.381 1.381 0 0 1-.9 1.73L7.84 23.23a1.381 1.381 0 0 1-1.73-.899L1.189 6.758a1.381 1.381 0 0 1 .9-1.73L15.57.77a1.381 1.381 0 0 1 1.73.9Z'
      fill='#F7CE65'
    />
    <Path
      d='m16.007 3.208 2.784 8.813a.762.762 0 0 1-.494.957L6.87 16.588a.763.763 0 0 1-.954-.5L3.132 7.274a.759.759 0 0 1 .496-.952l11.426-3.61a.759.759 0 0 1 .953.496Z'
      fill='#E9EDF4'
    />
    <Path
      d='M22.874 6.22v15.696a1.381 1.381 0 0 1-1.377 1.378H7.358a1.382 1.382 0 0 1-1.379-1.378V6.22a1.381 1.381 0 0 1 1.38-1.379h14.137a1.382 1.382 0 0 1 1.379 1.379Z'
      fill='#9CD0FF'
    />
    <Path
      d='M21.177 7.298v9.243a.762.762 0 0 1-.758.764H8.435a.762.762 0 0 1-.76-.764V7.298a.759.759 0 0 1 .76-.76h11.983a.76.76 0 0 1 .76.76Z'
      fill='#E9EDF4'
    />
    <Path
      d='M18.805 20.933a.374.374 0 0 1-.374.374h-8.008a.374.374 0 0 1 0-.748h8.008c.208 0 .374.166.374.374Zm1.941-1.677a.374.374 0 0 1-.374.375H8.482a.374.374 0 0 1 0-.748h11.89c.208 0 .374.165.374.373Z'
      fill='#659BB1'
    />
    <Path
      d='m17.771 11.42-.804.814a.816.816 0 0 0-.217.694l.19 1.155c.106.64-.541 1.128-1.093.825l-.996-.544a.726.726 0 0 0-.702 0l-.996.544c-.552.303-1.199-.185-1.093-.825l.19-1.155a.816.816 0 0 0-.217-.694l-.804-.815c-.448-.453-.2-1.243.417-1.336l1.114-.169a.738.738 0 0 0 .334-.142.776.776 0 0 0 .232-.287l.498-1.047a.74.74 0 0 1 1.352 0l.498 1.047a.777.777 0 0 0 .232.287.738.738 0 0 0 .334.142l1.114.17c.617.092.866.882.417 1.336Z'
      fill='#F7CE65'
    />
  </Svg>
)

export default SvgCouponsWon
