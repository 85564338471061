import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgArrowRightIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 27 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m18.343.293 8.364 8.364a1 1 0 0 1 0 1.414l-8.364 8.364a1 1 0 1 1-1.414-1.414l6.657-6.657H0v-2h23.586l-6.657-6.657A1 1 0 0 1 18.343.293Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgArrowRightIcon
