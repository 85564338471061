import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgStarIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path d='M12.125 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24Z' fill='#F7CE65' />
    <Path
      d='M16.34 17.838c-.69.504-3.344-.808-4.199-.806-.855.003-3.502 1.326-4.195.825-.693-.501-.265-3.43-.531-4.243-.267-.813-2.344-2.921-2.082-3.735.263-.814 3.18-1.312 3.871-1.817.691-.504 2.055-3.131 2.91-3.133.855-.003 2.23 2.62 2.923 3.12.693.501 3.614.986 3.88 1.799s-1.802 2.93-2.065 3.745c-.262.814.18 3.74-.511 4.245Z'
      fill='#fff'
    />
  </Svg>
)

export default SvgStarIcon
