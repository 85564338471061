/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import { Surface, Text } from 'react-native-paper'
import { TouchableOpacity, View } from 'react-native'
import { OffersCard, PointCard } from '@nowon/card'
import { Container } from '@nowon/container'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import {
  getOffers,
  getAllUserProducts,
  getUserById,
  getAllOffersCount,
} from '../../apiGraphql'
import {
  orderBy
} from 'lodash'

export const YourPoints = (props) => {
  const { user, colors, t } = props
  const isFocussed = useIsFocused()
  const navigation = useNavigation()
  const isRendered = useIsFocused()
  const [availableOfferData, setAvailableOfferData] = useState([])
  const [filteredOfferData, setFilteredOfferData] = useState([])

  const { data: userData } = useQuery(
    ['getUserById'],
    async () => {
      const response = await getUserById(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )

  const { data: allOffersCount } = useQuery(
    ['getAllOffersCount'],
    async () => {
      const response = await getAllOffersCount()
      return response
    },
    {
      enabled: isRendered,
    },
    {
      initialData: [],
    },
  )

  const { data: offersData } = useQuery(
    ['getOffers'],
    async () => {
      const response = await getOffers()
      return response
    },
    {
      enabled: isFocussed,
      initialData: [],
    },
  )

  const { data: allUserProducts } = useQuery(
    ['getAllUserProducts'],
    async () => {
      const response = await getAllUserProducts(user?.userId)
      return response
    },
    {
      enabled: isFocussed && !!user?.userId,
      initialData: [],
    },
  )

  useEffect(() => {
    if (!offersData) return
    if (allOffersCount?.length > 0) {
      const filterBasedOnAvailable = offersData.map((f) => {
        if (f?.offerType === 'RAFFLE') {
          return f
        }
        return {
          ...f,
          availableOffers:
            f.availableOffers -
            (allOffersCount?.find((e) => e.title === f?.title)?.count || 0),
        }
      })
      setAvailableOfferData(filterBasedOnAvailable)
    } else {
      setAvailableOfferData(offersData)
    }
  }, [offersData, allOffersCount])

  useEffect(() => {
    if (!availableOfferData || !isFocussed) return
    ;(async () => {
      if (allUserProducts.length > 0) {
        const filterRaffleData = availableOfferData.filter(
          (oD) => oD.offerType === 'RAFFLE',
        )
        const filteredData = allUserProducts.filter((f) =>
          filterRaffleData.some((e) => e?.title === f?.title),
        )
        const finalFilteredData = availableOfferData.filter(
          (f) => !filteredData.some((e) => e?.title === f?.title),
        )
        const EndFilterData = finalFilteredData

        const sorted = orderBy(EndFilterData, (item) => Number(item.availableOffers), 'desc')
        setFilteredOfferData(sorted)
      } else {
        const sorted = orderBy(availableOfferData, (item) => Number(item.availableOffers), 'desc')
        setFilteredOfferData(sorted)
      }
    })()
  }, [allUserProducts, availableOfferData, isFocussed, allOffersCount])

  const availablePoints =
    Number(userData?.earnedPoints) - Number(userData?.redeemedPoints)

  return (
    <Container>
      <Surface elevation={0} style={{ gap: '12px', paddingBottom: 27 }}>
        <Surface
          elevation={0}
          style={{
            gap: '13px',
          }}
        >
          <Text variant='bodySmall' style={{ color: colors.primaryVariant }}>
            <TouchableOpacity onPress={() => navigation.navigate('challenges')}>
              {t('CHALLENGES')}
            </TouchableOpacity>
            {'>'} {t('YOUR_POINTS')}
          </Text>
        </Surface>
        <Surface
          elevation={0}
          style={{
            gap: '13px',
          }}
        >
          <Text variant='headlineLarge' style={{ color: colors.primary }}>
            {t('YOUR_POINTS')}
          </Text>
        </Surface>
        <Surface
          elevation={0}
          style={{
            gap: '13px',
          }}
        >
          <Text variant='bodyLarge' style={{ color: colors.primaryVariant }}>
            {t('REWARD_POINT_INFO')}
          </Text>
        </Surface>
        <Surface
          style={{
            borderRadius: 8,
            paddingVertical: 12,
            paddingHorizontal: 18,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Surface elevation={0}>
            <Text
              variant='headlineSmall'
              style={{ color: colors.primaryDisabled }}
            >
              {userData?.earnedPoints}
            </Text>
            <Text
              variant='labelMedium'
              style={{
                color: colors.primaryDisabled,
                opacity: 0.5,
              }}
            >
              {t('POINTS_EARNED')}
            </Text>
          </Surface>

          <Surface
            elevation={0}
            style={{
              height: 50,
              width: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          />

          <Surface elevation={0}>
            <Text variant='titleLarge' style={{ color: colors.primaryOn }}>
              {userData && availablePoints}
            </Text>
            <Text
              variant='labelMedium'
              style={{
                color: colors.primaryOn,
                opacity: 0.5,
              }}
            >
              {t('AVAILABLE_POINTS')}
            </Text>
          </Surface>
        </Surface>

        <Surface
          style={{
            borderRadius: 8,
            paddingVertical: 12,
            paddingHorizontal: 18,
          }}
        >
          <Surface
            elevation={0}
            style={{ flexDirection: 'row', alignItems: 'baseLine' }}
          >
            <Text
              variant='headlineSmall'
              style={{ padding: 10, color: colors.primaryVariant }}
            >
              {userData?.redeemedPoints}
            </Text>
            <Text
              variant='labelMedium'
              style={{ color: colors.primaryVariant }}
            >
              {t('REDEEMED_POINTS')}
            </Text>
          </Surface>
        </Surface>

        <Surface
          elevation={0}
          style={{
            flexDirection: 'row',
            paddingHorizontal: 10,
          }}
        >
          <Text variant='bodyLarge' style={{ color: colors.textPrimary }}>
            {t('OFFERS_AVAILABLE')}
          </Text>
        </Surface>
        {allUserProducts?.length > 0 && (
          <Surface
            style={{ paddingVertical: 24, paddingHorizontal: 12, gap: 14 }}
          >
            <Surface elevation={0} style={{ flexDirection: 'row', gap: 8 }}>
              {userData?.redeemedPoints > 0 && (
                <Text
                  variant='headlineSmall'
                  style={{ color: colors.textPrimary }}
                >
                  {userData?.redeemedPoints}
                </Text>
              )}
              <Text
                variant='labelMedium'
                style={{ color: colors.textPrimary, marginTop: 12 }}
              >
                {t('REDEEM_POINTS')}
              </Text>
            </Surface>
            {allUserProducts.map((ap) => (
              <PointCard
                value={-ap.points}
                heading={ap?.title}
                offerType={ap.offerType}
                indicator={ap?.status}
                redeemedDate={ap?.redeemedDate}
              />
            ))}
          </Surface>
        )}
        {filteredOfferData
          .map((item, index) => (
            <View key={index}>
              <OffersCard
                item={item}
                title={item?.title}
                description={item?.description}
                label={`${t('POINTS_REQUIRED')} - ${item?.pointsRequired}`}
                imageURL={item?.imageURL}
                onPress={() =>
                  navigation.navigate('redeem', {
                    id: item?.SK,
                    availablePoints,
                    earnedPoints: userData?.earnedPoints,
                  })
                }
                disabled={Number(item.availableOffers) <= 0}
              />
            </View>
          ))}
      </Surface>
    </Container>
  )
}
