import * as React from 'react'
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg'

const SvgGenreIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <G clipPath='url(#GenreIcon_svg__a)' fill='#B35A5B'>
      <Path d='M6 8.07h-.436A4.335 4.335 0 0 0 2.35 9.33l-.12.14v4.14h2.04v-2.35l.275-.31.125-.145a5.5 5.5 0 0 1 2.355-1.43c-.447-.34-.8-.79-1.025-1.305ZM15.67 9.315a4.335 4.335 0 0 0-3.215-1.26c-.182 0-.364.01-.545.03a3.296 3.296 0 0 1-1 1.225 5.454 5.454 0 0 1 2.5 1.5l.125.14.27.31v2.355h1.97v-4.16l-.105-.14ZM5.55 7.095h.154A3.225 3.225 0 0 1 7.26 3.95a2.045 2.045 0 1 0-1.71 3.165v-.02Z' />
      <Path d='M9.055 10.15A4.844 4.844 0 0 0 5.5 11.5l-.125.14v3.165a.785.785 0 0 0 .8.77h5.744a.785.785 0 0 0 .8-.77V11.65l-.12-.15a4.791 4.791 0 0 0-3.544-1.35ZM8.934 8.96a2.23 2.23 0 0 0 2-1.27A1.835 1.835 0 0 1 9.5 4.945l.175-.305a2.21 2.21 0 0 0-.74-.14 2.235 2.235 0 1 0 0 4.465V8.96ZM13.425.57l-2.86 4.93a.641.641 0 0 0 .55 1h5.725a.64.64 0 0 0 .55-1L14.53.57a.64.64 0 0 0-1.105 0Z' />
    </G>
    <Defs>
      <ClipPath id='GenreIcon_svg__a'>
        <Path fill='#fff' d='M0 0h18v18H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SvgGenreIcon
