import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { useNavigation } from '@react-navigation/native'
import { getBlacklistEmailLambdaApi } from '../../api'
import { useTranslation } from 'react-i18next'

const useRegister = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [verifyEmailId, setVerifyEmailId] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  console.log(referralCode)

  const signUp = async () => {
    try {
      const emailDomain = email.split('@').pop()
      const blRes = await getBlacklistEmailLambdaApi(emailDomain);

      if (blRes.data) {
        setErrorMsg(t('TEMP_EMAIL_ERROR'));
        return
      }

      const response = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:referralCode': referralCode,
        },
        autoSignIn: {
          enabled: false,
        },
      })
      if (response?.user) {
        setEmail('')
        setPassword('')
        navigation.navigate('splash', {
          time: 5000,
          path: 'login',
        })
      }
    } catch (error) {
      if (error?.message?.includes('||')) {
        const authError = error?.message?.split('||')
        setErrorMsg(authError[1])
      } else {
        setErrorMsg(error?.message)
      }
    }
  }
  function validateEmailId(emailId) {
    // eslint-disable-next-line prefer-regex-literals
    const mailFormat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    )
    setVerifyEmailId(mailFormat.test(emailId.toLowerCase()))
  }

  const facebookSignIn = async () => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const googleSignIn = async () => {
    try {
      console.log('google')
      await Auth.federatedSignIn({ provider: 'Google' })
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    validateEmailId(email)
  }, [email])

  return {
    privacyChecked,
    setPrivacyChecked,
    signUp,
    name,
    email,
    password,
    setName,
    setEmail,
    setPassword,
    verifyEmailId,
    facebookSignIn,
    googleSignIn,
    referralCode,
    setReferralCode,
    setErrorMsg,
    errorMsg,
  }
}

export default useRegister
