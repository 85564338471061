import React, { useEffect } from 'react'
import { Image, ImageBackground, StyleSheet } from 'react-native'
import { Button, Surface, Text } from 'react-native-paper'
import { Container } from '@nowon/container'
import { Icon } from '@nowon/icons'
import logo from '../../assets/logo.png'
import welcomeRapCity from '../../assets/welcomeRapCity.png'
import aboutRapCity from '../../assets/aboutRapCity.png'
import { setItemAsync } from '../../utils/SecureStore'

export const InviteeHome = (props) => {
  const { colors, navigation, t } = props
  const referralCode = props?.route?.params?.referralCode
  useEffect(() => {
    ;(async () => {
      const response = await setItemAsync('referralCode', referralCode)
      console.log(response)
    })()
  }, [referralCode])

  const getStarted = () => {
    navigation.navigate('register')
  }

  return (
    <Surface elevation={0} style={styles.container}>
      <Surface elevation={0} style={styles.header}>
        <Image
          source={logo}
          style={{
            height: 30,
            width: 112,
          }}
        />
      </Surface>
      <Text
        variant='headlineLarge'
        style={{ color: colors.primary, paddingLeft: 24 }}
      >
        {t('HELLO')}
      </Text>
      <Container>
        <Surface elevation={0}>
          <Image source={welcomeRapCity} style={styles.image} />
          <Text
            variant='headlineSmall'
            style={{
              color: colors.textPrimary,
            }}
          >
            {t('WELCOME_RAPCITY')}
          </Text>
          <Text
            variant='titleMedium'
            style={{
              color: colors.textPrimary,
              marginVertical: 26,
            }}
          >
            {t('FRIEND_INVITE')}
          </Text>
          <Button
            mode='elevated'
            style={{
              height: 48,
              width: 186,
              backgroundColor: colors.primary,
              borderRadius: 24,
              marginBottom: 52,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            labelStyle={{
              textAlign: 'center',
              fontSize: 16,
              lineHeight: 20,
              fontWeight: 400,
              color: colors.textPrimary,
            }}
            onPress={() => getStarted()}
          >
            {t('ACTIONS.GET_STARTED')}
          </Button>
        </Surface>
        <Surface elevation={0}>
          <Text
            variant='headlineSmall'
            style={{
              color: colors.primary,
            }}
          >
            {t('ABOUT_RAPCITY')}
          </Text>
          <Text
            variant='titleMedium'
            style={{
              color: colors.primary,
              marginVertical: 26,
            }}
          >
            {t('LOREM_IPSUM')}
          </Text>
          <Button
            mode='text'
            labelStyle={{
              color: colors.primary,
              fontSize: 15,
              fontWeight: 300,
              lineHeight: 21,
              marginLeft: 0,
            }}
            style={{
              alignSelf: 'flex-start',
            }}
            onPress={() => {}}
          >
            {t('ACTIONS.KNOW_MORE')}
          </Button>
          <ImageBackground source={aboutRapCity} style={styles.backgroundImage}>
            <Button
              mode='elevated'
              style={{
                height: 32,
                width: 118,
                backgroundColor: colors.primary,
                borderRadius: 24,
                marginBottom: 20,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              labelStyle={{
                textAlign: 'center',
                fontSize: 14,
                lineHeight: 17,
                fontWeight: 400,
                color: colors.primary,
              }}
              onPress={() => {}}
            >
              {t('ACTIONS.BUY_TICKET')}
            </Button>
          </ImageBackground>
        </Surface>
        <Surface
          elevation={0}
          style={{
            marginBottom: 50,
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(255, 255, 255, 0.2)',
          }}
        >
          <Text
            variant='headlineSmall'
            style={{
              color: colors.primary,
              marginBottom: 22,
            }}
          >
            {t('FOLLOW_RAPCITY')}
          </Text>
          <Surface
            elevation={0}
            style={{ flexDirection: 'row', marginBottom: 56, gap: 16 }}
          >
            <Surface
              style={{
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: colors.primary,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon name='FaceBookSolidIcon' />
            </Surface>
            <Surface
              style={{
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: colors.primary,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon name='InstagramSolidIcon' />
            </Surface>
            <Surface
              style={{
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: colors.primary,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon name='YoutubeIcon' />
            </Surface>
            <Surface
              style={{
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: colors.primary,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon name='MusicIcon' />
            </Surface>
          </Surface>
        </Surface>
        <Surface elevation={0} style={styles.footer}>
          <Text variant='labelMedium' style={{ color: '#cccccc' }}>
            {t('COPY_RIGHT')}
          </Text>
          <Text variant='labelMedium' style={{ color: '#cccccc' }}>
            {t('PRIVACY_POLICY')}
          </Text>
        </Surface>
      </Container>
      <Surface
        elevation={0}
        style={{
          height: 68,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.neutral,
        }}
      >
        <Button
          mode='outlined'
          labelStyle={{
            textAlign: 'center',
            fontSize: 16,
            lineHeight: 20,
            fontWeight: 400,
            color: colors.primary,
          }}
          style={{
            height: 40,
            width: 186,
            borderColor: colors.primary,
          }}
          onPress={() => getStarted()}
        >
          {t('ACTIONS.GET_STARTED')}
        </Button>
      </Surface>
    </Surface>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#16181F',
  },
  header: {
    height: 59,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255,255,255,0.1)',
    marginBottom: 35,
  },
  image: {
    height: 148,
    width: 327,
    borderRadius: 24,
    marginBottom: 18,
  },
  backgroundImage: {
    height: 240,
    width: 327,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginVertical: 30,
  },
  IconButton: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
  },
  footer: {
    justifyContent: 'space-between',
    paddingTop: 18,
    flexDirection: 'row',
  },
})
