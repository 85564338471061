import React, { useState } from 'react'
import { StyleSheet, Image, View, TouchableOpacity } from 'react-native'
import { Text } from '@nowon/components'
import { Container } from '@nowon/container'
import { IconButton, Surface, MD3Colors } from 'react-native-paper'
import ContestRuleImage from '../../assets/Contest-ruleImage.png'
import ProfitImage from '../../assets/Contest-profitImage.png'
import Modal from 'react-native-modal'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: 'Lexend Deca',
    fontSize: 32,
    color: '#6EC1E4',
    paddingRight: 80,
    marginBottom: 20,
  },
  pageSubtitle: {
    fontSize: 24,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    color: '#FFFFFF',
    opacity: 0.8,
  },
  ruleText: {
    fontFamily: 'Lexend Deca',
    fontSize: 16,
    color: '#CCCCCC',
    paddingRight: 30,
    marginBottom: 30,
  },
  content: {
    borderRadius: 24,
    backgroundColor: '#22242A',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    backdropFilter: 'blur(2px)',
    padding: 22
  },
})

export const ContestInfo = (_props) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <Container>
      <Surface elevation={0} style={{ gap: '22px', paddingBottom: 27 }}>
        <Text style={styles.pageTitle}>{ t('CONTEST.CONTEST_INFO') }</Text>

        <Text style={styles.pageSubtitle}>{ t('CONTEST.RAPCITY_CONTEST') }</Text>
        <Text style={styles.ruleText}>{ t('CONTEST.OVER_TALENTS') }</Text>

        <Text style={styles.pageSubtitle}>{ t('CONTEST.SCHEDULE') }</Text>

        <TouchableOpacity onPress={() => setShowModal(true)}>
          <Image
            source={ContestRuleImage}
            style={{
              height: 440,
              width: 239,
            }}
          />
        </TouchableOpacity>

        <Text style={styles.ruleText}>
          { t('CONTEST.THE_24_RAPPERS_LINE_1') }
          <br />
          <br />
          { t('CONTEST.THE_24_RAPPERS_LINE_2') }
          <br />
          <br />
          { t('CONTEST.THE_24_RAPPERS_LINE_3') }
        </Text>

        <Text style={styles.pageSubtitle}>{ t('CONTEST.WIN') }</Text>

        <Image
          source={ProfitImage}
          style={{
            height: 145,
            width: 241,
          }}
        />

        <Text style={styles.ruleText}>
          { t('CONTEST.DER_ORDER') }
        </Text>
      </Surface>

      <Modal
        isVisible={showModal}
        onSwipeComplete={() => {}}
        swipeDirection={['up', 'left', 'right', 'down']}
        style={styles.view}
      >
        <View style={styles.content}>
          <IconButton
            icon='close'
            iconColor={MD3Colors.neutral100}
            size={18}
            onPress={() => setShowModal(false)}
          />
          <Image
            source={ContestRuleImage}
            style={{
              height: 622,
              width: 337,
            }}
          />
        </View>
      </Modal>
    </Container>
  )
}
