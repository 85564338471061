import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { TextInput, HelperText, useTheme } from 'react-native-paper'
import PropTypes from 'prop-types'

const EmailTextInput = (props) => {
  const { colors } = useTheme()
  const { disabled = false, onChangeText, errorMessage } = props
  const [errorMsg, setErrorMsg] = useState('')

  const validateEmail = (email) => {
    if (email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!re.test(email)) {
        setErrorMsg(errorMessage)
      }
      return re.test(email)
    }

    return true
  }

  return (
    <View style={disabled && styles.disabled}>
      <TextInput
        {...props}
        onChangeText={(text) => {
          onChangeText(text)
        }}
        placeholderTextColor={colors.onSurfacePlaceholder}
        disabled={disabled}
        theme={{
          colors: {
            onSurface: colors.surface,
          },
        }}
        onFocus={() => {
          setErrorMsg('')
        }}
        onBlur={(text) => {
          validateEmail(text?.nativeEvent?.text)
        }}
      />
      {errorMsg && (
        <HelperText type='error' visible={errorMsg?.length > 0}>
          {errorMsg}
        </HelperText>
      )}
    </View>
  )
}

EmailTextInput.defaultProps = {
  errorMessage: 'Email address is invalid!',
}

EmailTextInput.propTypes = {
  onValidate: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

const styles = StyleSheet.create({
  disabled: {
    borderColor: '#808080',
    borderWidth: '1px',
    borderRadius: 4,
    marginTop: 5,
    height: 40,
  },
})

export default EmailTextInput
