import * as React from 'react'
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg'

const SvgChangePasswordIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <G clipPath='url(#ChangePasswordIcon_svg__a)'>
      <Path d='M20 10a10 10 0 1 0-20 0 10 10 0 0 0 20 0Z' fill={props.color} />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.036 8.54a.312.312 0 0 0-.252.475l.397.673h-.804a.313.313 0 0 0 0 .625h.804l-.397.671a.312.312 0 1 0 .538.319l.406-.689.407.688a.312.312 0 1 0 .538-.318l-.396-.671h.803a.313.313 0 1 0 0-.625h-.804l.397-.672a.312.312 0 1 0-.538-.319l-.407.689-.406-.689a.313.313 0 0 0-.287-.157Zm8.541 0a.312.312 0 0 0-.25.475l.396.673h-.804a.312.312 0 0 0 0 .625h.804l-.397.671a.313.313 0 1 0 .538.319l.406-.689.407.688a.312.312 0 1 0 .538-.318l-.396-.671h.803a.312.312 0 0 0 0-.625h-.804l.397-.672a.312.312 0 1 0-.538-.319l-.407.689-.406-.689a.312.312 0 0 0-.287-.157Zm-4.27 0a.312.312 0 0 0-.252.475l.397.673h-.804a.312.312 0 0 0 0 .625h.804l-.397.671a.312.312 0 1 0 .538.319l.406-.689.407.688a.312.312 0 0 0 .538-.318l-.396-.671h.803a.313.313 0 1 0 0-.625h-.804l.397-.672a.312.312 0 1 0-.538-.319L10 9.386l-.406-.689a.313.313 0 0 0-.286-.157Z'
        fill='#222'
      />
    </G>
    <Defs>
      <ClipPath id='ChangePasswordIcon_svg__a'>
        <Path fill='#fff' d='M0 0h20v20H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SvgChangePasswordIcon
