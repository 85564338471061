import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgMenuIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 24 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path stroke={props.color} d='M0 .5h24M0 10.5h24' />
  </Svg>
)

export default SvgMenuIcon
