import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgDiamondIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M14.737 4.21H8.42l-7.368 9.474h10.526l3.158-9.473Z'
      fill='#71E7E7'
    />
    <Path
      d='m38.947 13.684-7.369-9.473h-6.315l3.158 9.473h10.526Z'
      fill='#59C2C2'
    />
    <Path d='M28.421 13.684h10.526L20 35.79l8.421-22.105Z' fill='#62D1D1' />
    <Path d='M11.58 13.684H1.052L20 35.79l-8.42-22.105Z' fill='#79F2F2' />
    <Path
      d='m14.737 4.21-3.158 9.474h16.842l-3.158-9.473H14.737Z'
      fill='#65D8D8'
    />
    <Path d='m20 35.79-8.42-22.106H28.42l-8.42 22.105Z' fill='#6EE4E4' />
  </Svg>
)

export default SvgDiamondIcon
