import { useEffect, useState } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import awsConfigure from 'web-cra/awsConfig'
// import { PASSWORD_SENT } from '../../constant'
import { useNavigation } from '@react-navigation/native'

Amplify.configure(awsConfigure)

const UseForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [verifyEmailId, setVerifyEmailId] = useState(false)
  const navigation = useNavigation()

  function validateEmailId(emailId) {
    // eslint-disable-next-line prefer-regex-literals
    const mailFormat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    )
    setVerifyEmailId(mailFormat.test(emailId.toLowerCase()))
  }

  useEffect(() => {
    validateEmailId(email)
  }, [email])

  async function forgotPassword() {
    try {
      await Auth.forgotPassword(email)
      navigation.navigate('verification', {
        verificationType: '',
      })
    } catch (error) {
      console.log({ error })
    }
  }
  return {
    validateEmailId,
    verifyEmailId,
    setEmail,
    forgotPassword,
  }
}

export default UseForgotPassword
