import React from 'react'
import { TouchableOpacity, StyleSheet, View } from 'react-native'
import { useTheme, Card } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import { useNavigation } from '@react-navigation/native'
import { Icon } from '@nowon/icons'

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: 24,
  },
  cardTitle: {
    justifyContent: 'flex-end',
    margin: 0,
    color: '#CCCCCC',
    height: 50,
    minHeight: 50,
  },
  cardSubtitleStyle: { color: '#CCCCCC', fontSize: 16 },
})

const ArtistCard = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('contestInfo')
      }}
    >
      <Card style={styles.card}>
        <Card.Title
          style={styles.cardTitle}
          subtitleStyle={styles.cardSubtitleStyle}
          subtitle={`💡  ${t('CONTEST.CONTEST_INFO')}`}
        />
        <Card.Content>
          <Text style={{ fontSize: 14, color: '#CCCCCC', paddingRight: 120 }}>
            { t('CONTEST.KNOW_ALL') }
          </Text>

          <View
            style={{
              position: 'absolute',
              right: 20,
              bottom: 20,
              backgroundColor: '#6EC1E4',
              width: 21,
              height: 21,
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon
              name='ChevronRightIcon'
              height={11}
              color={colors.textPrimary}
            />
          </View>
        </Card.Content>
      </Card>
    </TouchableOpacity>
  )
}

export default ArtistCard
