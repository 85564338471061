import React, { useEffect, useState } from 'react'
import { Container } from '@nowon/container'
import { Surface, Text } from 'react-native-paper'
import { useIsFocused } from '@react-navigation/native'
import { ScrollView, View } from 'react-native'
import { DNAcard } from '@nowon/card'
import { useQuery } from '@tanstack/react-query'
import { PlayListCard } from '../../components'
import { getAdminTopTracks, getMatchedPercentage } from '../../apiGraphql'
import { SPOTIFY_ADMIN_USER_ID } from '../../config'

export const PlaylistTrack = (props) => {
  const { colors, t, user } = props
  const [userData, setUserData] = useState([])
  const isRendered = useIsFocused()

  const { data: adminTopTracks } = useQuery(
    ['getAdminTopTracks'],
    async () => {
      const response = await getAdminTopTracks(SPOTIFY_ADMIN_USER_ID)
      return response.songs.slice(0, 10)
    },
    {
      enabled: !!user?.userId,
      initialData: [],
    },
  )

  const { data: matchedUsers } = useQuery(
    ['getMatchedPercentage'],
    async () => {
      const response = await getMatchedPercentage()
      const result = response.filter((e) => e?.userId !== SPOTIFY_ADMIN_USER_ID)
      setUserData(result)

      return result
    },
    {
      enabled: !!user?.userId,
      initialData: [],
    },
  )

  useEffect(() => {
    if (!userData) return
    var index = userData.findIndex(function (item) {
      return item.userId === user?.userId
    })
    userData.unshift(userData.splice(index, 1)[0])
  }, [userData])

  return (
    <Container>
      <Surface elevation={0} style={{ paddingBottom: 20, gap: 20 }}>
        <Text
          variant='titleMedium'
          style={{ fontWeight: 600, color: colors.textPrimary }}
        >
          {t('TOP')} {t('COMMUNITY_SONGS')}
        </Text>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {adminTopTracks?.map((e) => (
            <PlayListCard
              colors={colors}
              t={t}
              image={e?.album?.images[0].url}
              title={e?.name}
              url={e?.external_urls?.spotify}
            />
          ))}
        </ScrollView>
      </Surface>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <Text
          variant='titleMedium'
          style={{
            fontWeight: 600,
            color: colors.textPrimary,
          }}
        >
          {t('COMMUNITY_DNA')}
        </Text>{' '}
        <View
          style={{
            height: 1,
            width: '37%',

            borderColor: 'rgba(255, 255, 255, 0.2)',
            borderWidth: 0.4,
            marginVertical: 10,
          }}
        />
      </View>
      {userData?.map((e) => (
        <View style={{ paddingVertical: 5 }}>
          <DNAcard
            matchedArtists={e?.matchedPercentages[0]?.matchedArtistPercentage}
            matchedSongs={e?.matchedPercentages[0]?.matchedSongsPercentage}
            matchedGenres={e?.matchedPercentages[0]?.matchedGenrePercentage}
            totalPercentage={e?.matchedPercentages[0]?.totalPercentage}
            name={e?.spotifyUserName}
            image={e?.spotifyUserImage[0]?.url}
          />
        </View>
      ))}
    </Container>
  )
}
