import React from 'react'
import { View, Image, Platform } from 'react-native'
import { Surface, Text, Button } from 'react-native-paper'
import PhotoEmpty from '../../assets/photoEmpty.png'

const ComingSoon = (props) => {
  const { colors, t, navigation, user } = props
  const handleBackNavigation = () => {
    if (Platform.OS === 'web') {
      window.history.go(-1)
    } else {
      navigation.goBack()
    }
  }
  return (
    <Surface elevation={0}>
      <View style={{}}>
        <Text variant='headlineLarge' style={{ color: colors.primary }}>
          {t('RAPCITY_MEMORY')}
        </Text>
      </View>
      <View
        style={{
          marginTop: '16px',
        }}
      >
        <Image
          source={PhotoEmpty}
          style={{
            height: 188,
            width: 327,
            marginTop: '56px',
          }}
          imageStyle={{ borderRadius: 15 }}
          resizeMode='cover'
        />
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 29,
          }}
        >
          <Text
            variant='titleMedium'
            style={{
              flexDirection: 'row',
              color: colors.textPrimary,
              justifyContent: 'center',
            }}
          >
            {t('HELLO')}{' '}
            {user?.userName || user?.firstName || t('RAPCITY_MEMBER')}
          </Text>
          <Text
            variant='titleMedium'
            style={{
              flexDirection: 'row',
              color: colors.textPrimary,
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {t('GET_BACK_SOON')}
          </Text>
        </View>
        <Button
          style={{
            height: 48,
            width: 150,
            marginBottom: 53,
            marginTop: 23,
            justifyContent: 'center',
            alignSelf: 'center',
            borderColor: colors.primary,
            borderRadius: 24,
          }}
          mode='outlined'
          onPress={handleBackNavigation}
          labelStyle={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 18,
            color: colors.textPrimary,
          }}
        >
          {t('ACTIONS.BACK')}
        </Button>
      </View>
    </Surface>
  )
}

export default ComingSoon
