import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Text } from '@nowon/components'
import { Container } from '@nowon/container'
import GetStartedModal from '../../components/Contest/GetStartedModal'
import VotingCard from '../../components/Contest/VotingCard'
import ArtistCard from '../../components/Contest/ArtistCard'
import MostVotedArtistCard from '../../components/Contest/MostVotedArtistCard'
import InformationCard from '../../components/Contest/InformationCard'
import { Surface, Card } from 'react-native-paper'
import { Icon } from '@nowon/icons'
import { createVoteTracker } from '../../apiGraphql'
import { useSelector } from 'react-redux'
import VotedCard from '../../components/Contest/VotedCard'
import { useTranslation } from 'react-i18next'
import { getContestDaysLeft } from '../../utils/contestHelper'

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: 'Lexend Deca',
    fontSize: 32,
    color: '#6EC1E4',
  },
  votingEndCard: {
    borderRadius: 12,
    border: '1px solid rgba(110, 228, 228, 0.1)',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  votingEndCardView: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  votingEndCardViewText: {
    fontFamily: 'Lexend Deca',
    fontSize: 16,
    fontWeight: 300,
    color: '#CCCCCC',
  },
  votingEndCardViewDayLeftText: {
    fontFamily: 'Lexend Deca',
    fontSize: 24,
    fontWeight: 300,
    color: '#6EE4E4',
    textAlign: 'end',
    flexGrow: 1,
  },
})

export const Contest = (props) => {
  const { user } = props
  const { t } = useTranslation()
  const [showGetStarted, setShowGetStarted] = useState(false)
  const { voteTracker, artistProfiles, votedArtist, isEnded } = useSelector(
    (state) => state?.contestReducer,
  )

  useEffect(() => {
    if (isEnded) {
      navigation.navigate('contestWinners')
    }

  }, [isEnded])

  useEffect(() => {
    if (voteTracker === false) {
      return
    }

    if (voteTracker === null) {
      setShowGetStarted(true)
    }
  }, [voteTracker])

  const onClickGetStarted = async () => {
    // TODO: handle error message
    await createVoteTracker(user.userId)

    setShowGetStarted(false)
  }

  return (
    <Container>
      <Surface elevation={0} style={{ gap: '22px', paddingBottom: 27 }}>
        <Text style={styles.pageTitle}>{ t('CONTEST.RAPCITY_CONTEST') }</Text>
        {votedArtist ? (
          <VotedCard
            voteTracker={voteTracker}
            votedArtist={votedArtist}
          ></VotedCard>
        ) : (
          <VotingCard voteTracker={voteTracker} votedArtist={votedArtist} />
        )}

        <Card style={styles.votingEndCard}>
          <Card.Content style={styles.votingEndCardView}>
            <Icon name='PieIcon' width={24} height={24} />
            <Text style={styles.votingEndCardViewText}>{ t('CONTEST.ENDIND_IN') }</Text>
            <Text style={styles.votingEndCardViewDayLeftText}>{ t('CONTEST.DAYS', { day: getContestDaysLeft() }) }</Text>
          </Card.Content>
        </Card>

        <ArtistCard />

        <MostVotedArtistCard artistProfiles={artistProfiles} />

        <InformationCard />
      </Surface>

      <GetStartedModal
        showGetStarted={showGetStarted}
        onClickGetStarted={onClickGetStarted}
      />
    </Container>
  )
}
