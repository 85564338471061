import React, { useEffect, useRef, useState } from 'react'
import { Text } from '@nowon/components'
import { getVotesByArtistId } from '../../apiGraphql'
import awsConfigure from 'web-cra/awsConfig'
import { Amplify, graphqlOperation, API }  from "aws-amplify";
import { onCreateVote } from '../../../graphql/subscriptions';

Amplify.configure(awsConfigure)

const VoteCount = (props) => {
  const { artist, styleInput } = props
  const [voteCount, setVoteCount] = useState(0)
  const subscription = useRef(null);

  const getVotes = async () => {
    const response = await getVotesByArtistId(artist.guid);

    setVoteCount(response.length)
  }

  useEffect(() => {
    getVotes()
  }, [artist])

	useEffect(() => {
    subscription.current = API.graphql(
      graphqlOperation(onCreateVote)
    ).subscribe({
      next: async (data) => {
        const { value } = data;
        const newVote = value.data.onCreateVote;

        if (newVote.artistId === artist.guid) {
          await getVotes();
        }
      },
    });
		return () => {
			subscription?.current?.unsubscribe();
		};
	}, [artist]);


  return (
    <Text style={styleInput}>{voteCount} votes</Text>
  )
}

export default VoteCount
