import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'
import { store } from './store/storeProvider'

export const apiCall = async (queryFn, body, hasLoading = false) => {
  if (hasLoading) {
    store.dispatch({
      type: 'START_LOADING',
    })
  }
  const data = await API.graphql(graphqlOperation(queryFn, body))
  if (hasLoading) {
    store.dispatch({
      type: 'FINISH_LOADING',
    })
  }

  return data
}

export const updateUser = async (requestBody) => {
  const { data } = await apiCall(mutations.updateUser, requestBody, true)
  return data.updateUser
}

export const updateUserTicketNumber = async (requestBody) => {
  const { data } = await apiCall(mutations.updateUserTicketNumber, requestBody)
  return data.updateUserTicketNumber
}

export const updateUserIsNewUser = async (requestBody) => {
  const { data } = await apiCall(mutations.updateUserIsNewUser, requestBody)
  return data.updateUserIsNewUser
}

export const getUserById = async (userId) => {
  const { data } = await apiCall(queries.getUserById, { userId })
  return data.getUserById
}

export const getOnboardingQuestions = async (userId) => {
  const { data } = await apiCall(queries.getOnboardingQuestions, { userId })
  return data?.getOnboardingQuestions
}

export const updateOnboardingAnswers = async (requestBody) => {
  const { data } = apiCall(mutations.updateOnboardingAnswers, requestBody)
  return data?.updateOnboardingAnswers
}

export const getAllChallengesByUserId = async (userId) => {
  const years = ['2022', '2023']
  const result = [];

  for (const year of years) {
    const { data } = await apiCall(queries.getAllChallengesByUserId, { userId, year })
    result.push(...data?.getAllChallengesByUserId)
  }

  return result
}

export const updateChallengeStatusByQrCode = async (userId, qrCode) => {
  const { data } = await apiCall(
    mutations.updateChallengeStatusByQrCode,
    {
      userId,
      qrCode,
    },
    true,
  )
  return data?.updateChallengeStatusByQrCode
}

export const getAllEvents = async () => {
  const { data } = await apiCall(queries.getAllEvents)
  return data?.getAllEvents
}

export const getAllCouponsWon = async (userId) => {
  const { data } = await apiCall(queries.getAllCouponsWon, { userId })
  return data?.getAllCouponsWon
}

export const getOffers = async () => {
  const years = ['2022', '2023']
  const result = [];

  for (const year of years) {
    const { data } = await apiCall(queries.getOffers, { year })
    result.push(...data?.getOffers)
  }

  return result
}

export const updateChallengeStatusToCompletedById = async (requestBody) => {
  const years = ['2022', '2023']
  const result = [];

  for (const year of years) {
    const { data } = await apiCall(
      mutations.updateChallengeStatusToCompletedById,
      {
        ...requestBody,
        year
      },
      true,
    )
    result.push(data?.updateChallengeStatusToCompletedById)
  }

  return result
}

export const updateRedeemedPoints = async (requestBody) => {
  const { data } = await apiCall(
    mutations.updateRedeemedPoints,
    requestBody,
    true,
  )
  return data?.updateRedeemedPoints
}

export const getAllUserProducts = async (userId) => {
  const { data } = await apiCall(queries.getAllUserProducts, { userId })
  return data?.getAllUserProducts
}

export const createPurchasedProduct = async (requestBody) => {
  const { data } = await apiCall(mutations.createPurchasedProduct, requestBody)
  return data?.createPurchasedProduct
}

export const getAllNotificationsByUserId = async (userId) => {
  const { data } = await apiCall(queries.getAllNotificationsByUserId, {
    userId,
  })
  return data?.getAllNotificationsByUserId
}

export const updateNotificationStatusToRead = async (requestBody) => {
  const { data } = await apiCall(
    mutations.updateNotificationStatusToRead,
    requestBody,
    true,
  )
  return data?.updateNotificationStatusToRead
}

export const updateReferredByForUser = async (userId, referredBy) => {
  const { data } = await apiCall(mutations.updateReferredByForUser, {
    userId,
    referredBy,
  })
  return data
}

export const updateUserDeletionRequested = async (
  userDeletionRequested,
  userId,
) => {
  const { data } = await apiCall(mutations.updateUserDeletionRequested, {
    userDeletionRequested,
    userId,
  })
  return data?.updateUserDeletionRequested
}

export const updateUserArtistsPreference = async (requestBody) => {
  const { data } = await apiCall(
    mutations.updateUserArtistsPreference,
    requestBody,
  )
  return data?.updateUserArtistsPreference
}

export const getOffersCountByUserId = async (userId) => {
  const { data } = await apiCall(queries.getOffersCountByUserId, { userId })
  return data?.getOffersCountByUserId
}

export const getAllOffersCount = async () => {
  const { data } = await apiCall(queries.getAllOffersCount)
  return data?.getOffersCountByUserId
}
export const getImagesByFolderId = async (photoFolderCode) => {
  const { data } = await apiCall(
    queries.getImagesByFolderId,
    {
      photoFolderCode,
    },
    true,
  )
  return data?.getImagesByFolderId
}

export const getAdminTopTracks = async (userId) => {
  const { data } = await apiCall(queries.getAdminTopTracks, {
    userId,
  })
  return data?.getAdminTopTracks
}

export const updateIsSpotifyConnected = async (requestBody) => {
  const { data } = await apiCall(
    mutations.updateIsSpotifyConnected,
    requestBody,
  )
  return data.updateIsSpotifyConnected
}

export const getMatchedPercentage = async () => {
  const { data } = await apiCall(queries.getMatchedPercentage)
  return data?.getMatchedPercentage
}

export const deleteSpotifyUser = async (userId) => {
  const { data } = await apiCall(mutations.deleteSpotifyUser, { userId })
  return data.deleteSpotifyUser
}

export const getContestArtistProfiles = async () => {
  const { data } = await apiCall(queries.getContestArtistProfiles, {})
  return data?.getContestArtistProfiles
}

export const getVoteTracker = async (userId) => {
  const { data } = await apiCall(queries.getVoteTracker, { userId })
  return data?.getVoteTracker
}

export const createVoteTracker = async (userId) => {
  const { data } = await apiCall(mutations.createVoteTracker, { userId })
  return data?.createVoteTracker
}

export const updateVoteTracker = async (userId, latestVotedAt, latestVotedArtistId) => {
  const { data } = await apiCall(mutations.updateVoteTracker, { userId, latestVotedAt, latestVotedArtistId })
  return data?.updateVoteTracker
}

export const createVote = async (guid, artistId, voteTrackerId) => {
  const { data } = await apiCall(mutations.createVote, { guid: guid, voteTrackerId, artistId })
  return data?.createVote
}

export const getVotesByArtistId = async (artistId) => {
  const result = [];
  let flag = true
  let nextToken;

  do {
    const { data: resData } = await apiCall(queries.getVotesByArtistId, { artistId, limit: 100000, nextToken });

    const data = resData?.getVotesByArtistId

    if (data?.nextToken) {
      result.push(...data?.items)

      nextToken = data.nextToken
    } else {
      result.push(...data?.items)
      flag = false
    }

  } while(flag)

  return result
}

export const getVotesByDate = async (startDate, endDate) => {
  const { data } = await apiCall(queries.getVotesByDate, { startDate, endDate })
  return data?.getVotesByDate
}

export const getBlacklistEmail = async (emailDomain) => {
  const { data } = await apiCall(queries.getBlacklistEmail, { emailDomain })
  return data?.getBlacklistEmail
}
