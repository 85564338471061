import React from 'react'
import { Surface, Text, useTheme } from 'react-native-paper'
import { TouchableOpacity } from 'react-native'
import { Container } from '@nowon/container'
import { CouponCard } from '@nowon/card'
import { useIsFocused } from '@react-navigation/native'
import { useQuery } from '@tanstack/react-query'
import discountCoupon from '../../assets/discountCoupon.png'
import { getAllCouponsWon } from '../../apiGraphql'

export const YourCoupon = (props) => {
  const { colors } = useTheme()
  const { t, navigation, user } = props
  const isRendered = useIsFocused()
  const { data: availableCoupons } = useQuery(
    ['getAllCouponsWon'],
    async () => {
      const response = await getAllCouponsWon(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  return (
    <Container>
      <Surface elevation={0}>
        <Surface elevation={0} style={{ marginBottom: 12, gap: 12 }}>
          <Text variant='labelMedium' style={{ color: colors.textPrimary }}>
            <TouchableOpacity onPress={() => navigation.navigate('challenges')}>
              {t('CHALLENGES')}{' '}
            </TouchableOpacity>{' '}
            {'>'} {t('YOUR_COUPONS')}
          </Text>
          <Text variant='headlineLarge' style={{ color: colors.primary }}>
            {t('YOUR_COUPONS')}
          </Text>
          <Text variant='titleMedium' style={{ color: colors.textPrimary }}>
            {t('COUPONS_INFO')}
          </Text>
        </Surface>
      </Surface>
      <Surface elevation={0} style={{ gap: 12, paddingVertical: 20 }}>
        {availableCoupons?.map((item) => (
          <CouponCard
            {...item}
            image={discountCoupon}
            value={item?.discount}
            couponCode={item?.couponCode}
            sponsorURL={item?.sponsorURL}
            subHeading={t('REWARD_CLAIM')}
          />
        ))}
      </Surface>
    </Container>
  )
}
