import React from 'react'
import { Surface, Text } from 'react-native-paper'
import { View } from 'react-native'
import { Icon } from '@nowon/icons'

const NotificationCard = (props) => {
  const { item } = props

  const getIcon = (iconLabel) => {
    if (iconLabel === 'ACCEPT_CHALLENGE') {
      return 'FlagIcon'
    }
    return 'FlagIcon'
  }
  return (
    <Surface
      style={{
        borderRadius: 8,
        marginTop: 8,
        padding: 16,
        flexDirection: 'row',
      }}
    >
      {item?.status === 'UNREAD' && (
        <View
          style={{
            borderRadius: 50,
            backgroundColor: 'red',
            height: 8,
            width: 8,
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        />
      )}
      <View
        style={{
          backgroundColor: '#99B5D4',
          width: 40,
          height: 40,
          borderRadius: 40,
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 16,
        }}
      >
        <Icon
          name={getIcon(item?.notificationType)}
          color='#222222'
          height={20}
        />
      </View>
      <View style={{ flex: 1, flexWrap: 'wrap' }}>
        <Text
          style={{ paddingBottom: 5, color: '#CCCCCC' }}
          variant='titleMedium'
        >
          {item?.title}
        </Text>
        <Text style={{ color: '#CCCCCC' }} variant='labelMedium'>
          {item?.description}
        </Text>
      </View>
    </Surface>
  )
}

export default NotificationCard
