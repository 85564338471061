import moment from 'moment'
import { countBy } from 'lodash'
import { getBlacklistEmail } from '../apiGraphql'

export const isVoted = (latestVotedAt) => {
  return moment() < moment(latestVotedAt).endOf('day')
}

export const getNextVoteAt = () => {
  const nextVoteAt = new Date()

  // CET timezone
  nextVoteAt.setUTCHours(21, 59, 59, 59)

  return nextVoteAt
}

export const getBeginningOfDate = () => {
  const today = new Date()
  today.setUTCHours(0, 0, 0, 0)

  return today
}

export const getHighestVotedOfDay = (votes) => {
  const arr = votes.map((x) => x.artistId)
  const countByRes = countBy(arr)
  const values = Object.values(countByRes)
  const max = Math.max(...values)
  const maxIds = []

  for (const key in countByRes) {
    if (countByRes[key] === max) {
      maxIds.push(key)
    }
  }

  return { artistIds: maxIds, count: max }
}

export const secondsToHms = (d) => {
  d = Number(d)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var mDisplay = m < 10 ? '0' + m : m
  var sDisplay = s < 10 ? '0' + s : s
  return `${mDisplay}:${sDisplay}`
}

export const getContestDaysLeft = () => {
  const endDate = process.env.REACT_APP_CONTEST_END_DATE
  const date = new Date(endDate)
  const diffDays = moment(date).diff(moment(), 'days')

  return diffDays
}

export const detectTempEmail = async (email) => {
  const domain = email.split('@').pop()

  const blacklistEmail = await getBlacklistEmail(domain)

  if (!blacklistEmail) {
    return false;
  }

  return true;
}
