import { Text as TextElement, StyleSheet } from 'react-native'
import React from 'react'
import { useTheme } from 'react-native-paper'

const Text = (props) => {
  const {
    children,
    colorVariant = 'textPrimary',
    style = {},
    variant,
    color,
  } = props
  const { colors } = useTheme()

  return (
    <TextElement
      style={StyleSheet.flatten([
        colors.fonts[variant],
        { color: colors[colorVariant] || color },
        style,
      ])}
    >
      {children}
    </TextElement>
  )
}

export default Text
