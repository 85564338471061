import { useTranslation } from 'react-i18next'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Surface, Text, useTheme } from 'react-native-paper'
import TicketInputCard from './TicketInputCard'

const PreferenceSection = (props) => {
  const {
    question,
    selectedOption,
    handleSelectOption,
    handleTicketSubmit = () => {},
    visible,
    setVisible,
  } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <Text
        variant='headlineSmall'
        style={{
          color: colors.primary,
        }}
      >
        {question?.questionText}
      </Text>
      <Surface style={{ flexDirection: 'column' }} elevation={0}>
        <Surface
          elevation={0}
          style={{
            flexDirection: 'row',
            marginBottom: 23,
            flex: 1,
            gap: 15,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginTop: question?.questionType === 'USER-DETAILS' ? 0 : 75,
          }}
        >
          {question?.answerOptions?.map((item) => (
            <TouchableOpacity
              onPress={() => handleSelectOption(item)}
              key={item}
            >
              <Surface
                elevation={0}
                style={{
                  borderRadius: 8,
                  borderColor: colors.primary,
                  borderWidth: 1,
                  backgroundColor: selectedOption?.includes(item)
                    ? colors.primary
                    : 'transparent',
                  paddingLeft: 23,
                  paddingTop: 10,
                  height: 86,
                  width: 156,
                  flexDirection: 'row',
                }}
              >
                <Text
                  variant='titleLarge'
                  style={{
                    color: selectedOption?.includes(item)
                      ? colors.textPrimary
                      : '#6F8DB6',
                    marginBottom: 16,
                    width: 120,
                  }}
                >
                  {t(`ACTIONS.${item.toUpperCase()}`)}
                </Text>
              </Surface>
            </TouchableOpacity>
          ))}
        </Surface>
        {selectedOption?.includes(
          question?.additionalInfoTriggerAnswerOption,
        ) && (
          <TicketInputCard
            handleTicketSubmit={handleTicketSubmit}
            visible={visible}
            setVisible={setVisible}
          />
        )}
      </Surface>
    </View>
  )
}

export default PreferenceSection
