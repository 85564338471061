export const onCreateVote = /* GraphQL */ `
	subscription onCreateVote {
		onCreateVote {
      id
      voteTrackerId
      artistId
      votedAt
		}
	}
`;
