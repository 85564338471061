export default {
  LOGIN: 'Login',
  SIGN_IN_WITH: 'Sign In With',
  WELCOME: 'Nowon EN',
  ACTIONS: {
    CONTINUE: 'Continue',
    SIGN_UP: 'Sign up',
    BACK: 'Back',
    SCAN_QR: 'Scan QR code',
    CANCEL: 'Cancel',
    RETRIEVE: 'Retrieve',
    VIEW_MAP: 'View map',
    GET_STARTED: 'Get started',
    OK: 'Ok',
    REDEEM: 'Redeem',
    KNOW_MORE: ' Know more',
    BUY_TICKET: `Buy tickets`,
    UPDATE: 'Update',
    SKIP: 'Skip',
    NEXT: 'Next',
    FOLLOW: 'Follow',
    INVITE: 'Invite',
    VISIT_SPONSOR: 'Visit sponsor',
    YES: 'Yes',
    NO: 'No',
  },
  NAME: 'Name',
  EMAIL_ID: 'Email Address',
  PASSWORD: 'Password',
  DELIVERED: 'Delivered',
  TICKET_NUMBER: 'Ticket number',
  PRODUCT_COUNTER_NAME: 'Product name/Counter name',
  CONFIRMATION_CODE: 'Confirmation Code',
  WELCOME_ONBOARD: 'Welcome to the Rap City Family',
  CHALLENGES: 'Challenges',
  NEW_CHALLENGE: 'New challenge',
  RAPCITY_MEMORY: 'Rap City memories',
  RAPCITY_MEMBER: 'Rap City member',
  WELCOME_RAPCITY: 'Welcome to Rapcity',
  ABOUT_RAPCITY: 'About Rap City',
  FOLLOW_RAPCITY: 'Follow Rap City',
  COPY_RIGHT: 'Copyright @ Rapcity 2022',
  SKIP_FOR_NOW: 'Skip for now',
  EMAIL: 'Email',
  LOG_IN: 'Login',
  PROFILE: 'Profile',
  OFFERS_AVAILABLE: 'Offers available',
  BY_CLICKING: 'By clicking on sign-up, you agree to the ',
  TERMS_CONDITION: `Terms and Conditions`,
  USE: ` of Use.`,
  GET_BACK_SOON: 'We are on it! We will get back to you soon.',
  PRIVACY_POLICY: 'Privacy policy',
  MOBILE_NUMBER: 'Mobile number',
  DATE_OF_BIRTH: 'Date of birth',
  ALL_CHALLENGES: 'All challenges',
  OVER_ALL_PROGRESS: 'Overall progress',
  REWARD_POINTS: 'Punkte',
  SIGN_UP_WITH: 'Sign up for free and enjoy the full Rap City experience - ',
  READ_MORE: 'read more',
  COUPON_WON: 'Gutscheine',
  COUPON_CODE: 'Rabatt Code',
  REGISTER: 'Register',
  SUPPORT: 'Support',
  LOG_OUT: 'Log out',
  YOUR_POINT: 'Points',
  YOUR_POINTS: 'Your points',
  YOUR_COUPONS: 'Your coupons',
  COUPON_EXPIRING: 'Coupon expiring',
  COUPONS_INFO: 'View all your coupons info',
  THANK_YOU: 'Thank You!',
  ADD_LATER: 'Will add later',
  YOUR_TICKET: 'Your Rapcity Ticket',
  TICKET_IMAGE: 'Ticket image placeholder',
  ENTER_TICKET_NUMBER: 'Please enter your rapcity ticket number',
  RAPCITY_SHIRT: 'Rapcity T-shirt',
  REDEEM_RAPCITY:
    'Redeem your points and get a Rapcity T-shirt at counter name',
  CONCERT_TICKET: 'Concert ticket',
  FREE_TICKET: 'Get a free ticket for event name at place name',

  VERIFICATION_COMPLETER: `Verification complete!`,
  PASSWORD_VALIDATION: `password must contain atleast 1 Uppercase, 1 lowercase, 1 number and 1 special character`,
  PREFERENCE: `Complete Your preferences`,
  YOUR_PREFERENCES: 'Your preferences',
  PERFERENCES: 'Preferences',
  POINTS: `Get 100 points`,
  CHALLENGE_OVERVIEW: `Challenge overview`,
  EVENT_OVERVIEW: 'Event overview',
  TOTAL_CHALLENGES: `Total challenges`,
  ACHIEVED: `Achieved`,
  PENDING: `Pending`,
  TIME_TABLE: `Time table`,
  DOORS_DJ: `Doors + DJ set`,
  VIEW_EVENTS: `View events`,
  FESTIVAL_MAP: 'Festival Map',
  VIEW_FESTIVE: ` View festival map`,
  NOT_JOINED: `Not yet joined the Rap City event`,
  ALREADY_USER: 'Already have an account?',
  JOINED: `Joined already ?`,
  INVITE_FRIENDS: `Invite friends`,
  INVITATION_ACCEPTED: 'Invitation accepted',
  ACCEPTED_CHALLENGE: 'Delwar Hossain has accepted your challenge',
  INVITE_JOIN: `Get 100 coins and invite your friends to join the Rap City Community `,
  HELLO: `Hello,`,
  TO_THE_OFFERS: `To the offers`,
  VISIT_LIDL: 'Visit Finelli',
  VISIT_LIDL_CONTENT: 'Visit Finelli store near you and scan the QR code',
  PHOTO_CHALLENGE_COMPLETED: 'Get ready to capture your memories.',
  PHOTO_CHALLENGE_MESSAGE:
    'Share your registered email ID with rap City at the photo booth to get your pictures.',
  INVITE_YOUR_FRIENDS: 'Invite friends to this challenge',
  CHALLENGE_COMPLETED: 'Whoa! You have completed your challenge',
  GO_TO_CHALLENGE: 'Go to challenges',
  GO_TO_HOME: 'Go to Home',
  HOME: 'HOME',
  VISIT_METRO: 'Visit Metro',
  REDEEM: 'Are you sure you want to redeem your points for:',
  REDEEM_PRESS: 'Congratulations! your points are redeemed for',
  CONGRATS: 'Congratulations!',
  REWARD_CLAIM: 'rewards to claim',
  REDEEMED_POINTS: 'Redeemed points',
  REDEEM_POINTS: 'You can redeem your points for discounts and offers',
  REDEEM_POINT: 'Redeem Points',
  REWARD_POINT_INFO: 'View all your reward points info',
  REDEEM_CODE: 'Your Photos will be available soon',
  REDEEM_OFFERTICKET_RAFFLE: 'you will be contacted per mail after the raffle',
  REDEEM_OFFERTICKET_NORMAL: 'Redeemed',
  POINTS_EARNED: 'Total points earned',
  FORGOT_PASS: 'Forgot password',
  CHANGE_PASSWORD: 'Change password',
  RESET_PASSWORD: 'Reset password',
  PASSWORD_SENT: 'Password sent',
  EMAIL_INVALID: 'Email address is invalid!',
  PASSWORD_UPDATE: 'Password updated',
  PASSWORD_UPDATE_SUCCESSFULLY: 'Your password is updated successfully.',
  BACK_LOGIN: 'Back to login',
  INVITE_REWARD:
    'Inivite your friends to join the rapcity challenges and events and get rewarded',
  LOREM_IPSUM:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempori1oiw incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam.',
  LOREM_DOLAR: 'Lorem ipsum dolar',
  NEW_USER: 'New user?',
  SIGN_IN_GOOGLE: 'Sign in with Google',
  SIGN_UP_GOOGLE: 'Sign up with Google',
  CONTINUE_FACEBOOK: 'Continue with Facebook',
  WITH_EMAIL: 'Or with email',
  PASSWORD_INVALID: 'Password is invalid!',
  RESET_PASSWORD_LINK: 'We have sent you a reset password link to your email.',
  NOTIFICATION: 'Notifications',
  PHOTO_CHALLENGE: 'Photo Challenge',
  PHOTO_BOOTH:
    'Visit the photo booth at the Rapcity event. Scan the QR code near the booth and capture your memories',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  UPDATE_PROFILE: 'update profile',
  POINTS_AVAILABLE: 'Points available',
  POINTS_REQUIRED: 'Points required',
  AVAILABLE_POINTS: 'Available points',
  AVAILABLE_POINTS_EXPIRING: ' Available points expiring by 26 Nov 2022',
  FRIEND_INVITE:
    'Your friend has invited you for a <Rapcity/Rapcity challenge>. Sign in with Rapcity & check out your challenge',
  PHOTO_UPDATE:
    'Your Photos have been updated. Thanks for shooting a Photo at Rap City and do not forget to mark @rapcity in if you post the Photo to Instagram',
  GET_STARTED_BY: 'Points, where you tell us more about yourself.',
  GET: 'Get',
  COMPLETED_ONBOARDING:
    'You have earned 100 points Thanks for completing your onboarding journey. ',
  HELP_AND_SUPPORT: 'Help & support',
  INSUFFICIENT_CARD_TITLE: 'Insufficient points',
  INSUFFICIENT_CARD_CONTENT: `You don’t have sufficient points. Please complete pending challenges to earn more points`,
  VISIT_YOUR_REWARD_POINTS: ' View all your reward points info',
  TOTAL_EARNED_POINTS: 'Total points earned',
  ACCOUNT_SUCCESSFULLY:
    "We have sent you a confirmation email. Please check your profile via the link and log in to your account. Please also check the SPAM folder if you haven't received a confirmation in the next few minutes!",
  ACCOUNT_DELETION: 'Are you sure you want to delete his profile?',
  DELETE_ACCOUNT: `Delete Account`,
  ALMOST_DONE: `Almost done!`,
  Q_USER_NAME: 'How should we call you?',
  Q_D0B: 'What is your date of birth?',
  Q_GENDER: 'What is your gender?',
  MALE: 'Male',
  FEMALE: 'Female',
  VARIOUS: 'various',
  Q_NUMBER: 'What is you telephone number?',
  Q_ADDRESS: 'Where are you from?',
  STREET: 'Street',
  NR: ' Nr',
  ZIP: 'ZIP',
  CITY: 'City',
  LAND: 'Country',
  USER_NAME: 'Username',
  VISIT_RAPCITY: 'View your memories about the Rap City event',
  OFFER: 'off',
  CODE: 'code',
  AUTHORIZE_US: 'and authorize us to lorem ipsum',
  INTO_SLIDE_1_TITLE: 'Welcome to the Rap City Family! ',
  INTO_SLIDE_1_DESCRIPTION:
    'You have successfully registered for the Rap City Community Portal and now automatically participating in the raffle for the Rap City Lifetime Pass.',
  INTO_SLIDE_2_TITLE: 'Points & Rewards',
  INTO_SLIDE_2_DESCRIPTION:
    'Complete various challenges and receive points as well as discount codes. You can use your points to collect various rewards in the shop. The more points you have collected, the more lucrative the prizes',
  PHOTO_BOOTH_CHALLENGE_SUCCESS_MESSAGE: `Show the following Code at the photo booth before taking the photo`,
  ARTIST_NAME_1: 'Artist 1',
  ARTIST_NAME_2: 'Artist 2',
  ARTIST_NAME_3: 'Artist 3',
  CHALLENGE_ALREADY_COMPLETED: 'challenge already done ',
  CHALLENGE_TYPE: 'Challenge Name',
  GERMANY: 'Germany',
  SWITZERLAND: 'Switzerland',
  FRANCE: 'France',
  ITALY: 'Italy',
  ENGLAND: 'England (UK)',
  USA: 'USA',
  INPROGRESS: 'In process',
  PRIVACY_POLICY_ERROR: 'Please check the Terms and Conditions',
  INVITE_FRIEND_TO_CHALLENGE: 'Invitation for Rap City challenge: ',
  REDEEMED: 'Redeemed',
  RAFFLE:
    'You are attending the raffle. You will get an email if you have won.  ',
  NOT_A_RAFFLE: 'Report to the Rap City Corner to get your prize.',
  POP_UP: 'Du wirst per Mail kontaktiert, sobald das Ticketing online ist.',
  AWESOME: 'Awesome',
  RECEIVE: 'receive',
  NOT_ACHIEVED: 'Not achieved',
  SHARE_FEEDBACK: `Share feedback and win a gift voucher`,
  FEEDBACK_CONTENT: `Share your valuable feedback about the rapcity event and win a gift voucher lorem ipsum dolar`,
  VIEW: 'View',
  IMAGE_SIZE: 'Image size is large. please upload another image',
  TOP: 'Top',
  COMMUNITY_SONGS: 'Community Songs',
  COMMUNITY_DNA: 'Community Music DNA ',
  MATCH_SONGS: 'Matches Songs',
  MATCH_GENRE: 'Matches Genre',
  MATCH_ARTISTS: 'Matches Artist',
  OVERALL_DNA: 'Overall DNA Matches',
  DNA: 'DNA',
  DISCONNECT_SPOTIFY: 'Disconnect Spotify',
  SPOTIFY_DISCONNECTION: 'Do You want to disconnect spotify?',
  DNA_CARD: 'View Your DNA',
  DNA_CARD_SPOTIFY: 'Verbinde deinen Spotify account',

  // New version
  HOME: {
    VOTE_CONTEST_TITLE: 'Rap City Contest',
    GET_INTO_THE_CONTEST: 'Cast your daily vote!'
  },
  CONTEST: {
    ABOUT_CONTEST: 'About Rap City Contest',
    WELCOME: 'Welcome to the Rap City Contest! Cast your daily vote for your favorite artist and have a say in the outcome.',
    RULE_24H: 'You can vote once every 24 hours.',
    RULE_PER_DAY: 'You can only vote for one artist per day.',
    RULE_END: 'The voting ends on July 2nd at 12:00 PM.',
    RULE_FAKE: 'Fake votes using temporary emails or similar methods will not be counted.',
    RAPCITY_CONTEST: 'Rap City Contest',
    VOTING: 'Voting',
    VOTED_FOR: 'You voted for',
    NEXT_VOTING: 'Your next voting will be in {{time}} hrs.',
    GIVE_DAILY: 'Cast your daily vote!',
    HOURS: 'Hours',
    MINUTES: 'Minutes',
    SECONDS: 'Seconds',
    DAYS: 'Days',
    ENDIND_IN: 'Voting ends in',
    YOUR_ARTIST_OF_DAY: 'About the Contest Participants',
    OTHERS: '& {{number}} others',
    MOSTEST_VOTES: 'most voted artist of the day',
    CONTEST_INFO: 'Contest Information',
    KNOW_ALL: 'Know more about the Rap City Contest',
    VOTE_FOR: 'Vote for your artist',
    VOTE_FOR_FAV: 'Vote for your artist',
    YOU_CAN_VOTE: 'You can vote once in 24 hrs.',
    MORE_INFO: 'more info',
    OVER_TALENTS: "Over 150 talents applied for this year's Rap City Contest. XEN, Pablo, and ZZ Amparo form the jury. Who will conquer the contest? Have your say and make a decision!",
    SCHEDULE: 'Schedule',
    THE_24_RAPPERS_LINE_1: "The 24 rappers who impressed the Rap City team the most performed live in front of a jury at this year's Sneakerness event.",
    THE_24_RAPPERS_LINE_2: "Two individuals won a wildcard and secured a spot directly in the semifinals, which will take place on the Rap City Stage at Openair Frauenfeld. The other 23 artists entered the online voting phase. The four artists with the most votes will also advance to the semifinals.",
    THE_24_RAPPERS_LINE_3: "Three newcomers will be selected by the jury at Frauenfeld to compete in the final. The final showdown will take place on November 18th at the Rap City Season 05 in the Hallenstadion.",
    WIN: 'Win',
    DER_ORDER: "Der oder die Gewinnerin erhält eine zweitägige Reise in die Red-Bull-Studios Berlin inklusive Flug und Hotel, ein Booking an der Rap City Season 06 im Hallenstadion und kann eine EP mit Sony Music Schweiz veröffentlichen. Die letzten Gewinner hiessen xthedoc (Season 04), SIRODOGGYD (Season 03) und Rapide x Alawai (Season 02)",
    VOTE_POSTED: 'Your vote has been posted',
    WINNERS: 'Contest Winners'
  },
  VERIFY_EMAIL: 'Verify Mail',
  VERIRED_EMAIL: 'Your account has been successfully verified. Sign in with your account now.',
  INVALID_EMAIL: 'Temporary email addresses are not allowed for voting',
  TEMP_EMAIL_ERROR: 'Temp Accounts are not able to register',
}
