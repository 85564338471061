import * as React from 'react'
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg'

const SvgProfileIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <G clipPath='url(#ProfileIcon_svg__a)' fill={props.color}>
      <Path d='M14.976 12.539A6.09 6.09 0 1 0 13.024.516a6.09 6.09 0 0 0 1.952 12.023ZM.691 21.044a6.524 6.524 0 0 0 6.519 6.518h13.58a6.524 6.524 0 0 0 6.519-6.518 6.524 6.524 0 0 0-6.52-6.52H7.21a6.524 6.524 0 0 0-6.518 6.52Z' />
    </G>
    <Defs>
      <ClipPath id='ProfileIcon_svg__a'>
        <Path fill='#fff' d='M0 0h28v28H0z' />
      </ClipPath>
    </Defs>
  </Svg>
)

export default SvgProfileIcon
