import React, { useRef, useState, useEffect } from 'react'
import { Surface, Text, Avatar } from 'react-native-paper'
import {
  TouchableOpacity,
  View,
  Linking,
  StyleSheet,
  Image,
  ActivityIndicator,
} from 'react-native'
import { Container } from '@nowon/container'
import { useQuery } from '@tanstack/react-query'
import { Icon } from '@nowon/icons'
import { ProfileMenuCard } from '@nowon/card'
import { useIsFocused } from '@react-navigation/native'
import { DeleteAccountPopup, ProfileImagePopup } from '@nowon/patterns'
import { supportMail, privacyPolicyPage } from '../../config'
import {
  getUserById,
  updateUserDeletionRequested,
  getImagesByFolderId,
  deleteSpotifyUser,
} from '../../apiGraphql'
import ProfileImage from '../../assets/profileImage.png'
import editIcon from '../../assets/editIcon.png'
import { imageUpload } from '../../api'

export const Profile = (props) => {
  const { user, colors, t, navigation, setProfileImg } = props
  const imagePicker = useRef()
  const [attachment, setAttachment] = useState('')
  const [availablePoints, setAvailablePoints] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [popupTitle, setPopupTitle] = useState('')

  const isRendered = useIsFocused()
  const { data: userData, refetch: refetchGetUserById } = useQuery(
    ['getUserById'],
    async () => {
      const response = await getUserById(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
      initialData: [],
    },
  )

  const { data: imageArray } = useQuery(
    ['getImagesByFolderId'],
    async () => {
      const res = await getImagesByFolderId(user?.photoFolderCode)
      if (res) return res
    },
    {
      enabled: !!user && isRendered,
      initialData: [],
    },
  )

  useEffect(() => {
    if (!userData) return
    setAvailablePoints(
      Number(userData?.earnedPoints) - Number(userData?.redeemedPoints),
    )
  }, [userData, isRendered])
  useEffect(() => {
    const profileImageArr = imageArray?.filter(
      (e) => e.name === 'PROFILE-IMG.JPG',
    )

    if (profileImageArr?.length > 0)
      setAttachment(
        `${profileImageArr && profileImageArr[0]?.url}?random=${Math.random()
          .toString(36)
          .substring(7)}`,
      )
  }, [imageArray, isRendered])

  const getBase64 = (f) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(f)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  function openImagePicker() {
    imagePicker.current.click()
  }

  const handleFileSelect = async ({ target: { files } }) => {
    if (files[0].size > 4.5e6) {
      setVisible(!visible)
    } else {
      const formdata = new FormData()
      formdata.append('file', files[0], 'IMG.JPG')
      if (isLoading) return
      setIsLoading(true)
      const res = await imageUpload(userData?.photoFolderCode, formdata)
      setAttachment(res?.data?.link)
      setProfileImg(
        `${res?.data?.link}?random=${Math.random().toString(36).substring(7)}`,
      )
      setIsLoading(false)
      getBase64(files[0]).then((d) => setAttachment(d))
    }
  }

  const handleDeleteUser = async () => {
    const response = await updateUserDeletionRequested(true, user?.userId)
    if (response) {
      navigation.navigate('logout')
      setModalVisible(!modalVisible)
    }
  }

  const handleDisconnectSpotify = async () => {
    const resp = await deleteSpotifyUser(user?.userId)
    if (resp) {
      setModalVisible(!modalVisible)
      refetchGetUserById()
      navigation.navigate('home')
    }
  }

  const checkForPopup = () => {
    if (popupTitle === 'ACCOUNT_DELETION') {
      handleDeleteUser()
    }
    if (popupTitle === 'SPOTIFY_DISCONNECTION') {
      handleDisconnectSpotify()
    }
  }

  const minTwoDigits = (n) => (n < 10 ? '0' : '') + n

  return (
    <Container>
      <Text
        variant='headlineLarge'
        style={{
          color: colors.primary,
        }}
      >
        {t('PROFILE')}
      </Text>
      <Surface elevation={0} style={{ paddingBottom: 20, gap: 16 }}>
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <View style={{ position: 'relative' }}>
            {attachment !== '' ? (
              isLoading ? (
                <Surface
                  elevation={0}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: 45,
                  }}
                >
                  <ActivityIndicator size='small' />
                </Surface>
              ) : (
                <Avatar.Image
                  size={110}
                  source={attachment}
                  style={{ backgroundColor: '#333' }}
                />
              )
            ) : (
              <Avatar.Image size={110} source={ProfileImage} />
            )}
            <TouchableOpacity onPress={() => openImagePicker()}>
              <Image
                source={editIcon}
                style={{
                  height: 32,
                  width: 32,
                  position: 'absolute',
                  top: -30,
                  right: 2,
                }}
              />
            </TouchableOpacity>
            <input
              type='file'
              id='avatar'
              name='avatar'
              accept='image/png, image/jpeg'
              style={{
                display: 'none',
              }}
              ref={imagePicker}
              onChange={handleFileSelect}
            />
          </View>

          <Surface
            elevation={0}
            style={{ display: 'flex', alignItems: 'center', gap: 4 }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text
                variant='headlineSmall'
                style={{
                  color: colors.onTertiaryContainer,
                }}
              >
                {user?.userName || user?.firstName || t('RAPCITY_MEMBER')}
              </Text>
            </View>
            <Text
              variant='labelLarge'
              style={{
                color: colors.onTertiaryContainer,
              }}
            >
              {user?.email}
            </Text>
          </Surface>

          <Surface
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: 8,
              paddingVertical: 11,
              width: '100%',
              gap: 5,
            }}
          >
            <Text
              variant='labelMedium'
              style={{
                color: colors.onTertiaryContainer,
                opacity: 0.5,
              }}
            >
              {t('YOUR_TICKET')}
            </Text>
            <Text
              variant='titleMedium'
              style={{
                color: colors.textPrimary,
                fontSize: 24,
                lineHeight: 28,
                fontFamily: 'Adopekid-Kanit-RC-Stencil',
              }}
            >
              {user?.ticketNumber || '-'}
            </Text>
          </Surface>
        </View>
        <Surface
          elevation={0}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 20,
          }}
        >
          <TouchableOpacity
            style={styles.pointsCard}
            onPress={() => navigation.navigate('your-points')}
          >
            <View>
              <Icon
                name='StarIcon'
                width={24}
                height={24}
                color={colors.textPrimary}
              />
            </View>
            <View style={{}}>
              <Text
                variant='headlineSmall'
                style={{
                  color: colors.onTertiaryContainer,
                }}
              >
                {availablePoints || '00'}
              </Text>
              <Text
                variant='labelMedium'
                style={{
                  color: colors.onTertiaryContainer,
                  opacity: 0.5,
                }}
              >
                {t('REWARD_POINTS')}
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.pointsCard}
            onPress={() => navigation.navigate('your-coupons')}
          >
            <View>
              <Icon
                name='CouponsWon'
                width={24}
                height={24}
                color={colors.textPrimary}
              />
            </View>
            <View style={{}}>
              <Text
                variant='headlineSmall'
                style={{ color: colors.onTertiaryContainer }}
              >
                {(!!userData?.couponsWon &&
                  minTwoDigits(userData?.couponsWon)) ||
                  '00'}
              </Text>
              <Text
                variant='labelMedium'
                style={{
                  color: colors.onTertiaryContainer,
                  opacity: 0.5,
                }}
              >
                {t('COUPON_WON')}
              </Text>
            </View>
          </TouchableOpacity>
        </Surface>
        <Surface style={{ gap: 4 }} elevation={0}>
          <TouchableOpacity
            onPress={() => navigation.navigate('updateProfile')}
          >
            <ProfileMenuCard
              title={t('UPDATE_PROFILE')}
              iconName='ProfileIcon'
              hasTopBorder
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => Linking.openURL(privacyPolicyPage)}>
            <ProfileMenuCard
              title={t('PRIVACY_POLICY')}
              iconName='ShieldIcon'
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL(`mailto:${supportMail}`)}
          >
            <ProfileMenuCard
              title={t('HELP_AND_SUPPORT')}
              iconName='HelpIcon'
            />
          </TouchableOpacity>
          {userData?.isSpotifyConnected && (
            <TouchableOpacity
              onPress={() => {
                setPopupTitle('SPOTIFY_DISCONNECTION')
                setModalVisible(true)
              }}
            >
              <ProfileMenuCard
                title={t('DISCONNECT_SPOTIFY')}
                iconName='SpotifyIcon'
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              setPopupTitle('ACCOUNT_DELETION')
              setModalVisible(true)
            }}
          >
            <ProfileMenuCard
              title={t('DELETE_ACCOUNT')}
              iconName='DeleteUserIcon'
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('logout')}>
            <ProfileMenuCard title={t('LOG_OUT')} iconName='LogOutIcon' />
          </TouchableOpacity>
        </Surface>
      </Surface>
      <ProfileImagePopup
        modalVisible={visible}
        setModalVisible={setVisible}
        colors={colors}
        t={t}
      />
      <DeleteAccountPopup
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        colors={colors}
        t={t}
        title={popupTitle}
        handleDeleteUser={checkForPopup}
      />
    </Container>
  )
}
const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    paddingHorizontal: 19,
    paddingVertical: 14,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightImageStyle: {
    marginRight: 30,
    height: 20,
    width: 20,
  },
  rightIconBackground: {
    borderRadius: 50,
    paddingVertical: 5,
    paddingHorizontal: 7,
  },
  pointsCard: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: 'rgb(37, 35, 42)',
    flex: 1,
    gap: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
})
