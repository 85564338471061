import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgFlagIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 17 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M15.49 7.198c-1.47.265-3.865.337-5.456-2.97C8.328.677 5.571.569 3.96.892c-.784.157-1.37.876-1.37 1.572v8.832c.463.193.96-.009 1.087-.036.032-.009.061-.013.098-.022 1.025-.247 2.108-.364 4.79 1.046 3.364 1.766 6.309-1.486 7.45-3.75.081-.158.356-.908.356-1.627-.398.18-.882.292-.882.292ZM1.61.438H.952c-.18 0-.328.161-.328.359v19.406c0 .198.148.36.328.36h.656c.18 0 .329-.162.329-.36V.797c0-.198-.148-.36-.329-.36Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgFlagIcon
