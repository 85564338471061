const initialState = {
  loading: 0,
  totalRequest: 0,
  isLoading: false,
}

export const getLoadStatus = (state = initialState) => state.loading
export const getTotalRequest = (state = initialState) => state.totalRequest

// eslint-disable-next-line default-param-last
export const loading = (state = initialState, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, totalRequest: state.totalRequest + 1, isLoading: true }
    case 'FINISH_LOADING':
      return { ...state, loading: state.loading + 1, isLoading: false }
    default:
      return state
  }
}

export const namespaced = true
export default loading
