import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Container } from '@nowon/container'
import { Surface } from 'react-native-paper'
import { useIsFocused } from '@react-navigation/native'
import ComingSoon from '../../components/ComingSoon/ComingSoon'
import MemoriesPage from '../../components/MemoriesPage/MemoriesPage'
import { getUserById, getImagesByFolderId } from '../../apiGraphql'

export const EventMemories = (props) => {
  const { colors, t, navigation, user } = props
  const isRendered = useIsFocused()
  const { data: userData } = useQuery(
    ['getUserById'],
    async () => {
      const response = await getUserById(user?.userId)
      if (response) return response
    },
    {
      enabled: !!user && isRendered,
    },
    {
      initialData: [],
    },
  )
  const { data: imageArray, isLoading } = useQuery(
    ['getImagesByFolderId'],
    async () => {
      const response = await getImagesByFolderId(userData?.photoFolderCode)
      console.log(response)
      if (response)
        return response?.filter((item) => item.name !== 'PROFILE-IMG.JPG')
    },
    {
      enabled: !!userData && isRendered,
    },
    {
      initialData: [],
    },
  )
  console.log(imageArray?.length)

  if (isLoading) {
    return <Container />
  }
  return (
    <Container>
      <Surface elevation={0} style={{ paddingBottom: 27 }}>
        {imageArray?.length > 0 ? (
          <MemoriesPage imageArray={imageArray} colors={colors} t={t} />
        ) : (
          <ComingSoon
            colors={colors}
            t={t}
            navigation={navigation}
            user={user}
          />
        )}
      </Surface>
    </Container>
  )
}
