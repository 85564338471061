import React from 'react'
import { Surface, Text } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

const PointCard = (props) => {
  const { value, heading, offerType, indicator } = props
  const { t } = useTranslation()
  return (
    <Surface
      style={{
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        flexDirection: 'column',
        borderRadius: 4,
        paddingHorizontal: 10,
        paddingVertical: 10,
        gap: 2,
      }}
    >
      <Text
        variant='titleSmall'
        style={{
          color: '#CCCCCC',
        }}
      >
        {heading}
      </Text>
      {value > 0 && (
        <Text
          variant='titleSmall'
          style={{
            color: '#EBACA9',
            gap: 6,
          }}
        >
          {value}
        </Text>
      )}
      <Surface
        elevation={0}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text
          variant='labelSmall'
          style={{
            color: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          {offerType === 'NON_RAFFLE'
            ? `${t('REDEEM_OFFERTICKET_NORMAL')}`
            : `${t('REDEEM_OFFERTICKET_RAFFLE')}`}
        </Text>
        {offerType === 'RAFFLE' && (
          <Surface
            elevation={0}
            style={{
              width: 100,
              height: 30,
              borderRadius: 4,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#222222',
            }}
          >
            <Text
              variant='labelSmall'
              style={{
                color: indicator === 'Delivered' ? '#6EE4E4' : '#E4B56E',
              }}
            >
              {indicator !== 'Delivered' ? t('INPROGRESS') : t('DELIVERED')}
            </Text>
          </Surface>
        )}
      </Surface>
    </Surface>
  )
}

export default PointCard
