import React, { useMemo } from 'react'
import {
  View,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
} from 'react-native'
import { Text } from '@nowon/components'
import ContestBackgroundImage from '../../assets/Contest-mainBackground.png'
import { useNavigation } from '@react-navigation/native'
import { useTimer } from 'react-timer-hook'
import { getNextVoteAt } from '../../utils/contestHelper'
import { Avatar } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  imageBackground: { height: 127, width: '100%' },
  votingText: {
    fontSize: 20,
    fontFamily: 'NunitoSans-Regular',
    marginBottom: 5,
  },
  giveYourVoteText: {
    fontSize: 24,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  nextVoteNoti: {
    fontFamily: 'Lexend Deca',
    fontSize: 15,
    color: '#CCCCCC',
  },
})

const VotedCard = (props) => {
  const { voteTracker, votedArtist } = props
  const navigation = useNavigation()
  const { t } = useTranslation()

  const expiryTimestamp = useMemo(() => {
    return getNextVoteAt(voteTracker?.latestVotedAt)
  }, [voteTracker])

  const { hours } = useTimer({
    autoStart: true,
    expiryTimestamp: expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  })

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('contestArtists')
      }}
    >
      <ImageBackground
        source={ContestBackgroundImage}
        style={styles.imageBackground}
        imageStyle={{ borderRadius: 24 }}
        resizeMode='cover'
      >
        <View
          style={{
            paddingHorizontal: 24,
            paddingVertical: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <View style={{ marginBottom: 12 }}>
            <Text style={styles.votingText}>{ t('CONTEST.VOTED_FOR') }</Text>

            <Text style={styles.giveYourVoteText}>{votedArtist.name}</Text>

            <Avatar.Image
              size={40}
              source={{ uri: votedArtist.avatarUrl }}
              style={{ backgroundColor: '#333', position: 'absolute', right: 0 }}
            />
          </View>
          <View>
            <Text style={styles.nextVoteNoti}>
              { t('CONTEST.NEXT_VOTING', { time: hours }) }
            </Text>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  )
}

export default VotedCard
