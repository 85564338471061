/* eslint-disable no-nested-ternary */
import React from 'react'
import { BackgroundContainer } from '@nowon/container'
import { PasswordVerificationCard } from '../../components/auth/PasswordVerification'
import { EmailVerificationCard } from '../../components/auth/EmailVerificationCard'
import { PasswordSuccessfulCard } from '../../components/auth/PasswordSuccesssfulCard'

export const Verification = ({ route, navigation }) => (
  <BackgroundContainer backgroundColor='#6EC1E4'>
    {route?.params?.VerificationType === 'Email' ? (
      <EmailVerificationCard navigation={navigation} />
    ) : route?.params?.VerificationType === 'PasswordUpdated' ? (
      <PasswordSuccessfulCard navigation={navigation} />
    ) : (
      <PasswordVerificationCard navigation={navigation} />
    )}
  </BackgroundContainer>
)
