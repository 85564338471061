import axios from 'axios'
import { apiUrl } from './config'
import { getItemAsync, setItemAsync } from './utils/SecureStore'

const extractResponseHeaders = (response) => {
  if (response.headers?.map) return response.headers?.map
  const headers = {}
  response.headers.forEach((val, key) => {
    headers[key] = val
  })
  return headers
}

const getAuthorizationHeader = async () => {
  const token = await getItemAsync('ACCESS_TOKEN')
  return {
    Authorization: `Bearer ${token}`,
  }
}

const apiCall = async (path, options, retry = true) => {
  try {
    const response = await fetch(
      `${!path.startsWith('http') ? `${apiUrl}/` : ''}${path}`,
      options,
    )
      .then(async (res) => {
        const responseStatus = res.status
        if (responseStatus === 401 && retry) {
          return apiCall(
            `${!path.startsWith('http') ? `${apiUrl}/` : ''}${path}`,
            options,
            false,
          )
        }

        let data = null
        try {
          data = await res.json()
        } catch (err) {
          return {
            responseStatus: res.status,
            responseHeaders: extractResponseHeaders(res),
          }
        }
        return {
          data,
          responseStatus: res.status,
          responseHeaders: extractResponseHeaders(res),
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return response
  } catch (err) {
    console.log('Fetch error: ', err)
    return err
  }
}

export const imageUpload = async (folderId, requestBody) =>
  apiCall(`${apiUrl}/upload?folderid=${folderId}`, {
    method: 'POST',
    body: requestBody,
    redirect: 'follow',
  })

export const getSpotifyUser = async () => {
  const response = apiCall(`https://api.spotify.com/v1/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeader()),
    },
  })
  return response
}

// eslint-disable-next-line camelcase
export async function getUserPlaylistTracks(playlist_id) {
  const response = await fetch(
    `https://api.spotify.com/v1/playlists/${playlist_id}/tracks`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(await getAuthorizationHeader()),
      },
    },
  )
  return response.json()
}

export async function spotifyLogin(userId, code) {
  const res = axios
    .post(`${apiUrl}/spotify/login`, { userId: userId, code: code })
    .then(async (response) => {
      await setItemAsync('ACCESS_TOKEN', response.data.body.access_token)
      await setItemAsync('REFRESH_TOKEN', response.data.body.refresh_token)
      await setItemAsync('EXPIRE_IN', response.data.body.expire_in)
      return response.data.statusCode
    })
    .catch((err) => err)
  return res
}

export const getBlacklistEmailLambdaApi = async (emailDomain) => {
  try {
    const res = await apiCall(`${apiUrl}/blacklist-email?emailDomain=${emailDomain}`);

    return res.data
  } catch (error) {
    console.error('getBlacklistEmailLambdaApi error: ', error)
  }
}
