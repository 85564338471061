import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgShieldIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M9 19.85a2 2 0 0 1-1-.25l-.3-.17A15.17 15.17 0 0 1 0 6.23v-.14a2 2 0 0 1 1-1.75L8 .4a2 2 0 0 1 2 0l7 3.94a1.999 1.999 0 0 1 1 1.75v.14a15.17 15.17 0 0 1-7.72 13.2l-.3.17a2 2 0 0 1-.98.25Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgShieldIcon
