import React from 'react'
import { View } from 'react-native'
import { Button, Surface, Text } from 'react-native-paper'
import { BackgroundContainer } from '@nowon/container'
import { Icon } from '@nowon/icons'

export const Splash = (props) => {
  const { colors, t, navigation } = props

  return (
    <BackgroundContainer backgroundColor={colors.primary}>
      <View
        style={{
          gap: '20px',
          backgroundColor: colors.primary,
          justifyContent: 'center',
          height: '100%',
          paddingVertical: '25px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              width: 96,
              height: 96,
              borderRadius: 50,
              borderWidth: 1,
              borderColor: colors.textPrimary,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              name='TickIcon'
              width={54}
              height={33}
              color={colors.textPrimary}
            />
          </View>
        </View>
        <Text variant='headlineLarge' style={{ color: colors.textPrimary }}>
          {t('VERIFY_EMAIL')}
        </Text>
        <Text
          variant='titleMedium'
          style={{
            color: colors.textPrimary,
            width: '92%',
          }}
        >
          {t('ACCOUNT_SUCCESSFULLY')}
        </Text>
      </View>
    </BackgroundContainer>
  )
}
