import { StyleSheet, View } from 'react-native'
import React from 'react'
import { Surface, Text, useTheme } from 'react-native-paper'
import { Icon } from '@nowon/icons'

const ProfileMenuCard = (props) => {
  const { title, iconName, hasTopBorder } = props
  const { colors } = useTheme()

  return (
    <Surface
      style={[
        {
          borderTopRightRadius: hasTopBorder ? 8 : 0,
          borderTopLeftRadius: hasTopBorder ? 8 : 0,
        },
        styles.rowContainer,
      ]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Icon name={iconName} color={colors.textPrimary} width={20} />
        <Text
          style={{
            color: colors.onTertiaryContainer,
            marginLeft: 28,
          }}
        >
          {title}
        </Text>
      </View>

      <Surface style={styles.rightIconBackground} elevation={0}>
        <Icon name='ChevronRightIcon' height={11} color='#6EC1E4' />
      </Surface>
    </Surface>
  )
}

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    paddingHorizontal: 19,
    paddingVertical: 14,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightIconBackground: {
    borderRadius: 50,
    width: 21,
    height: 21,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(55, 59, 77, 1)',
  },
})

export default ProfileMenuCard
