import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgChevronRightIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M1.707 11.707 7.414 6 1.707.293.293 1.707 4.586 6 .293 10.293l1.414 1.414Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgChevronRightIcon
