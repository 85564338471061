const initialState = {
  voteTracker: false,
  selectedArtist: null,
  votedArtist: null,
  artistProfiles: [],
  isEnded: false
}

// eslint-disable-next-line default-param-last
export const contestReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VOTE_TRACKER':
      return { ...state, voteTracker: action.payload }
    case 'SET_SELECTED_ARTIST':
      return { ...state, selectedArtist: action.payload }
    case 'SET_VOTED_ARTIST':
      return { ...state, votedArtist: action.payload }
    case 'SET_ARTIST_PROFILES':
      return { ...state, artistProfiles: action.payload }
    case 'SET_IS_ENDED':
      return { ...state, isEnded: action.payload }
    default:
      return state
  }
}

export const namespaced = true
export default contestReducer
