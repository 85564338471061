import { View, Image } from 'react-native'
import React from 'react'
import { useTheme, Surface } from 'react-native-paper'
import { RWebShare } from 'react-web-share'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import Logo from '../../assets/logo.png'
import { appUrl } from '../../config'

const InviteCard = ({ user, inviteChallenge }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <RWebShare
      data={{
        text: 'Join the Rap City Family!',
        url: `${appUrl}/register?referralCode=${user?.referralCode}`,
        title: 'Invite Friends',
      }}
      onClick={() => {}}
    >
      <View>
        <Surface
          style={{
            width: '100%',
            borderRadius: 24,
            paddingLeft: 28,
            paddingRight: 22,
            paddingVertical: 22,
            backgroundColor: colors.backgroundSurface,
          }}
        >
          <Image
            source={Logo}
            style={{
              width: 200,
              height: 30,
              alignSelf: 'center',
            }}
            resizeMode='contain'
          />
          <Text
            variant='brandBodyLarge'
            colorVariant='textPrimary'
            style={[
              {
                marginTop: 22,
              },
            ]}
          >
            {t('INVITE_FRIENDS')}
          </Text>
          <Text variant='compactMedium' colorVariant='textPrimary'>
            {t('INVITE_JOIN')}{' '}
          </Text>
          <View
            style={{
              backgroundColor: '#fff',
              height: 32,
              width: 118,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 24,
              marginTop: 14,
            }}
          >
            <Text variant='labelLarge' style={{ color: colors.primary }}>
              {t('ACTIONS.INVITE')}
            </Text>
          </View>
        </Surface>
      </View>
    </RWebShare>
  )
}

export default InviteCard
