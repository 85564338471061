import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgCameraIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 40 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M33.5 41.75H31a.75.75 0 0 1 0-1.5h2.5a4.505 4.505 0 0 0 4.5-4.5V9.5A4.505 4.505 0 0 0 33.5 5h-27A4.505 4.505 0 0 0 2 9.5v26.25a4.505 4.505 0 0 0 4.5 4.5H9a.75.75 0 1 1 0 1.5H6.5a6.007 6.007 0 0 1-6-6V9.5a6.007 6.007 0 0 1 6-6h27a6.007 6.007 0 0 1 6 6v26.25a6.007 6.007 0 0 1-6 6Z'
      fill={props.color}
    />
    <Path
      d='M33.5 37.25h-3a.75.75 0 1 1 0-1.5h3a.75.75 0 0 0 .75-.75v-2.25a.75.75 0 0 0-.75-.75h-27a.75.75 0 0 0-.75.75V35a.75.75 0 0 0 .75.75h3a.75.75 0 1 1 0 1.5h-3A2.25 2.25 0 0 1 4.25 35v-2.25A2.25 2.25 0 0 1 6.5 30.5h27a2.25 2.25 0 0 1 2.25 2.25V35a2.25 2.25 0 0 1-2.25 2.25ZM12.5 5H7.25a.75.75 0 0 1-.75-.75v-1.5A2.25 2.25 0 0 1 8.75.5H11a2.25 2.25 0 0 1 2.25 2.25v1.5a.75.75 0 0 1-.75.75ZM8 3.5h3.75v-.75A.75.75 0 0 0 11 2H8.75a.75.75 0 0 0-.75.75v.75Zm12 24a9.75 9.75 0 1 1 9.75-9.75A9.762 9.762 0 0 1 20 27.5Zm0-18a8.25 8.25 0 1 0 8.25 8.25A8.26 8.26 0 0 0 20 9.5Z'
      fill={props.color}
    />
    <Path
      d='M20 24.5a6.75 6.75 0 1 1 6.75-6.75A6.758 6.758 0 0 1 20 24.5Zm0-12A5.25 5.25 0 1 0 20 23a5.25 5.25 0 0 0 0-10.5Z'
      fill={props.color}
    />
    <Path
      d='M20 21.25a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Zm0-5.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-9 2.75H1.25a.75.75 0 1 1 0-1.5H11a.75.75 0 1 1 0 1.5Zm27.75 0H29a.75.75 0 1 1 0-1.5h9.75a.75.75 0 1 1 0 1.5Zm-11.25 27h-15a3.755 3.755 0 0 1-3.75-3.75v-10.5a.75.75 0 0 1 .75-.75h21a.75.75 0 0 1 .75.75v10.5a3.754 3.754 0 0 1-3.75 3.75ZM10.25 32v9.75A2.25 2.25 0 0 0 12.5 44h15a2.25 2.25 0 0 0 2.25-2.25V32h-19.5Z'
      fill={props.color}
    />
    <Path
      d='M24.5 42.5h-9a3.754 3.754 0 0 1-3.75-3.75v-7.5a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 .75.75v7.5a3.754 3.754 0 0 1-3.75 3.75ZM13.25 32v6.75A2.25 2.25 0 0 0 15.5 41h9a2.25 2.25 0 0 0 2.25-2.25V32h-13.5ZM33.5 12.5h-3a2.25 2.25 0 0 1-2.25-2.25v-1.5A2.25 2.25 0 0 1 30.5 6.5h3a2.25 2.25 0 0 1 2.25 2.25v1.5a2.25 2.25 0 0 1-2.25 2.25Zm-3-4.5a.75.75 0 0 0-.75.75v1.5a.75.75 0 0 0 .75.75h3a.75.75 0 0 0 .75-.75v-1.5A.75.75 0 0 0 33.5 8h-3ZM5.75 28.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-3a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm28.5 3a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-3a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgCameraIcon
