import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgMusicCordIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M13.5 2v8.75a2.254 2.254 0 1 1-1-1.87V5.14l-7 1.75v5.86a2.254 2.254 0 1 1-1-1.87V4a.5.5 0 0 1 .378-.485l8-2a.494.494 0 0 1 .57.266A.5.5 0 0 1 13.5 2Z'
      fill='#8F14AE'
    />
  </Svg>
)

export default SvgMusicCordIcon
