import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { Text } from '@nowon/components'
import { Container } from '@nowon/container'
import { Surface, Card, Snackbar } from 'react-native-paper'
import { Icon } from '@nowon/icons'
import { useNavigation } from '@react-navigation/native'
import { store } from '../../store/storeProvider'
import VoteCount from '../../components/Contest/VoteCount'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { detectTempEmail, getNextVoteAt, isVoted } from '../../utils/contestHelper'
import { createVote, updateVoteTracker } from '../../apiGraphql'
import { v4 as uuidv4 } from 'uuid'
import { useTimer } from 'react-timer-hook'

const styles = StyleSheet.create({
  breadcumView: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#CCCCCC',
  },
  subBreadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#6EC1E4',
  },
  pageTitle: {
    fontFamily: 'Lexend Deca',
    fontSize: 32,
    color: '#6EC1E4',
    paddingRight: 30,
  },
  pageDesc: {
    fontFamily: 'Lexend Deca',
    fontSize: 14,
    paddingRight: 80,
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 12,
  },
  cardVoted: {
    backgroundColor: 'rgba(255, 76, 119, 0.15)',
    borderRadius: 12,
  },
  cardUnVoted: {
    backgroundColor: 'rgba(255, 255, 255)',
    opacity: 0.5,
    borderRadius: 12,
  },
  voteCount: {
    fontSize: 15,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  votedCount: {
    color: 'rgba(255, 76, 119, 1)',
    fontSize: 15,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  cardContentMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContentAvatar: {
    marginRight: 16,
  },
  cardContentAvatarImage: {
    height: 90,
    width: 80,
    borderRadius: 4,
  },
  cardContentArtistInfo: {
    alignSelf: 'flex-start',
    display: 'flex',
    height: '100%',
  },
  cardContentArtistName: {
    fontSize: 24,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  cardContentArtistNameWildcard: {
    fontSize: 24,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    color: '#F1AD33',
  },
  cardContentArtistDescription: {
    fontSize: 12,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  voteIcon: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 40,
    height: 40,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  wildcard: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    color: '#F1AD33',
  },
})

export const ContestArtists = (props) => {
  const { user } = props
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { artistProfiles, votedArtist, voteTracker, isEnded } = useSelector(
    (state) => state?.contestReducer,
  )
  const [snakbarVisible, setSnakbarVisible] = useState(false)
  const [alertSnakbarVisible, setAlertSnakbarVisible] = useState(false)
  const [isDisabledVote, setIsDisabledVote] = useState(false)

  const onSelectArtist = (artist) => {
    store.dispatch({
      type: 'SET_SELECTED_ARTIST',
      payload: artist,
    })
  }

  const { hours } = useTimer({
    autoStart: true,
    expiryTimestamp: getNextVoteAt(),
    onExpire: () => console.warn('onExpire called'),
  })

  useEffect(() => {
    if (isEnded) {
      navigation.navigate('contestWinners')
    }

  }, [isEnded])

  useEffect(() => {
    if (votedArtist) {
      setIsDisabledVote(true)
    } else {
      setIsDisabledVote(false)
    }
  }, [votedArtist])

  const onVote = async (_selectedArtist) => {
    setIsDisabledVote(true)
    const isBlacklistEmail = await detectTempEmail(user.email)

    if (isBlacklistEmail) {
      setAlertSnakbarVisible(true)

      setTimeout(() => {
        setAlertSnakbarVisible(false)
      }, 5000)

      return
    }

    if (
      !_selectedArtist.isWildcard &&
      (!voteTracker.latestVotedAt ||
        (voteTracker && !isVoted(voteTracker.latestVotedAt)))
    ) {
      const vote = await createVote(
        uuidv4(),
        _selectedArtist.guid,
        voteTracker.id,
      )

      setSnakbarVisible(true)

      setTimeout(() => {
        setSnakbarVisible(false)
      }, 10000)

      if (vote) {
        store.dispatch({
          type: 'SET_VOTED_ARTIST',
          payload: _selectedArtist,
        })

        const res = await updateVoteTracker(
          user?.userId,
          new Date(),
          _selectedArtist.guid,
        )

        store.dispatch({
          type: 'SET_VOTE_TRACKER',
          payload: res,
        })
      }

      setIsDisabledVote(false)
    }
  }

  return (
    <Container>
      <Surface elevation={0} style={{ gap: '22px', paddingBottom: 27 }}>
        <View style={styles.breadcumView}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('contest')
            }}
          >
            <Text style={styles.breadcum}>
              {t('CONTEST.RAPCITY_CONTEST')} &gt;
            </Text>
          </TouchableOpacity>

          <Text style={styles.subBreadcum}> Artist</Text>
        </View>
        <Text style={styles.pageTitle}>{t('CONTEST.VOTE_FOR_FAV')}</Text>
        <Text style={styles.pageDesc}>
          {t('CONTEST.YOU_CAN_VOTE')}{' '}
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('contestInfo')
            }}
            style={{ color: '#6EC1E4' }}
          >
            <Text style={styles.pageDesc}>{t('CONTEST.MORE_INFO')}</Text>
          </TouchableOpacity>
        </Text>

        <View style={{ position: 'absolute', top: 80 }}>
          <Snackbar visible={alertSnakbarVisible}>
            <Card
              style={{
                backgroundColor: 'rgba(0, 0, 0, 1)',
                marginBottom: 8,
                width: 400
              }}
            >
              <Card.Title
                title={t('INVALID_EMAIL')}
                style={{
                  fontSize: 15,
                  fontFamily: 'Lexend Deca'
                }}
              />
            </Card>
          </Snackbar>

          <Snackbar visible={snakbarVisible}>
            <Card
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                marginBottom: 8,
              }}
            >
              <Card.Title
                title={t('CONTEST.VOTE_POSTED')}
                style={{
                  fontSize: 15,
                  fontFamily: 'Lexend Deca',
                  color: '#FFFFFF',
                }}
                subtitle={t('CONTEST.NEXT_VOTING', { time: hours })}
                subtitleStyle={{
                  fontSize: 12,
                  fontFamily: 'Lexend Deca',
                  color: '#CCCCCC',
                }}
                left={() => (
                  <Icon
                    name='ThumbsUpIcon'
                    height={24}
                    width={24}
                    color='#EDA600'
                  />
                )}
              />
            </Card>
          </Snackbar>
        </View>

        {artistProfiles &&
          artistProfiles.map((artist) => (
            <TouchableOpacity
              onPress={() => {
                onSelectArtist(artist)
                navigation.navigate('contestArtistDetails')
              }}
            >
              <Card
                style={
                  !!votedArtist
                    ? votedArtist.guid === artist.guid
                      ? styles.cardVoted
                      : styles.cardUnVoted
                    : styles.card
                }
              >
                <Card.Content>
                  <View style={styles.cardContentMain}>
                    <View style={styles.cardContentAvatar}>
                      <Image
                        style={styles.cardContentAvatarImage}
                        source={{ uri: artist.avatarUrl }}
                      />
                    </View>

                    <View style={styles.cardContentArtistInfo}>
                      <View style={{ flexGrow: 1 }}>
                        <Text
                          style={
                            artist.isWildcard
                              ? styles.cardContentArtistNameWildcard
                              : styles.cardContentArtistName
                          }
                        >
                          {artist.name}
                        </Text>
                        <Text style={styles.cardContentArtistDescription}>
                          {artist.albumName}
                        </Text>
                      </View>

                      {!artist.isWildcard && (
                        <View>
                          <VoteCount
                            artist={artist}
                            styleInput={
                              !!votedArtist && votedArtist.guid === artist.guid
                                ? styles.votedCount
                                : styles.voteCount
                            }
                          />
                        </View>
                      )}
                    </View>

                    {!artist.isWildcard ? (
                      <TouchableOpacity
                        style={styles.voteIcon}
                        onPress={() => onVote(artist)}
                        disabled={isDisabledVote}
                      >
                        <Icon
                          name='HeartIcon'
                          filled={
                            !!votedArtist && votedArtist.guid === artist.guid
                          }
                          width={24}
                          height={21}
                        />
                      </TouchableOpacity>
                    ) : (
                      <Text style={styles.wildcard}>Wildcard Winner</Text>
                    )}
                  </View>
                </Card.Content>
              </Card>
            </TouchableOpacity>
          ))}
      </Surface>
    </Container>
  )
}
