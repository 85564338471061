import React, { useState, useEffect } from 'react'
import { Surface, Text, useTheme } from 'react-native-paper'
import { Platform, TouchableOpacity } from 'react-native'
import { Container } from '@nowon/container'
import { updateUser, updateUserTicketNumber } from '../../apiGraphql'
import { UserDetails } from '../../components/onboard/userDetails'

export const ProfileUpdate = (props) => {
  const { user, setUser, navigation, t } = props
  const [userName, setUserName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [street, setStreet] = useState('')
  const [doorNumber, setDoorNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [gender, setGender] = useState('')
  const [email, setEmail] = useState('')
  const [ticketNumber, setTicketNumber] = useState()
  const { colors } = useTheme()

  useEffect(() => {
    if (!user) return
    setUserName(user?.userName)
    setFirstName(user?.firstName)
    setLastName(user?.lastName)
    setMobileNumber(user?.mobileNumber)
    setDateOfBirth(user?.dateOfBirth)
    setStreet(user?.street)
    setDoorNumber(user?.doorNumber)
    setZipCode(user?.zipCode)
    setCity(user?.city)
    setCountry(user?.country)
    setGender(user?.gender)
    setTicketNumber(user?.ticketNumber)
    setEmail(user?.email)
  }, [user])

  const handleBackNavigation = () => {
    if (Platform.OS === 'web') {
      window.history.go(-1)
    } else {
      navigation.goBack()
    }
  }

  const handleUpdateProfile = async () => {
    try {
      const response = await updateUser({
        userName,
        firstName,
        lastName,
        mobileNumber,
        dateOfBirth,
        userId: user?.userId,
        street,
        doorNumber,
        zipCode,
        city,
        country,
        gender,
      })
      if (response?.ticketNumber !== ticketNumber) {
        const ticketNumberResponse = await updateUserTicketNumber({
          userId: user?.userId,
          ticketNumber,
        })
        setUser(ticketNumberResponse)
      } else {
        setUser(response)
      }
      handleBackNavigation()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Surface
      elevation={0}
      style={{
        backgroundColor: '#000000',
        paddingBottom: 32,
      }}
    >
      <Container>
        <Text
          variant='labelMedium'
          style={{
            paddingTop: 16,
            paddingBottom: 24,
            color: colors.textPrimary,
          }}
        >
          <TouchableOpacity onPress={() => navigation.navigate('profile')}>
            {t('PROFILE')}
          </TouchableOpacity>
          {'>'} {t('UPDATE_PROFILE')}
        </Text>
        <Text
          variant='headlineLarge'
          style={{ color: colors.primary, marginBottom: 24 }}
        >
          {t('UPDATE_PROFILE')}
        </Text>
        <UserDetails
          isFromProfile
          handleUpdateProfile={handleUpdateProfile}
          userName={userName}
          setUserName={setUserName}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          street={street}
          setStreet={setStreet}
          doorNumber={doorNumber}
          setDoorNumber={setDoorNumber}
          zipCode={zipCode}
          setZipCode={setZipCode}
          city={city}
          setCity={setCity}
          country={country}
          setCountry={setCountry}
          gender={gender}
          setGender={setGender}
          ticketNumber={ticketNumber}
          setTicketNumber={setTicketNumber}
          email={email}
        />
      </Container>
    </Surface>
  )
}
