import React from 'react'
import { View, Image, TouchableOpacity, Linking } from 'react-native'
import { useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@nowon/components'

const PlayListCard = (props) => {
  const { colors } = useTheme()
  const { t } = useTranslation()

  // console.log(props.image)
  return (
    <View style={{ paddingHorizontal: 5 }}>
      <TouchableOpacity
        style={{
          width: 130,
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 5,
        }}
        onPress={() => {
          Linking.openURL(props?.url)
        }}
      >
        <Image
          source={props?.image}
          style={{
            height: 126,
            width: 126,
            borderRadius: 10,
          }}
        />
        <Text colorVariant='textPrimary' style={{ fontSize: 12 }}>
          {props?.title}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default PlayListCard
