import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgEditIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M1.48 14.59a.75.75 0 0 1-.553-.244.74.74 0 0 1-.197-.574l.183-2.02 8.489-8.49 2.653 2.653-8.487 8.488-2.02.184a.707.707 0 0 1-.068.003Zm11.104-9.205L9.932 2.732l1.59-1.591a.75.75 0 0 1 1.062 0l1.59 1.591a.752.752 0 0 1 0 1.062l-1.59 1.59v.001Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgEditIcon
