import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgDownloadIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M13 8.334a.667.667 0 0 0-.667.666v2.667a.666.666 0 0 1-.667.667H2.333a.666.666 0 0 1-.667-.667V9A.667.667 0 0 0 .333 9v2.667a2 2 0 0 0 2 2h9.333a2 2 0 0 0 2-2V9A.667.667 0 0 0 13 8.334Zm-6.474 1.14c.064.06.139.108.22.14a.627.627 0 0 0 .507 0 .667.667 0 0 0 .22-.14l2.667-2.667a.67.67 0 0 0-.947-.947L7.666 7.394V1a.667.667 0 0 0-1.333 0v6.394L4.806 5.86a.67.67 0 0 0-.946.947l2.666 2.666Z'
      fill='#222'
    />
  </Svg>
)

export default SvgDownloadIcon
