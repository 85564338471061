import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgEventsIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 26 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 0a8 8 0 0 0-8 8v8a8 8 0 0 0 8 8h10a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H8Zm6.451 5.927c-.3-.921-1.603-.921-1.902 0l-.957 2.946a1 1 0 0 1-.951.691H7.543c-.97 0-1.372 1.24-.588 1.81l2.506 1.82a1 1 0 0 1 .363 1.118l-.957 2.947c-.3.92.755 1.687 1.539 1.118l2.506-1.821a1 1 0 0 1 1.176 0l2.506 1.82c.784.57 1.838-.196 1.539-1.117l-.957-2.947a1 1 0 0 1 .363-1.118l2.506-1.82c.784-.57.381-1.81-.588-1.81H16.36a1 1 0 0 1-.95-.69l-.958-2.947Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgEventsIcon
