import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TouchableOpacity, StyleSheet, View, Image } from 'react-native'
import {
  useTheme,
  Card,
  Avatar,
  IconButton,
  MD3Colors,
  ProgressBar,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import { useNavigation } from '@react-navigation/native'
import SampleAudioImage from '../../assets/Contest-sampleAudioImage.png'
import { Icon } from '@nowon/icons'
import { secondsToHms } from '../../utils/contestHelper'

const styles = StyleSheet.create({
  breadcumView: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#CCCCCC',
  },
  subBreadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#6EC1E4',
  },
  pageTitle: {
    fontFamily: 'Lexend Deca',
    fontSize: 32,
    color: '#6EC1E4',
    paddingRight: 30,
  },
  pageDesc: {
    fontFamily: 'Lexend Deca',
    fontSize: 14,
    paddingRight: 80,
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 12,
  },
  cardContentMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContentAvatar: {
    marginRight: 16,
  },
  cardContentAvatarImage: {
    height: 90,
    width: 80,
    borderRadius: 4,
  },
  cardContentArtistInfo: {
    alignSelf: 'flex-start',
    display: 'flex',
    height: '100%',
    flexGrow: 1,
  },
  cardContentArtistName: {
    fontSize: 16,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  cardContentArtistDescription: {
    fontSize: 12,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    color: '#CCCCCC',
  },
  playerTimer: {
    fontSize: 12,
    fontFamily: 'Lexend Deca',
    color: '#CCCCCC',
  },
  voteIcon: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 40,
    height: 40,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  progressBar: { marginBottom: 8 },
  audioPlayerIcons: { height: 18, width: 18, margin: 0 },
})

const AudioPlayer = (props) => {
  const { selectedArtist } = props

  // TODO: Handle tracks
  const audioSrc = selectedArtist.songUrls[0];
  const [trackIndex, setTrackIndex] = useState(0)
  const [trackProgress, setTrackProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(new Audio(audioSrc))
  const intervalRef = useRef()
  const isReady = useRef(false)
  const { duration } = audioRef.current
  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : '0%'
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #787880), color-stop(${currentPercentage}, #787880))
  `

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack()
      } else {
        setTrackProgress(audioRef.current.currentTime)
      }
    }, [1000])
  }

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current)
    audioRef.current.currentTime = value
    setTrackProgress(audioRef.current.currentTime)
  }

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      audioRef.current.play()
      setIsPlaying(true)
    }
    startTimer()
  }

  const toPrevTrack = () => {
    // if (trackIndex - 1 < 0) {
    //   setTrackIndex(tracks.length - 1)
    // } else {
    //   setTrackIndex(trackIndex - 1)
    // }
  }

  const toNextTrack = () => {
    // if (trackIndex < tracks.length - 1) {
    //   setTrackIndex(trackIndex + 1)
    // } else {
    //   setTrackIndex(0)
    // }
  }
  useEffect(() => {
    if (isPlaying) {
      startTimer()
    }
  }, [isPlaying])

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause()

    audioRef.current = new Audio(audioSrc)
    setTrackProgress(audioRef.current.currentTime)

    if (isReady.current) {
      audioRef.current.play()
      setIsPlaying(true)
      startTimer()
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true
    }
  }, [selectedArtist])

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause()
      clearInterval(intervalRef.current)
    }
  }, [])

  const onPressPlayPause = (flag) => {
    if (flag) {
      console.log('?????????????', audioSrc)

      audioRef.current.play()
      setIsPlaying(true)
    } else {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  return (
    <Card style={styles.card}>
      <Card.Content>
        <View style={styles.cardContentMain}>
          <View style={styles.cardContentAvatar}>
            <Image
              style={styles.cardContentAvatarImage}
              source={{ uri: selectedArtist.avatarUrl }}
            />
          </View>

          <View style={styles.cardContentArtistInfo}>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.cardContentArtistName}>
                {selectedArtist.songNames[0]}
              </Text>
              <Text style={styles.cardContentArtistDescription}>
                {selectedArtist?.name}
              </Text>
            </View>

            <View style={styles.progressBar}>
              <input
                type='range'
                value={trackProgress}
                step='1'
                min='0'
                max={duration ? duration : `${duration}`}
                className='progress'
                onChange={(e) => onScrub(e.target.value)}
                onMouseUp={onScrubEnd}
                onKeyUp={onScrubEnd}
                style={{ background: trackStyling }}
              />
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={styles.playerTimer}>{ secondsToHms(audioRef.current.currentTime)} / 3:44</Text>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  position: 'absolute',
                  right: 0,
                }}
              >
                <IconButton
                  icon='skip-previous'
                  iconColor={MD3Colors.neutral100}
                  style={styles.audioPlayerIcons}
                  size={18}
                  onPress={toPrevTrack}
                />
                <IconButton
                  icon='skip-next'
                  iconColor={MD3Colors.neutral100}
                  style={styles.audioPlayerIcons}
                  size={18}
                  onPress={toNextTrack}
                />
              </View>
            </View>
          </View>

          <View style={styles.voteIcon}>
            {isPlaying ? (
              <IconButton
                icon='pause'
                iconColor='#E46147'
                style={{ backgroundColor: '#FFFFFF' }}
                size={22}
                onPress={() => onPressPlayPause(false)} 
              />
            ) : (
              <IconButton
                icon='play'
                iconColor='#E46147'
                style={{ backgroundColor: '#FFFFFF' }}
                size={22}
                onPress={() => onPressPlayPause(true)}
              />
            )}
          </View>
        </View>
      </Card.Content>
    </Card>
  )
}

export default AudioPlayer
