import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgExclamatoryIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 4 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M1.357 15.288a81.867 81.867 0 0 1-.352-4.16C.92 9.678.855 8.248.813 6.84a132.368 132.368 0 0 1-.064-4V.6h2.592v2.24c0 1.323-.021 2.71-.064 4.16a95.945 95.945 0 0 1-.224 4.32 67.05 67.05 0 0 1-.352 3.968H1.357Zm.576 8.032c-.576 0-1.024-.17-1.344-.512-.299-.341-.448-.832-.448-1.472 0-.576.16-1.045.48-1.408.341-.384.779-.576 1.312-.576.576 0 1.013.181 1.312.544.32.341.48.821.48 1.44 0 .597-.17 1.077-.512 1.44-.32.363-.747.544-1.28.544Z'
      fill='#EE9E9A'
    />
  </Svg>
)

export default SvgExclamatoryIcon
