import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgMusicIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.373.017c0 .305.09 4.714 4.717 4.989l.001 3.406c-.35.02-3.046-.175-4.724-1.671l-.005 6.632c.064 4.603-3.323 7.404-7.751 6.436C-3.024 17.525-.487 6.201 7.179 7.413c0 3.656.002 0 .002 3.656-3.167-.466-4.226 2.169-3.385 4.055.766 1.718 3.92 2.09 5.02-.333a6.4 6.4 0 0 0 .186-1.622V0l3.37.017Z'
      fill='#000'
    />
  </Svg>
)

export default SvgMusicIcon
