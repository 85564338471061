import React from 'react'
import { TouchableOpacity, View, Modal } from 'react-native'
import { Icon } from '@nowon/icons'
import { Button, Text } from 'react-native-paper'

const ProfileImagePopup = (props) => {
  const { modalVisible, setModalVisible, colors, t } = props
  return (
    <Modal
      animationType='slide'
      transparent
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            width: '100%',
            height: '100%',
            padding: 35,
            elevation: 5,
            background: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <View
            style={{
              margin: 'auto',
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              borderRadius: 16,
              padding: 35,
              paddingTop: 10,
              paddingRight: 10,
              display: 'flex',
              width: '100%',
              height: '50%',
            }}
          >
            <View style={{ justifyContent: 'flex-end', marginLeft: 'auto' }}>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(!modalVisible)
                }}
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50,
                }}
              >
                <Icon name='CloseIcon' color='white' width={14} />
              </TouchableOpacity>
            </View>
            <View
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: 25,
              }}
            >
              <Text
                style={{
                  color: colors.primary,
                  textAlign: 'center',
                  elevation: 0,
                  marginBottom: 20,
                }}
                variant='headlineSmall'
              >
                {t('IMAGE_SIZE')}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Button
                  mode='outlined'
                  style={{
                    backgroundColor: '#fff',
                    width: 100,
                    height: 32,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    setModalVisible(!modalVisible)
                  }}
                >
                  {t('ACTIONS.OK')}
                </Button>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default ProfileImagePopup
