/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Surface, Text, Button } from 'react-native-paper'
import { Container } from '@nowon/container'
import { Icon } from '@nowon/icons'
import { CouponCard } from '@nowon/card'
import { ActivityIndicator, View } from 'react-native'
import { RWebShare } from 'react-web-share'
import discountCoupon from '../../assets/discountCoupon.png'
import coins from '../../assets/coins.png'
import { getAllChallengesByUserId } from '../../apiGraphql'
import { appUrl } from '../../config'

export const ChallengeCompleted = (props) => {
  const { navigation, colors, t, route, user } = props
  const [challengeDetails, setChallengeDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  async function getChallengesList() {
    try {
      setIsLoading(true)
      const response = await getAllChallengesByUserId(user?.userId)
      const challengeData = response?.find(
        (item) => item.challengeId === parseInt(route?.params?.challengeId, 10),
      )
      setChallengeDetails(challengeData)
      setIsLoading(false)
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    if (user?.userId) getChallengesList()
  }, [user])

  if (isLoading) {
    return (
      <Container>
        <View
          style={{
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <ActivityIndicator color={colors.primary} />
        </View>
      </Container>
    )
  }
  return (
    <Container>
      <Surface elevation={0} style={{ paddingBottom: 10 }}>
        <Surface elevation={0} style={{ gap: 68 }}>
          <Surface elevation={0} style={{ gap: 17 }}>
            <Icon name='ThumbsUpIcon' width={48} height={56} color='#6EE4E4' />
            <Text variant='headlineLarge' style={{ color: '#6EE4E4' }}>
              {route.params?.status === 'COMPLETED' &&
              challengeDetails?.challengeType !== 'VOTE3FAVORITE'
                ? t('CHALLENGE_ALREADY_COMPLETED')
                : t('CHALLENGE_COMPLETED')}
            </Text>
            <Text variant='titleMedium' style={{ color: colors.textPrimary }}>
              {challengeDetails?.title}
            </Text>
            {route.params?.challengeType === 'QR_PHOTOBOOTH' && (
              <>
                <Text
                  variant='headlineSmall'
                  style={{ color: colors.textPrimary }}
                >
                  {t('PHOTO_BOOTH_CHALLENGE_SUCCESS_MESSAGE')}
                </Text>
                <Text
                  variant='headlineSmall'
                  style={{ color: '#6EE4E4', alignSelf: 'center' }}
                >
                  {user?.photoFolderCode}
                </Text>
              </>
            )}
          </Surface>
          {route.params?.status !== 'COMPLETED' ? (
            challengeDetails?.totalPoints ? (
              <CouponCard
                image={coins}
                value={challengeDetails?.totalPoints}
                subHeading={t('REWARD_CLAIM')}
                content={t('REDEEM_POINTS')}
                backgroundColor='#CBF0FF'
              />
            ) : (
              <CouponCard
                image={discountCoupon}
                value={challengeDetails?.discount}
                couponCode={challengeDetails?.couponCode}
                content={t('REDEEM_CODE')}
                backgroundColor='#CBF0FF'
              />
            )
          ) : (
            <View />
          )}
          <Surface elevation={0} style={{ gap: 24, alignItems: 'center' }}>
            <Button
              mode='outlined'
              contentStyle={{ height: 48 }}
              style={{
                width: 186,
                borderColor: colors.primary,
                borderRadius: 24,
              }}
              labelStyle={{ fontSize: 14, fontWeight: 300 }}
              onPress={() => navigation.navigate('challenges')}
            >
              {t('ACTIONS.BACK')}
            </Button>
            <RWebShare
              data={{
                text: `${t('INVITE_FRIEND_TO_CHALLENGE')}${
                  challengeDetails?.title
                }`,
                url: `${appUrl}/followChallenge?challengeId=${challengeDetails?.challengeId}`,
                title: `${t('INVITE_FRIEND_TO_CHALLENGE')}${
                  challengeDetails?.title
                }`,
              }}
              onClick={() => console.log('shared successfully!')}
            >
              <Text
                variant='titleMedium'
                style={{
                  textDecorationLine: 'Underline',
                  color: colors.neutralLight,
                }}
              >
                {t('INVITE_YOUR_FRIENDS')}
              </Text>
            </RWebShare>
          </Surface>
        </Surface>
      </Surface>
    </Container>
  )
}
