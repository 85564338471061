import React, { useEffect } from 'react'
import {
  Surface,
  Text,
  Button,
  useTheme,
  ActivityIndicator,
} from 'react-native-paper'
import {
  EmailTextInput,
  PasswordTextInput,
  SocialButton,
} from '@nowon/components'
import { BackgroundContainer } from '@nowon/container'
import { useIsFocused } from '@react-navigation/native'
import BlurWidget from '@nowon/components/src/Blur'
import useLogin from './useLogin'

export const Login = (props) => {
  const { colors } = useTheme()
  const { navigation, t } = props
  const isFocus = useIsFocused()
  const {
    signIn,
    email,
    password,
    setEmail,
    setPassword,
    errorMsg,
    setErrorMsg,
    googleSignIn,
    isLoading,
    continueClicked,
    setContinueClicked,
  } = useLogin()
  useEffect(() => {
    if (isFocus === false) {
      setEmail('')
      setPassword('')
    }
  }, [isFocus])
  return (
    <BackgroundContainer>
      {isLoading ? (
        <Surface
          elevation={0}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: 50,
          }}
        >
          <ActivityIndicator size='large' />
        </Surface>
      ) : (
        <>
          <BlurWidget borderRadius={16} />
          <Surface elevation={0}>
            <Surface elevation={0} style={{ gap: 12 }}>
              <Text
                style={{
                  color: colors.primary,
                  fontSize: '32px',
                  lineHeight: '40px',
                  fontWeight: 700,
                }}
              >
                {t('Login')}
              </Text>
              <SocialButton
                onPress={() => googleSignIn()}
                iconName='GoogleIcon'
                label={t('SIGN_IN_GOOGLE')}
              />
              <Text
                variant='titleMedium'
                style={{
                  color: colors.neutralLight,
                }}
              >
                {t('WITH_EMAIL')}
              </Text>
              <EmailTextInput
                mode='outlined'
                placeholder={t('EMAIL_ID')}
                style={{
                  backgroundColor: colors.neutralLight,
                }}
                value={email}
                onChangeText={(text) => setEmail(text.toLowerCase().trim())}
                onValidate={(isValid) => {
                  console.log({ isValid })
                }}
                onFocus={() => {
                  setContinueClicked(false)
                  setErrorMsg('')
                }}
                errorMessage={t('EMAIL_INVALID')}
              />
              <PasswordTextInput
                mode='outlined'
                placeholder='Password'
                secureTextEntry
                style={{
                  backgroundColor: colors.neutralLight,
                }}
                value={password}
                onFocus={() => {
                  setContinueClicked(false)
                  setErrorMsg('')
                }}
                onChangeText={(text) => setPassword(text)}
                onValidate={(isValid) => {
                  console.log({ isValid })
                }}
                errorMessage={t('PASSWORD_INVALID')}
                continueClicked={continueClicked}
              />
            </Surface>
            {errorMsg && continueClicked && (
              <Text style={{ color: 'red' }}>{errorMsg}</Text>
            )}
            <Text
              style={{
                fontSize: 18,
                lineHeight: 17,
                fontWeight: 400,
                color: '#509AC4',
                cursor: 'pointer',
                alignSelf: 'flex-end',
                marginRight: 8,
                marginVertical: 18,
              }}
              onPress={() => navigation.navigate('forgotPassword')}
            >
              {t('FORGOT_PASS')}
            </Text>
            <Button
              mode='contained'
              contentStyle={{ height: 48, backgroundColor: '#6EC1E4' }}
              style={{
                borderRadius: 8,
                width: '287px',
              }}
              labelStyle={{
                color: 'white',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 400,
              }}
              onPress={() => signIn()}
            >
              {t('ACTIONS.CONTINUE')}
            </Button>
            <Surface
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 18,
              }}
              elevation={0}
            >
              <Text
                variant='labelLarge'
                style={{
                  color: '#509AC4',
                  cursor: 'pointer',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 18,
                  lineHeight: 18,
                }}
                onPress={() => {
                  navigation.navigate('register')
                }}
              >
                {t('REGISTER')}
              </Text>
            </Surface>
          </Surface>
        </>
      )}
    </BackgroundContainer>
  )
}
