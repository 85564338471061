import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgLoveIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 16 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M15.53 4.652A4.155 4.155 0 0 0 11.376.497 4.147 4.147 0 0 0 8 2.236 4.146 4.146 0 0 0 4.624.497 4.155 4.155 0 0 0 .47 4.652c0 .325.042.64.112.944C1.16 9.18 5.143 12.962 8 14c2.856-1.038 6.841-4.82 7.417-8.404.072-.304.113-.619.113-.944Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgLoveIcon
