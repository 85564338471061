import React from 'react'
import { Surface, Text, Button } from 'react-native-paper'
import {
  Image,
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { Container } from '@nowon/container'
import { CouponCard } from '@nowon/card'
import { RWebShare } from 'react-web-share'
import { instagramPage, appUrl } from '../../config'
import Camera from '../../assets/camera.png'
import Logo from '../../assets/LogoFollow.png'
import discountCoupon from '../../assets/discountCoupon.png'
import coins from '../../assets/coins.png'
import { getAllChallengesByUserId } from '../../apiGraphql'

export const FollowChallenge = (props) => {
  const { navigation, user, colors, t } = props
  const challengeId = Number(props?.route?.params?.challengeId)

  const { data: challengeDetail } = useQuery(
    ['getAllChallengesByUserId', challengeId],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      if (response) {
        const filteredData = response?.find(
          (r) => r.challengeId === challengeId,
        )
        return filteredData
      }
    },
    {
      enabled: !!user?.userId && !!challengeId,
    },
    {
      initialData: {},
    },
  )

  const handleNavigation = () => {
    if (challengeDetail?.challengeType === 'FOLLOW_INSTAGRAM') {
      Linking.openURL(instagramPage)
      return
    }
    if (challengeDetail?.challengeType === 'INVITE_FRIEND') {
      navigation.navigate('inviteFriends')
      return
    }
    if (challengeDetail?.challengeType === 'ALL_SPONSORS_VISITED') {
      return navigation.navigate('challenges')
    }
    navigation.navigate('scanner', {
      challengeId,
    })
  }
  function getCtaText() {
    if (challengeDetail?.challengeType.startsWith('QR'))
      return t('ACTIONS.SCAN_QR')
    if (challengeDetail?.challengeType === 'INVITE_FRIEND')
      return t('ACTIONS.INVITE')
    if (challengeDetail?.challengeType === 'ALL_SPONSORS_VISITED')
      return t('ACTIONS.BACK')
    return t('ACTIONS.FOLLOW')
  }

  return (
    <Container>
      <Surface
        elevation={0}
        style={{ height: '100vh', justifyContent: 'space-between', flex: 1 }}
      >
        <Surface elevation={0} style={{ gap: 20, marginBottom: 12 }}>
          <Text variant='bodyMedium' style={{ color: colors.textPrimary }}>
            <TouchableOpacity onPress={() => navigation.navigate('challenges')}>
              {t('CHALLENGES')}{' '}
            </TouchableOpacity>
            {'>'} {challengeDetail?.title}
          </Text>
          <Text variant='headlineLarge' style={{ color: colors.primary }}>
            {challengeDetail?.title}
          </Text>
        </Surface>
        <Surface elevation={0} style={{ gap: 17 }}>
          <Surface
            elevation={0}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 11,
            }}
          >
            {challengeDetail?.challengeType === 'FOLLOW_INSTAGRAM' ? (
              <Image style={{ height: 80, width: 80 }} source={Logo} />
            ) : (
              <View style={styles.avatar}>
                <Image style={{ height: 48, width: 48 }} source={Camera} />
              </View>
            )}
            <Surface
              elevation={0}
              style={{
                justifyContent: 'center',
                width: '72%',
                gap: 12,
              }}
            >
              <Text variant='titleMedium' style={{ color: colors.textPrimary }}>
                {challengeDetail?.description}
              </Text>
            </Surface>
          </Surface>
        </Surface>
        {challengeDetail?.status === 'COMPLETED' && (
          <Text
            variant='labelLarge'
            style={{ color: colors.textPrimary, marginTop: 20 }}
          >
            {t('CHALLENGE_ALREADY_COMPLETED')}
          </Text>
        )}
        <CouponSection challengeDetail={challengeDetail} t={t} />
        <View style={{ flex: 1 }} />
        <View
          style={{
            alignItems: 'center',
            paddingBottom: '30%',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <View style={{ alignItems: 'center', gap: 12 }}>
            {challengeDetail?.challengeType !== 'ALL_SPONSORS_VISITED' && (
              <Button
                mode='contained'
                contentStyle={{ height: 48, backgroundColor: '#6EC1E4' }}
                style={{
                  borderRadius: 24,
                  width: '186px',
                  marginBottom: 10,
                }}
                labelStyle={{
                  color: 'white',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 400,
                }}
                onPress={handleNavigation}
                disabled={challengeDetail?.status === 'COMPLETED'}
              >
                {getCtaText()}
              </Button>
            )}
            <RWebShare
              data={{
                text: `${t('INVITE_FRIEND_TO_CHALLENGE')}${
                  challengeDetail?.title
                }`,
                url: `${appUrl}/followChallenge?challengeId=${challengeId}`,
                title: `${t('INVITE_FRIEND_TO_CHALLENGE')}${
                  challengeDetail?.title
                }`,
              }}
              onClick={() => console.log('shared successfully!')}
            >
              <Text
                variant='titleMedium'
                style={{
                  textDecorationLine: 'Underline',
                  color: colors.textPrimary,
                  cursor: 'pointer',
                }}
              >
                {' '}
                {t('INVITE_YOUR_FRIENDS')}
              </Text>
            </RWebShare>
          </View>
        </View>
      </Surface>
    </Container>
  )
}

const CouponSection = (props) => {
  const { challengeDetail, t } = props
  if (challengeDetail?.totalPoints && challengeDetail?.discount) {
    return (
      <View style={{ paddingVertical: 30 }}>
        <CouponCard
          image={discountCoupon}
          value={`${challengeDetail?.totalPoints} ${t('PTS')} & ${
            challengeDetail?.discount
          }`}
          subHeading={t('REWARD_CLAIM')}
          content={challengeDetail.rewardDescription}
        />
      </View>
    )
  }
  if (challengeDetail?.totalPoints) {
    return (
      <View style={{ paddingVertical: 30 }}>
        <CouponCard
          image={coins}
          value={challengeDetail?.totalPoints}
          subHeading={t('REWARD_CLAIM')}
          content={challengeDetail.rewardDescription}
        />
      </View>
    )
  }
  if (challengeDetail?.discount) {
    return (
      <View style={{ paddingVertical: 30 }}>
        <CouponCard
          image={discountCoupon}
          value={challengeDetail?.discount}
          content={challengeDetail.rewardDescription}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: '#ffffff',
    paddingHorizontal: '24px',
    paddingTop: '33px',
    paddingBottom: '33px',
    minWidth: '327px',
    minHeight: '510px',
    borderRadius: '16px',
    justifyContent: 'space-between',
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    alignItems: 'center',
  },
  avatar: {
    height: 80,
    width: 80,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
})
