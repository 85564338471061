// this is an auto generated file. This will be overwritten

export const getUserById = /* GraphQL */ `
  query GetUserById($userId: String!) {
    getUserById(userId: $userId) {
      challengesAchieved
      city
      country
      dateOfBirth
      couponsWon
      doorNumber
      email
      earnedPoints
      gender
      firstName
      lastName
      mobileNumber
      redeemedPoints
      photoFolderCode
      street
      referralCode
      ticketNumber
      totalChallenges
      userDeletionRequested
      userId
      userName
      zipCode
      isNewUser
      isSpotifyConnected
      favouriteArtists
      preferredArtistRegion
      referredBy
    }
  }
`
export const getOnboardingQuestions = /* GraphQL */ `
  query GetOnboardingQuestions($userId: String!) {
    getOnboardingQuestions(userId: $userId) {
      questionId
      questionType
      questionText
      status
      answerOptions
      answers
      additionalInfo
      additionalInfoTriggerAnswerOption
    }
  }
`
export const getAllChallengesByUserId = /* GraphQL */ `
  query GetAllChallengesByUserId($userId: String!, $year: String) {
    getAllChallengesByUserId(userId: $userId, year: $year) {
      challengeId
      challengeType
      status
      totalPoints
      title
      description
      discount
      expiryDate
      couponCode
      rewardDescription
    }
  }
`
export const getAllEvents = /* GraphQL */ `
  query GetEvents {
    getAllEvents {
      SK
      eventDate
      eventDuration
      eventName
      endTime {
        hours
        minutes
      }
      startTime {
        hours
        minutes
      }
      eventInfo
    }
  }
`
export const getOffers = /* GraphQl */ `
query GetOffers($year: String) {
  getOffers(year: $year) {
    SK
    availableOffers
    description
    imageURL
    offerType
    pointsRequired
    title
    redeemMessage
  }
}
`

export const getAllUserProducts = `
query GetAllUserProducts($userId: String!) {
  getAllUserProducts(userId: $userId) {
    points
    redeemedDate
    status
    title
    offerType
  }
}
`
export const getAllNotificationsByUserId = `
query GetAllNotificationsByUserId($userId: String!) {
  getAllNotificationsByUserId(userId: $userId) {
    description
    notificationId
    notificationType
    status
    title
  }
}
`
export const getAllCouponsWon = `
query getAllCouponsWon($userId: String!) {
  getAllCouponsWon(userId: $userId) {
    couponCode
    couponDescription
    discount
    expiryDate
    sponsorURL
  }
}`
export const getOffersCountByUserId = `
query getOffersCountByUserId($userId: String) {
  getOffersCountByUserId(userId: $userId) {
    count
    title
  }
}`

export const getAllOffersCount = `
query getAllOffersCount {
  getOffersCountByUserId {
    count
    title
  }
}`

export const getImagesByFolderId = `
query getImagesByFolderId($photoFolderCode: String!) {
  getImagesByFolderId(photoFolderCode: $photoFolderCode) {
    name
    url
  }
}`

export const getAdminTopTracks = `
query getAdminTopTracks($userId: String!) {
  getAdminTopTracks(userId: $userId) {
    userId
    artists {
      genres
      id
      name
      popularity
      type
    }
    songs {
      id
      name
      popularity
      album {
        images {
          height
          url
          width
        }
      }
      external_urls {
        spotify
        }
    }
  }
}`

export const getMatchedPercentage = `
query getMatchedPercentage {
  getMatchedPercentage {
    userId
    spotifyUserName
    spotifyUserId
    spotifyUserImage {
      url
    }
    matchedPercentages {
      matchedArtistPercentage
      matchedGenrePercentage
      matchedSongsPercentage
      totalPercentage
    }
  }
}`

export const getContestArtistProfiles = `
query getContestArtistProfiles {
  getContestArtistProfiles {
    id
    guid
    name
    avatarUrl
    albumName
    songUrls
    songNames
    spotifyUrl
    youtubeUrl
    instagramUrl
    website
    isWildcard
    isWinner
  }
}`

export const getVoteTracker = /* GraphQL */ `
  query getVoteTracker($userId: String!) {
    getVoteTracker(userId: $userId) {
      id
      userId
      latestVotedAt
      latestVotedArtistId
    }
  }
`
export const getVotesByArtistId = /* GraphQL */ `
  query getVotesByArtistId($artistId: String!, $limit: Int, $nextToken: String) {
    getVotesByArtistId(artistId: $artistId, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`

export const getVotesByDate = /* GraphQL */ `
  query getVotesByDate($startDate: String!, $endDate: String!) {
    getVotesByDate(startDate: $startDate, endDate: $endDate) {
      id
      artistId
    }
  }
`

export const getBlacklistEmail = /* GraphQL */ `
  query getBlacklistEmail($emailDomain: String!) {
    getBlacklistEmail(emailDomain: $emailDomain) {
      PK
    }
  }
`
