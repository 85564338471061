import { useQuery, useMutation } from '@tanstack/react-query'
import {
  getOnboardingQuestions,
  updateOnboardingAnswers,
} from '../../apiGraphql'

export const useOnboardingQuestions = (userId) => {
  const { data } = useQuery(
    ['getOnboardingQuestions'],
    async () => {
      const response = await getOnboardingQuestions(userId)
      return response
    },
    {
      enabled: !!userId,
    },
    {
      initialData: [],
    },
  )

  return { data }
}

export const useUpdateOnboardingAnswers = (userId) =>
  useMutation(async ({ questionId, answers }) => {
    await updateOnboardingAnswers({
      userId,
      questionId,
      answers,
    })
  })
