import React from 'react'
import { View } from 'react-native'
import { Svg, Circle } from 'react-native-svg'
import { Icon } from '@nowon/icons'

const CircularProgress = (props) => {
  const {
    size,
    strokeWidth,
    innerStrokeWidth,
    progressPercent = 0,
    iconName = 'DiamondIcon',
  } = props
  const radius = (size - strokeWidth) / 2
  const circum = radius * 2 * Math.PI
  const svgProgress = 100 - progressPercent

  return (
    <View
      style={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Svg width={size} height={size}>
        {/* Background Circle */}
        {innerStrokeWidth ? (
          <Circle
            stroke={props.bgColor ? props.bgColor : '#f2f2f2'}
            fill='none'
            cx={size / 2}
            cy={size / 2}
            r={radius}
            {...{ innerStrokeWidth }}
          />
        ) : (
          <Circle
            stroke={props.bgColor ? props.bgColor : '#f2f2f2'}
            fill='none'
            cx={size / 2}
            cy={size / 2}
            r={radius}
            {...{ strokeWidth }}
          />
        )}

        {/* Progress Circle */}
        <Circle
          stroke={props.pgColor ? props.pgColor : 'rgba(92, 164, 169, 1)'}
          fill='none'
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap='round'
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
      </Svg>
      <View style={{ position: 'absolute' }}>
        <Icon name={iconName} width={size / 2} height={size / 2} />
      </View>
    </View>
  )
}

export default CircularProgress
