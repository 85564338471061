import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'
import ContestBackgroundImage from '../../assets/Contest-mainBackground.png'
import { useNavigation } from '@react-navigation/native'
import { useTimer } from 'react-timer-hook'
import TimerStyled from './Timmer'
import { getNextVoteAt } from '../../utils/contestHelper'

const styles = StyleSheet.create({
  imageBackground: { height: 178, width: '100%' },
  votingText: {
    fontSize: 20,
    fontFamily: 'NunitoSans-Regular',
  },
  giveYourVoteText: {
    fontSize: 20,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
})

const VotingCard = (props) => {
  const { voteTracker } = props
  const navigation = useNavigation()
  const { t } = useTranslation()

  const expiryTimestamp = useMemo(() => {
    return getNextVoteAt(voteTracker?.latestVotedAt)
  }, [voteTracker])

  const { seconds, minutes, hours } = useTimer({
    autoStart: true,
    expiryTimestamp: expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  })

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('contestArtists')
      }}
    >
      <ImageBackground
        source={ContestBackgroundImage}
        style={styles.imageBackground}
        imageStyle={{ borderRadius: 24 }}
        resizeMode='cover'
      >
        <View
          style={{
            paddingHorizontal: 24,
            paddingVertical: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <View style={{ marginBottom: 20 }}>
            <Text style={styles.votingText}>{ t('CONTEST.VOTING') }</Text>

            <Text style={styles.giveYourVoteText}>{ t('CONTEST.GIVE_DAILY') }</Text>
          </View>
          <View>
            <TimerStyled seconds={seconds} minutes={minutes} hours={hours} />
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  )
}

export default VotingCard
