import { Platform } from 'react-native'
import * as MD3Tokens from './material-theme/styledictionary/js/tokens'

const ref = {
  palette: {
    primary100: MD3Tokens.MdRefPalettePrimary100,
    primary99: MD3Tokens.MdRefPalettePrimary99,
    primary95: MD3Tokens.MdRefPalettePrimary95,
    primary90: MD3Tokens.MdRefPalettePrimary90,
    primary80: MD3Tokens.MdRefPalettePrimary80,
    primary70: MD3Tokens.MdRefPalettePrimary70,
    primary60: MD3Tokens.MdRefPalettePrimary60,
    primary50: MD3Tokens.MdRefPalettePrimary50,
    primary40: MD3Tokens.MdRefPalettePrimary40,
    primary30: MD3Tokens.MdRefPalettePrimary30,
    primary20: MD3Tokens.MdRefPalettePrimary20,
    primary10: MD3Tokens.MdRefPalettePrimary10,
    primary01: MD3Tokens.MdSysColorInversePrimary01,
    primary02: MD3Tokens.MdSysColorInversePrimary02,
    primary03: MD3Tokens.MdSysColorInversePrimary03,
    primary0: MD3Tokens.MdRefPalettePrimary0,
    secondary100: MD3Tokens.MdRefPaletteSecondary100,
    secondary99: MD3Tokens.MdRefPaletteSecondary99,
    secondary95: MD3Tokens.MdRefPaletteSecondary95,
    secondary90: MD3Tokens.MdRefPaletteSecondary90,
    secondary80: MD3Tokens.MdRefPaletteSecondary80,
    secondary70: MD3Tokens.MdRefPaletteSecondary70,
    secondary60: MD3Tokens.MdRefPaletteSecondary60,
    secondary50: MD3Tokens.MdRefPaletteSecondary50,
    secondary40: MD3Tokens.MdRefPaletteSecondary40,
    secondary30: MD3Tokens.MdRefPaletteSecondary30,
    secondary20: MD3Tokens.MdRefPaletteSecondary20,
    secondary10: MD3Tokens.MdRefPaletteSecondary10,
    secondary0: MD3Tokens.MdRefPaletteSecondary0,
    tertiary100: MD3Tokens.MdRefPaletteTertiary100,
    tertiary99: MD3Tokens.MdRefPaletteTertiary99,
    tertiary95: MD3Tokens.MdRefPaletteTertiary95,
    tertiary90: MD3Tokens.MdRefPaletteTertiary90,
    tertiary80: MD3Tokens.MdRefPaletteTertiary80,
    tertiary70: MD3Tokens.MdRefPaletteTertiary70,
    tertiary60: MD3Tokens.MdRefPaletteTertiary60,
    tertiary50: MD3Tokens.MdRefPaletteTertiary50,
    tertiary40: MD3Tokens.MdRefPaletteTertiary40,
    tertiary30: MD3Tokens.MdRefPaletteTertiary30,
    tertiary20: MD3Tokens.MdRefPaletteTertiary20,
    tertiary10: MD3Tokens.MdRefPaletteTertiary10,
    tertiary0: MD3Tokens.MdRefPaletteTertiary0,
    neutral100: MD3Tokens.MdRefPaletteNeutral100,
    neutral99: MD3Tokens.MdRefPaletteNeutral99,
    neutral95: MD3Tokens.MdRefPaletteNeutral95,
    neutral90: MD3Tokens.MdRefPaletteNeutral90,
    neutral80: MD3Tokens.MdRefPaletteNeutral80,
    neutral70: MD3Tokens.MdRefPaletteNeutral70,
    neutral60: MD3Tokens.MdRefPaletteNeutral60,
    neutral50: MD3Tokens.MdRefPaletteNeutral50,
    neutral40: MD3Tokens.MdRefPaletteNeutral40,
    neutral30: MD3Tokens.MdRefPaletteNeutral30,
    neutral20: MD3Tokens.MdRefPaletteNeutral20,
    neutral10: MD3Tokens.MdRefPaletteNeutral10,
    neutral0: MD3Tokens.MdRefPaletteNeutral0,
    neutralVariant100: MD3Tokens.MdRefPaletteNeutralVariant100,
    neutralVariant99: MD3Tokens.MdRefPaletteNeutralVariant99,
    neutralVariant95: MD3Tokens.MdRefPaletteNeutralVariant95,
    neutralVariant90: MD3Tokens.MdRefPaletteNeutralVariant90,
    neutralVariant80: MD3Tokens.MdRefPaletteNeutralVariant80,
    neutralVariant70: MD3Tokens.MdRefPaletteNeutralVariant70,
    neutralVariant60: MD3Tokens.MdRefPaletteNeutralVariant60,
    neutralVariant50: MD3Tokens.MdRefPaletteNeutralVariant50,
    neutralVariant40: MD3Tokens.MdRefPaletteNeutralVariant40,
    neutralVariant30: MD3Tokens.MdRefPaletteNeutralVariant30,
    neutralVariant20: MD3Tokens.MdRefPaletteNeutralVariant20,
    neutralVariant10: MD3Tokens.MdRefPaletteNeutralVariant10,
    neutralVariant0: MD3Tokens.MdRefPaletteNeutralVariant0,
    error100: MD3Tokens.MdRefPaletteError100,
    error99: MD3Tokens.MdRefPaletteError99,
    error95: MD3Tokens.MdRefPaletteError95,
    error90: MD3Tokens.MdRefPaletteError90,
    error80: MD3Tokens.MdRefPaletteError80,
    error70: MD3Tokens.MdRefPaletteError70,
    error60: MD3Tokens.MdRefPaletteError60,
    error50: MD3Tokens.MdRefPaletteError50,
    error40: MD3Tokens.MdRefPaletteError40,
    error30: MD3Tokens.MdRefPaletteError30,
    error20: MD3Tokens.MdRefPaletteError20,
    error10: MD3Tokens.MdRefPaletteError10,
    error0: MD3Tokens.MdRefPaletteError0,
    onPrimary: MD3Tokens.MdSysColorOnPrimary,
  },

  typeface: {
    brandRegular: Platform.select({
      web: 'Adopekid-Kanit-RC-Stencil',
      ios: 'Adopekid-Kanit-RC-Stencil',
      default: 'Adopekid-Kanit-RC-Stencil',
    }),
    weightRegular: '400',
    plainMedium: Platform.select({
      web: 'Lexend Deca',
      ios: 'Lexend Deca',
      default: 'Lexend Deca',
    }),
    plainBody: Platform.select({
      web: 'NunitoSans-Regular',
      ios: 'NunitoSans-Regular',
      default: 'NunitoSans-Regular',
    }),
    weightMedium: '500',
  },

  opacity: {
    level1: 0.08,
    level2: 0.12,
    level3: 0.16,
    level4: 0.38,
  },
}

const brandType = {
  fontFamily: ref.typeface.brandRegular,
  fontWeight: ref.typeface.weightRegular,
}

const mediumType = {
  fontFamily: ref.typeface.plainMedium,
  letterSpacing: 0.15,
  fontWeight: ref.typeface.weightMedium,
}

const body1Type = {
  fontFamily: ref.typeface.plainBody,
  fontWeight: ref.typeface.weightRegular,
}

export const typescale = {
  displayLarge: {
    ...mediumType,
    lineHeight: 64,
    fontSize: 57,
  },
  displayMedium: {
    ...mediumType,
    lineHeight: 52,
    fontSize: 45,
  },
  displaySmall: {
    ...mediumType,
    lineHeight: 44,
    fontSize: 36,
  },
  // Frozen
  headlineLarge: {
    ...mediumType,
    lineHeight: 40,
    fontSize: 32,
    fontWeight: 700,
  },
  headlineMedium: {
    ...mediumType,
    lineHeight: 36,
    fontSize: 28,
  },
  headlineSmall: {
    ...mediumType,
    lineHeight: 32,
    fontSize: 24,
    fontWeight: 500,
  },

  titleLarge: {
    ...mediumType,
    lineHeight: 28,
    fontSize: 22,
  },
  // Frozen
  titleMedium: {
    ...mediumType,
    lineHeight: 24,
    fontSize: 16,
  },
  titleSmall: {
    ...mediumType,
    letterSpacing: 0.1,
    lineHeight: 20,
    fontSize: 14,
  },

  // Frozen
  labelLarge: {
    ...mediumType,
    letterSpacing: 0.1,
    lineHeight: 20,
    fontSize: 14,
  },
  labelMedium: {
    ...mediumType,
    letterSpacing: 0.5,
    lineHeight: 16,
    fontSize: 12,
  },
  labelSmall: {
    ...mediumType,
    letterSpacing: 0.5,
    lineHeight: 16,
    fontSize: 11,
  },
  labelUltraSmall: {
    ...mediumType,
    letterSpacing: 0.5,
    lineHeight: 16,
    fontSize: 9,
  },
  bodyLarge: {
    ...mediumType,
    fontWeight: ref.typeface.weightRegular,
    // fontFamily: ref.typeface.brandRegular,
    lineHeight: 24,
    fontSize: 16,
  },
  bodyMedium: {
    ...mediumType,
    fontWeight: ref.typeface.weightRegular,
    // fontFamily: ref.typeface.brandRegular,
    letterSpacing: 0.25,
    lineHeight: 20,
    fontSize: 14,
  },
  bodySmall: {
    ...mediumType,
    fontWeight: ref.typeface.weightRegular,
    // fontFamily: ref.typeface.brandRegular,
    letterSpacing: 0.4,
    lineHeight: 16,
    fontSize: 12,
  },
  compactLarge: {
    ...body1Type,
    fontWeight: ref.typeface.weightRegular,
    letterSpacing: 0.25,
    lineHeight: 27,
    fontSize: 20,
  },
  compactMedium: {
    ...body1Type,
    fontWeight: ref.typeface.weightRegular,
    letterSpacing: 0.25,
    lineHeight: 20,
    fontSize: 14,
  },

  brandtTitleLarge: {
    ...brandType,
    lineHeight: 30,
    fontSize: 32,
    fontWeight: 600,
  },
  brandBodyLarge: {
    ...brandType,
    lineHeight: 24,
    fontSize: 20,
    fontWeight: 600,
  },
  subTitles: {
    ...mediumType,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 15,
    lineHeight: 19,
  },
}

export const tokens = {
  md: {
    ref,
    sys: {
      typescale,
    },
  },
}

export const MD3Colors = ref.palette
