import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgHomeIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 26 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M9.192 25.71v-4.09c0-1.036.842-1.878 1.886-1.884h3.832c1.048 0 1.898.844 1.898 1.884v4.078c0 .898.73 1.629 1.636 1.635h2.614a4.614 4.614 0 0 0 3.258-1.332 4.547 4.547 0 0 0 1.35-3.23V11.153c0-.979-.437-1.908-1.193-2.536L15.59 1.566a4.154 4.154 0 0 0-5.277.095L1.623 8.618a3.299 3.299 0 0 0-1.29 2.537v11.603c0 2.527 2.063 4.575 4.608 4.575h2.555c.436.004.855-.166 1.165-.471.309-.305.483-.72.483-1.152h.048Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgHomeIcon
