import { Icon } from '@nowon/icons'
import React from 'react'
import {
  Button,
  Modal,
  Portal,
  Provider,
  Surface,
  Text,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'

const RedeemInfoCard = (props) => {
  const {
    isVisible,
    onClose = () => {},
    colors,
    isInSufficient,
    redeemInfo,
  } = props
  const { t } = useTranslation()
  return (
    <Provider>
      <Portal>
        <Modal
          visible={isVisible}
          onDismiss={onClose}
          contentContainerStyle={{
            width: 300,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <Surface
            style={{
              height: 48,
              width: 48,
              backgroundColor: '#FFE9E8',
              borderRadius: 24,
              justifyContent: 'center',
              alignItems: 'center',
              marginVertical: 16,
            }}
            elevation={0}
          >
            <Icon name='ExclamatoryIcon' />
          </Surface>
          <Text
            variant='headlineSmall'
            style={{ color: 'black', marginBottom: 12, textAlign: 'center' }}
          >
            {isInSufficient ? t('INSUFFICIENT_CARD_TITLE') : t('CONGRATS')}
          </Text>
          <Text
            variant='labelLarge'
            style={{
              color: 'black',
              marginBottom: 27,
              textAlign: 'center',
              width: 245,
            }}
          >
            {isInSufficient ? t('INSUFFICIENT_CARD_CONTENT') : t(redeemInfo)}
          </Text>
          <Button
            mode='outlined'
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: colors.primary,
              marginBottom: 25,
              height: 32,
              width: 107,
            }}
            labelStyle={{
              color: colors.neutral,
            }}
            onPress={onClose}
          >
            {t('ACTIONS.OK')}
          </Button>
        </Modal>
      </Portal>
    </Provider>
  )
}

export default RedeemInfoCard
