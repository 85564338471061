import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgInstagramLogo = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M9 6.768a2.232 2.232 0 1 0 0 4.464 2.232 2.232 0 0 0 0-4.464Zm8.937-2.205a5.876 5.876 0 0 0-.378-2.034A3.6 3.6 0 0 0 15.471.441a5.877 5.877 0 0 0-2.034-.378C12.276 0 11.934 0 9 0S5.724 0 4.563.063a5.877 5.877 0 0 0-2.034.378A3.6 3.6 0 0 0 .441 2.529a5.877 5.877 0 0 0-.378 2.034C0 5.724 0 6.066 0 9s0 3.276.063 4.437c.01.697.137 1.388.378 2.043.18.474.46.903.819 1.26a3.5 3.5 0 0 0 1.269.819c.65.245 1.339.373 2.034.378C5.724 18 6.066 18 9 18s3.276 0 4.437-.063a5.876 5.876 0 0 0 2.034-.378c.477-.177.91-.457 1.269-.819.36-.357.64-.786.819-1.26a5.941 5.941 0 0 0 .378-2.043C18 12.276 18 11.934 18 9s0-3.276-.063-4.437Zm-2.286 7.2a5.157 5.157 0 0 1-.351 1.62 3.474 3.474 0 0 1-1.917 1.917 5.158 5.158 0 0 1-1.629.315H6.246a5.158 5.158 0 0 1-1.629-.315 3.159 3.159 0 0 1-1.179-.774 3.16 3.16 0 0 1-.738-1.143 4.94 4.94 0 0 1-.306-1.629V6.246c.01-.556.113-1.107.306-1.629.166-.446.43-.85.774-1.179A3.231 3.231 0 0 1 4.617 2.7a5.157 5.157 0 0 1 1.629-.315h5.508a5.157 5.157 0 0 1 1.629.315c.446.166.85.43 1.179.774.326.323.578.713.738 1.143.192.522.299 1.073.315 1.629V9c0 1.854.063 2.043.036 2.754v.009Zm-1.44-6.696a2.142 2.142 0 0 0-1.269-1.269A3.6 3.6 0 0 0 11.7 3.6H6.3a3.6 3.6 0 0 0-1.242.234 2.142 2.142 0 0 0-1.269 1.224c-.13.4-.195.82-.189 1.242v5.4c.009.424.088.844.234 1.242a2.142 2.142 0 0 0 1.269 1.269c.384.141.788.22 1.197.234h5.4a3.602 3.602 0 0 0 1.242-.234 2.143 2.143 0 0 0 1.269-1.269c.15-.397.23-.817.234-1.242V6.3c0-.425-.08-.846-.234-1.242v.009ZM9 12.438A3.429 3.429 0 0 1 5.571 9 3.438 3.438 0 1 1 9 12.438Zm3.6-6.282a.81.81 0 0 1-.517-.264c-.134-.148-.208-.26-.208-.46a.81.81 0 0 1 .725-.806.81.81 0 0 1 .725.805c0 .2-.074.313-.208.461a.81.81 0 0 1-.517.264Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgInstagramLogo
