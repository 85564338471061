import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgCloseIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path d='M.747 20.546 20.547.747M1 1l19.799 19.799' stroke={props.color} />
  </Svg>
)

export default SvgCloseIcon
