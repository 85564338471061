import React, { useEffect, useState } from 'react'
import { Surface, Text, Checkbox, Button, useTheme } from 'react-native-paper'
import { View, Linking } from 'react-native'
import {
  EmailTextInput,
  PasswordTextInput,
  SocialButton,
} from '@nowon/components'
import { useIsFocused } from '@react-navigation/native'
import { BackgroundContainer } from '@nowon/container'
import BlurWidget from '@nowon/components/src/Blur'
import useRegister from './useRegister'
import { setItemAsync } from '../../utils/SecureStore'
import { privacyPolicyPage } from '../../config'

export const Register = (props) => {
  const { navigation, t } = props
  const { colors } = useTheme()
  const isFocus = useIsFocused()
  const [isNotChecked, setIsNotChecked] = useState(false)
  const referralCode = props?.route?.params?.referralCode

  useEffect(() => {
    if (!referralCode) return
    ;(async () => {
      await setItemAsync('referralCode', referralCode)
    })()
  }, [referralCode])

  const {
    privacyChecked,
    setPrivacyChecked,
    signUp,
    email,
    password,
    setEmail,
    setPassword,
    googleSignIn,
    setErrorMsg,
    errorMsg,
    setReferralCode,
  } = useRegister()

  useEffect(() => {
    setReferralCode(referralCode)
    if (isFocus === false) {
      setEmail('')
      setPassword('')
    }
  }, [isFocus])

  return (
    <BackgroundContainer>
      <>
        <BlurWidget borderRadius={16} />
        <View style={{ gap: '12px' }}>
          <Text variant='headlineLarge' style={{ color: colors.primary }}>
            {t('REGISTER')}
          </Text>
          <Text
            variant='titleMedium'
            style={{
              color: colors.neutralLight,
            }}
          >
            {t('SIGN_UP_WITH')}
          </Text>
          <SocialButton
            onPress={() => googleSignIn()}
            iconName='GoogleIcon'
            label={t('SIGN_UP_GOOGLE')}
          />
          <Text
            variant='titleMedium'
            style={{
              color: colors.neutralLight,
            }}
          >
            {t('WITH_EMAIL')}
          </Text>

          <EmailTextInput
            mode='outlined'
            placeholder={t('EMAIL_ID')}
            style={{
              backgroundColor: colors.neutralLight,
              width: '100%',
            }}
            value={email}
            onFocus={() => setErrorMsg('')}
            onChangeText={(text) => setEmail(text.toLowerCase().trim())}
            onValidate={(isValid) => {
              console.log({ isValid })
            }}
            errorMessage={t('EMAIL_INVALID')}
          />

          <View style={{ width: '100%' }}>
            <PasswordTextInput
              mode='outlined'
              placeholder={t('PASSWORD')}
              style={{
                backgroundColor: colors.neutralLight,
                width: '100%',
              }}
              onFocus={() => setErrorMsg('')}
              value={password}
              onChangeText={(text) => setPassword(text)}
              autoComplete='new-password'
              // onValidate={(isValid) => {
              //   console.log({ isValid })
              // }}
              errorMessage={t('PASSWORD_VALIDATION')}
            />
          </View>
          {errorMsg && <Text style={{ color: 'red' }}>{errorMsg}</Text>}
          {isNotChecked && (
            <Text style={{ color: 'red' }}>{t('PRIVACY_POLICY_ERROR')}</Text>
          )}
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Checkbox
              status={privacyChecked ? 'checked' : 'unchecked'}
              onPress={() => {
                setPrivacyChecked(!privacyChecked)
              }}
            />
            <Text
              variant='titleMedium'
              style={{
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: 400,
                color: colors.neutralLight,
                width: '100%',
              }}
            >
              {t('BY_CLICKING')}
              <Text
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 400,
                  textDecorationLine: 'Underline',
                  marginLeft: '2px',
                  color: colors.neutralLight,
                }}
                onPress={() => Linking.openURL(privacyPolicyPage)}
              >
                {t('TERMS_CONDITION')}
              </Text>{' '}
              {t('USE')}
            </Text>
          </View>
          <Button
            mode='contained'
            contentStyle={{ height: 48, backgroundColor: '#6EC1E4' }}
            style={{
              borderRadius: 8,
              width: '100%',
            }}
            labelStyle={{
              color: colors.neutralLight,
              fontSize: '16px',
              fontWeight: 400,
            }}
            onPress={() => {
              if (privacyChecked) {
                setReferralCode(referralCode)
                signUp()
                setIsNotChecked(false)
              } else {
                setIsNotChecked(true)
              }
            }}
          >
            {t('ACTIONS.SIGN_UP')}
          </Button>
          <Surface
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
            elevation={0}
          >
            <Text
              variant='labelLarge'
              style={{
                color: '#509AC4',
                cursor: 'pointer',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 18,
                lineHeight: 18,
              }}
              onPress={() => {
                navigation.navigate('login')
              }}
            >
              {' '}
              {t('LOGIN')}
            </Text>
          </Surface>
        </View>
      </>
    </BackgroundContainer>
  )
}
