import React from 'react'
import {
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  Dimensions,
} from 'react-native'
import { Text } from '@nowon/components'
import { Icon } from '@nowon/icons'
import RapCitySmallLogo from 'core/src/assets/rapCitySmallLogo.png'
import { saveAs } from 'file-saver'
import { RWebShare } from 'react-web-share'

const MemoriesPopup = (props) => {
  const { modalVisible, setModalVisible, modalImage, setImageArrayState } =
    props
  let { height } = Dimensions.get('window')
  setImageArrayState(false)
  height -= 240
  const download = () => {
    console.log('click')
    saveAs(modalImage.url, modalImage.name)
  }
  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        zIndex: modalVisible ? 100 : -1,
        backgroundColor: 'rgb(22, 24, 31)',
        maxWidth: 400,
        width: '100%',
      }}
    >
      <View style={styles.centeredView}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'rgb(22, 24, 31)',
            paddingBottom: 18,
          }}
        >
          <Image
            source={RapCitySmallLogo}
            style={{ resizeMode: 'contain', width: 32, height: 29 }}
          />
          <View style={{ marginLeft: 'auto' }}>
            <TouchableOpacity
              onPress={() => {
                setModalVisible(!modalVisible)
                setImageArrayState(true)
              }}
              style={{
                width: 32,
                height: 32,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 50,
              }}
            >
              <Icon name='CloseIcon' color='white' width={14} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ gap: 27 }}>
          <Image
            source={modalImage.url}
            style={{
              width: '100%',
              height,
              borderRadius: 15,
            }}
            resizeMode='contain'
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TouchableOpacity
              style={{
                background: '#FFFFFF',
                height: 32,
                width: 32,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => download()}
            >
              <Icon name='DownloadIcon' color='#222222' width={14} />
            </TouchableOpacity>
            <RWebShare
              data={{
                text: 'Schau dir meine Rap City Fotobooth Bilder an!',
                url: `${modalImage.url}`,
                title: 'Schau dir meine Rap City Fotobooth Bilder an!',
              }}
            >
              <View
                style={{
                  backgroundColor: '#fff',
                  width: 100,
                  height: 32,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 24,
                  marginLeft: 'auto',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                  <Icon name='ShareIcon' color='black' width={16} />
                  <Text
                    variant='bodyMedium'
                    style={{ color: '#000', marginTop: 2 }}
                  >
                    Share
                  </Text>
                </View>
              </View>
            </RWebShare>
          </View>
        </View>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    backgroundColor: 'rgb(22, 24, 31)',
  },
})

export default MemoriesPopup
