import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgGoogleIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.208 12.194c0-.606-.054-1.189-.155-1.749H12v3.308h4.602a3.934 3.934 0 0 1-1.707 2.58v2.146h2.764c1.616-1.489 2.549-3.68 2.549-6.285Z'
      fill='#4285F4'
    />
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 20.55c2.309 0 4.244-.766 5.659-2.071l-2.764-2.146c-.765.513-1.745.816-2.895.816-2.227 0-4.112-1.504-4.784-3.525H4.359v2.216A8.547 8.547 0 0 0 12 20.55Z'
      fill='#34A853'
    />
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.216 13.624A5.14 5.14 0 0 1 6.948 12c0-.563.097-1.111.268-1.624V8.16H4.359A8.546 8.546 0 0 0 3.45 12c0 1.38.33 2.685.91 3.84l2.856-2.216Z'
      fill='#FBBC05'
    />
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 6.85c1.255 0 2.382.432 3.268 1.28l2.453-2.453C16.24 4.297 14.305 3.45 12 3.45a8.547 8.547 0 0 0-7.64 4.71l2.856 2.216C7.888 8.355 9.773 6.85 12 6.85Z'
      fill='#EA4335'
    />
  </Svg>
)

export default SvgGoogleIcon
