import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgAlertIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 12 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M6 13.667c.732 0 1.332-.6 1.332-1.334H4.666c0 .734.593 1.334 1.333 1.334Zm4-4V6.333c0-2.046-1.094-3.76-3-4.213v-.453c0-.554-.448-1-1-1-.554 0-1 .446-1 1v.453c-1.914.453-3 2.16-3 4.213v3.334l-.86.86c-.42.42-.128 1.14.466 1.14h8.78c.593 0 .893-.72.473-1.14l-.86-.86Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgAlertIcon
