/* eslint-disable no-nested-ternary */
import { View, Image } from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { FAB, Surface, Text } from 'react-native-paper'
import React, { useEffect, useState } from 'react'
import { Container } from '@nowon/container'
import { Icon } from '@nowon/icons'
import { useIsFocused } from '@react-navigation/native'
import { getAllEvents } from '../../apiGraphql'
import Logo from '../../assets/logosmallblack.png'
import { getCurrentDate } from '../../utils/formateDate'

export const Events = (props) => {
  const { colors, t, navigation } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const isFocus = useIsFocused()
  const time = new Date()
  let timer
  const { data: eventsData } = useQuery(
    ['getAllEvents'],
    async () => {
      const response = await getAllEvents()
      return response
    },
    {
      initialData: [],
    },
  )

  function getCurrentTime() {
    const hours = time.getHours()
    const todayDate = getCurrentDate()
    const activeEvent = eventsData.findIndex(
      (item) => item?.startTime?.hours <= hours && item?.endTime?.hours > hours,
    )
    if (
      activeEvent !== activeIndex &&
      eventsData[activeEvent]?.eventDate === todayDate
    )
      setActiveIndex(activeEvent + 1)
    timer = setTimeout(() => getCurrentTime(), 1000)
  }

  useEffect(() => {
    if (!eventsData) return
    getCurrentTime()
    return () => {
      clearTimeout(timer)
    }
  }, [isFocus, eventsData])

  return (
    <Container>
      <Text
        variant='headlineLarge'
        style={{ color: colors.primary, marginBottom: 24 }}
      >
        {t('TIME_TABLE')}
      </Text>
      <Surface
        elevation={0}
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Text
          style={{ color: colors.textPrimary, marginBottom: 24, fontSize: 18 }}
        >
          {eventsData?.[0]?.eventDate}
        </Text>
      </Surface>
      {eventsData.map((eD, index) => (
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ position: 'relative', alignItems: 'center' }}>
            <View
              style={{
                width: 24,
                height: 24,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 50,
              }}
            >
              <View
                style={{
                  width: 14,
                  height: 14,
                  backgroundColor:
                    activeIndex === index + 1
                      ? 'rgba(110, 193, 228, 1)'
                      : 'transparent',
                  borderWidth: 1,
                  borderColor:
                    activeIndex < index + 1
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'transparent',
                  borderRadius: 50,
                }}
              >
                {activeIndex > index + 1 && (
                  <Icon
                    name='TickIcon'
                    width={12}
                    height={15}
                    color={colors.primary}
                  />
                )}
              </View>
            </View>

            {eventsData?.length !== index + 1 && (
              <View
                style={{
                  width: 1,
                  height: '80%',
                  borderWidth: 1,
                  borderStyle: 'dashed',
                  top: 24,
                  position: 'absolute',
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                }}
              />
            )}
          </View>

          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text style={{ color: colors.neutralLight }}>
              {`${eD?.startTime?.hours}:${eD?.startTime?.minutes
                .toString()
                .padStart(2, '0')}-${eD?.endTime?.hours}:${eD?.endTime?.minutes
                .toString()
                .padStart(2, '0')}`}
            </Text>
            <View
              style={{
                padding: 16,
                backgroundColor:
                  activeIndex === index + 1
                    ? '#3775A4'
                    : activeIndex > index + 1
                    ? 'rgba(110, 228, 228, 0.1)'
                    : 'rgba(255, 255, 255, 0.1)',
                borderRadius: 12,
                flexDirection: 'row',
                marginTop: 12,
                marginBottom: 24,
                width: '100%',
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  backgroundColor: '#fff',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 8,
                }}
              >
                <Image
                  source={Logo}
                  style={{
                    height: 22,
                    width: 25,
                  }}
                />
              </View>
              <View
                style={{
                  marginLeft: 15,
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: '#fff',
                    fontSize: 20,
                    marginBottom: 10,
                    fontFamily: 'Adopekid-Kanit-RC-Stencil',
                  }}
                >
                  {eD?.eventName}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* <Text variant='labelLarge' style={{ color: '#FFFFFF' }}>
                    {eD?.eventDuration}
                  </Text> */}
                  {eD?.eventInfo && (
                    <View
                      style={{
                        backgroundColor: '#A1B4FF',
                        borderRadius: 4,
                        height: 19,
                        width: 110,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text variant='labelLarge' style={{ color: '#FFFFFF' }}>
                        {eD?.eventInfo}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      ))}
      <FAB
        icon='line-scan'
        style={{
          position: 'fixed',
          right: 24,
          bottom: 24,
          height: 40,
          borderRadius: 50,
          backgroundColor: colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        color='#FFFFFF'
        onPress={() => navigation.navigate('scanner')}
        label='Scan'
      />
    </Container>
  )
}
