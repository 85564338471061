import { View, TouchableOpacity } from 'react-native'
import React from 'react'
import { Surface, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { Icon } from '@nowon/icons'
import { Text } from '@nowon/components'

const CompleteProfileCard = ({ navigation, challengeData }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  return (
    <Surface
      style={{
        borderRadius: 32,
        paddingHorizontal: 24,
        paddingTop: 28,
        paddingBottom: 32,
        backgroundColor: 'rgba(161, 180, 255, 0.2)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <Text variant='brandBodyLarge' colorVariant='primaryVariant'>
          {challengeData?.title}
        </Text>

        <Text variant='bodyMedium' colorVariant='primaryVariant'>
          {`${t('POINTS')}${challengeData.totalPoints} ${t('REWARD_POINTS')}`}
        </Text>
      </View>
      <TouchableOpacity
        style={{
          width: 48,
          height: 48,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.primary,
          borderRadius: 50,
        }}
        onPress={() => navigation.navigate('onboard')}
      >
        <Icon
          name='ArrowRightIcon'
          width={27}
          height={18}
          color={colors.textPrimary}
        />
      </TouchableOpacity>
    </Surface>
  )
}

export default CompleteProfileCard
