import React, { useTransition } from 'react'
import {
  View,
  StyleSheet,
} from 'react-native'
import Modal from 'react-native-modal'
import { Button } from 'react-native-paper'
import { Text } from '@nowon/components'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  view: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  content: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: '#22242A',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    backdropFilter: 'blur(2px)',
    padding: 22,
  },
  icon: {
    fontSize: 45,
    marginBottom: 30,
  },
  contentTitle: {
    fontSize: 24,
    marginBottom: 18,
    color: '#6EC1E4',
    fontFamily: 'Lexend Deca',
  },
  contentSubtitle: {
    fontSize: 14,
    marginBottom: 18,
    fontFamily: 'Lexend Deca',
  },
  getStartedButton: {
    width: 'fit-content',
    borderColor: '#6EC1E4',
  },
  listItem: {
    fontSize: 14,
    marginBottom: 5,
  },
})

const GetStartedModal = (props) => {
  const { t } = useTranslation()
  const { showGetStarted, onClickGetStarted } = props;

  const listItem = [
    'CONTEST.RULE_24H',
    'CONTEST.RULE_PER_DAY',
    'CONTEST.RULE_END',
    'CONTEST.RULE_FAKE',
  ]

  return (
    <Modal
      isVisible={showGetStarted}
      onSwipeComplete={() => {}}
      swipeDirection={['up', 'left', 'right', 'down']}
      style={styles.view}
    >
      <View style={styles.content}>
        <Text style={styles.icon}>👋</Text>
        <Text style={styles.contentTitle}>{ t('CONTEST.ABOUT_CONTEST') }</Text>

        <Text style={styles.contentSubtitle}>
          { t('CONTEST.WELCOME') }
        </Text>

        <View style={{ marginBottom: 20 }}>
          {listItem.map((item, index) => (
            <Text
              key={`list-item-${index}`}
              style={styles.listItem}
            >{`\u2022 ${t(item)}`}</Text>
          ))}
        </View>

        <Button
          style={styles.getStartedButton}
          mode='outlined'
          onPress={onClickGetStarted}
        >
          Get Started
        </Button>
      </View>
    </Modal>
  )
}

export default GetStartedModal
