import ReactGA from 'react-ga'

class Analytics {
  static init() {}

  static logScreenView = async (currentRouteName) => {
    ReactGA.initialize('G-VD2LVF6N7G')
    ReactGA.set({ page: currentRouteName })
    ReactGA.pageview(currentRouteName)
  }

  static logEvent = (body) => {}
}

export default Analytics
