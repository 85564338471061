import React from 'react'
import { View, Image } from 'react-native'
import { Text, Button, useTheme } from 'react-native-paper'
import { Icon } from '@nowon/icons'
import { useTranslation } from 'react-i18next'

export const EmailVerificationCard = ({ navigation }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View style={{ gap: '12px', justifyContent: 'center', height: '100%' }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <View
          style={{
            width: 96,
            height: 96,
            borderRadius: 50,
            borderWidth: 1,
            borderColor: '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Icon
            name='TickIcon'
            width={54}
            height={33}
            color={colors.textPrimary}
          />
        </View>
      </View>
      <Text variant='headlineLarge' style={{ color: colors.textPrimary }}>
        {t('VERIFICATION_COMPLETER')}
      </Text>
      <Text
        variant='titleMedium'
        style={{
          color: colors.textPrimary,
          width: 300,
        }}
      >
        {t('VERIRED_EMAIL')}
      </Text>
      <Image
        source={require('../../assets/VerificationWave.png')}
        style={{
          height: '24px',
          width: '78px',
          marginBottom: '20px',
          alignSelf: 'flex-end',
        }}
      />
      <Button
        mode='contained'
        contentStyle={{ height: 48, backgroundColor: '#ffffff' }}
        style={{
          borderRadius: 8,
        }}
        labelStyle={{
          color: '#6EC1E4',
          fontSize: '16px',
          fontWeight: 400,
        }}
        onPress={() => navigation.navigate('login')}
      >
        {t('ACTIONS.GET_STARTED')}
      </Button>
    </View>
  )
}
