import React, { useState } from 'react'
import { Button, Surface, Text, TextInput, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import ToolTip from './ToolTip'

const TicketInputCard = (props) => {
  const { handleTicketSubmit = () => {}, visible, setVisible } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  const [ticketNumber, setTicketNumber] = useState('')

  return (
    <Surface
      style={{
        paddingHorizontal: 16,
        paddingVertical: 14,
        marginBottom: 24,
        position: 'relative',
      }}
    >
      <ToolTip visible={visible} />
      <Text
        variant='titleMedium'
        style={{
          fontWeight: 300,
          marginBottom: 11,
          color: 'rgba(246, 246, 246, 0.5)',
        }}
      >
        {t('ENTER_TICKET_NUMBER')}
      </Text>
      <TextInput
        placeholder={t('TICKET_NUMBER')}
        style={{
          height: 40,
          borderRadius: 4,
          backgroundColor: 'rgba(255, 251, 255, 0.1)',
          paddingRight: 17,
          marginBottom: 16,
          position: 'relative',
        }}
        onFocus={() => setVisible(false)}
        mode='outlined'
        onChange={(value) => setTicketNumber(value.target.value)}
        activeOutlineColor={colors.primary}
        outlineColor='transparent'
        placeholderTextColor='rgba(246, 246, 246, 0.5)'
        right={
          <TextInput.Icon
            icon='information-variant'
            style={{
              position: 'absolute',
              left: 10,
              bottom: -10,
              backgroundColor: '#F7F7F7',
              height: 24,
              width: 24,
            }}
            iconColor={colors.primary}
            size={20}
            onPress={() => setVisible(!visible)}
          />
        }
      />
      <Surface
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        elevation={0}
      >
        <Text
          variant='titleSmall'
          style={{ color: colors.textPrimary, marginTop: 10 }}
          onPress={() => handleTicketSubmit(ticketNumber, false)}
        >
          {t('ADD_LATER')}
        </Text>
        <Button
          mode='outlined'
          labelStyle={{
            fontSize: 14,
            fontWeight: 400,
            color: '#6EC1E4',
          }}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 23,
            borderColor: colors.primary,
          }}
          onPress={() => handleTicketSubmit(ticketNumber, true)}
        >
          {t('ACTIONS.CONTINUE')}
        </Button>
      </Surface>
    </Surface>
  )
}

export default TicketInputCard
