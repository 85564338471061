import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Text, useTheme, Surface } from 'react-native-paper'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { Container } from '@nowon/container'
import { useQuery } from '@tanstack/react-query'
import { Icon } from '@nowon/icons'
import { useTranslation } from 'react-i18next'
import { getAllEvents } from '../../apiGraphql'

export const Menu = () => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [show, setShow] = React.useState(0)
  const { t } = useTranslation()
  const isRendered = useIsFocused()

  const { data: eventsData } = useQuery(
    ['getAllEvents'],
    async () => {
      const response = await getAllEvents()
      return response.filter((r) => {
        const EventDate = new Date(r?.eventDate)
        const CurrentDate = new Date()
        return EventDate > CurrentDate
      })
    },
    {
      enabled: isRendered,
    },
    {
      initialData: [],
    },
  )
  console.log(eventsData?.length, 'events data in menu')
  const menu = [
    {
      iconName: 'HomeIcon',
      menu: t('GO_TO_HOME'),
      goTo: 'home',
    },
    {
      iconName: 'FlagIcon',
      menu: t('CHALLENGES'),
      goTo: 'challenges',
    },
    {
      iconName: 'GiftIcon',
      menu: t('TO_THE_OFFERS'),
      goTo: 'your-points',
    },
    {
      iconName: 'EventsIcon',
      menu: t('CONTEST.RAPCITY_CONTEST'),
      goTo: 'contest',
    },
    {
      iconName: 'InviteFriendsIcon',
      menu: t('INVITE_FRIENDS'),
      goTo: 'inviteFriends',
    },
    {
      iconName: 'ProfileIcon',
      menu: t('PROFILE'),
      goTo: 'profile',
    },
  ]

  if (eventsData?.length > 0) {
    console.log('inside')
    menu.splice(1, 0, {
      iconName: 'EventsIcon',
      menu: t('TIME_TABLE'),
      goTo: 'events',
    })
  }
  return (
    <Container>
      <Surface elevation={0} style={{ gap: 44 }}>
        {menu.map((item, index) => (
          <TouchableOpacity
            style={styles.contentCenter}
            onPress={() => {
              setShow(index)
              navigation.navigate(item.goTo)
            }}
          >
            <>
              <Icon
                name={item?.iconName}
                color={show === index ? colors.primary : '#5C5C5C'}
              />
              <Text
                style={{
                  flexDirection: 'row',
                  color: show === index ? colors.primary : '#5C5C5C',
                  fontFamily: 'Adopekid-Kanit-RC-Stencil',
                }}
                variant='headlineMedium'
              >
                {item.menu}
              </Text>
            </>
          </TouchableOpacity>
        ))}
      </Surface>
    </Container>
  )
}
const styles = StyleSheet.create({
  contentCenter: {
    textAlign: 'center',
    display: 'flex',
    gap: 30,
    alignItems: 'center',
    flexDirection: 'row',
  },
})
