import React, { useMemo, useState } from 'react'
import {
  StyleSheet,
  ImageBackground,
  View,
  TouchableOpacity,
  Linking,
} from 'react-native'
import { Text } from '@nowon/components'
import { Container } from '@nowon/container'
import {
  Surface,
  IconButton,
  MD3Colors,
  Button,
  Card,
} from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import ArtistBackgroundImage from '../../assets/Contest-ArtistProfileBackground.jpg'
import AudioPlayer from '../../components/Contest/AudioPlayer'
import { useSelector } from 'react-redux'
import { createVote, updateVoteTracker } from '../../apiGraphql'
import { store } from '../../store/storeProvider'
import { v4 as uuidv4 } from 'uuid'
import { Icon } from '@nowon/icons'
import { useTimer } from 'react-timer-hook'
import {
  detectTempEmail,
  getNextVoteAt,
  isVoted,
} from '../../utils/contestHelper'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  container: { paddingHorizontal: 0, paddingVertical: 0, minHeight: '90vh' },
  imageBackgroud: {
    height: '90vh',
    width: '100%',
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  surface: { gap: '22px', paddingBottom: 27 },
  breadcumView: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#CCCCCC',
  },
  subBreadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#6EC1E4',
  },
  detailView: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
  },
  artistName: {
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    fontSize: 40,
  },
  mediaPlayer: {
    height: 113,
    marginBottom: 10,
    backgroundColor:
      'linear-gradient(179.34deg, rgba(255, 255, 255, 0.1) 0.57%, rgba(255, 255, 255, 0) 110.37%)',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    rowGap: 10,
    marginBottom: 10,
  },
  socialIcon: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 8,
  },
})

export const ContestArtistDetails = (props) => {
  const { user } = props
  const { t } = useTranslation()
  const { voteTracker, selectedArtist, votedArtist, isEnded } = useSelector(
    (state) => state?.contestReducer,
  )
  const [showVotedToast, setShowVotedToast] = useState(false)
  const navigation = useNavigation()
  const [alertSnakbarVisible, setAlertSnakbarVisible] = useState(false)
  const [isLoadingVote, setIsLoadingVote] = useState(false)

  const { hours } = useTimer({
    autoStart: true,
    expiryTimestamp: getNextVoteAt(),
    onExpire: () => console.warn('onExpire called'),
  })

  const isDiabledVote = useMemo(() => {
    if (!selectedArtist) return true

    const today = new Date()
    const startDate = new Date(process.env.REACT_APP_CONTEST_START_DATE)
    const endDate = new Date(process.env.REACT_APP_CONTEST_END_DATE)

    const isVotingPeriod = startDate < today && today < endDate
    let isArtistVoted = votedArtist?.guid !== selectedArtist.guid

    if (!votedArtist) {
      isArtistVoted = false
    }

    return !isVotingPeriod || selectedArtist.isWildcard || isArtistVoted
  }, [selectedArtist, votedArtist])

  if (!selectedArtist) {
    return navigation.navigate('contestArtists')
  }

  const onVote = async () => {
    setIsLoadingVote(true)
    const isBlacklistEmail = await detectTempEmail(user.email)

    if (isBlacklistEmail) {
      setAlertSnakbarVisible(true)

      setTimeout(() => {
        setAlertSnakbarVisible(false)
      }, 5000)

      return
    }

    if (
      !selectedArtist.isWildcard &&
      (!voteTracker.latestVotedAt ||
        (voteTracker && !isVoted(voteTracker.latestVotedAt)))
    ) {
      const vote = await createVote(
        uuidv4(),
        selectedArtist.guid,
        voteTracker.id,
      )

      setShowVotedToast(true)

      setTimeout(() => {
        setShowVotedToast(false)
      }, 10000)

      if (vote) {
        store.dispatch({
          type: 'SET_VOTED_ARTIST',
          payload: selectedArtist,
        })

        await updateVoteTracker(user?.userId, new Date(), selectedArtist.guid)
      }
    }

    setIsLoadingVote(false)
  }

  const renderButtonVote = () => {
    if (isEnded) {
      return <></>
    }

    if (!!votedArtist && selectedArtist.guid === votedArtist.guid) {
      return (
        <Button
          mode='contained'
          buttonColor='#FFFFFF'
          textColor='#FF4C77'
          contentStyle={{ height: 54 }}
          style={{ borderRadius: 4 }}
        >
          Voted
        </Button>
      )
    } else if (selectedArtist.isWildcard) {
      return (
        <Button
          icon='heart'
          mode='contained'
          buttonColor='#FF4C77'
          textColor='#FFFFFF'
          contentStyle={{ height: 54 }}
          style={{ borderRadius: 4 }}
          onPress={onVote}
          disabled={true}
        >
          Wildcard Winner
        </Button>
      )
    } else {
      return (
        <Button
          icon='heart'
          mode='contained'
          buttonColor='#FF4C77'
          textColor='#FFFFFF'
          contentStyle={{ height: 54 }}
          style={{ borderRadius: 4 }}
          onPress={onVote}
          disabled={isLoadingVote || isDiabledVote}
        >
          Vote
        </Button>
      )
    }
  }

  return (
    <Container style={styles.container}>
      <Surface elevation={0} style={styles.surface}>
        <ImageBackground
          source={ArtistBackgroundImage}
          style={styles.imageBackgroud}
          resizeMode='cover'
        >
          <View style={styles.breadcumView}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('contest')
              }}
            >
              <Text style={styles.breadcum}>
                {t('CONTEST.RAPCITY_CONTEST')} &gt;
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                navigation.navigate('contestArtists')
              }}
            >
              <Text style={styles.breadcum}> Artist &gt;</Text>
            </TouchableOpacity>

            <Text style={styles.subBreadcum}> {selectedArtist?.name}</Text>
          </View>
          <View style={styles.detailView}>
            <View>
              <Text style={styles.artistName}>{selectedArtist?.name}</Text>
            </View>

            <View style={{ marginBottom: 5 }}>
              <AudioPlayer selectedArtist={selectedArtist} />
            </View>

            {alertSnakbarVisible && (
              <Card
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 1)',
                  marginBottom: 8,
                }}
              >
                <Card.Title
                  title={t('INVALID_EMAIL')}
                  style={{
                    fontSize: 15,
                    fontFamily: 'Lexend Deca',
                    color: 'red',
                  }}
                />
              </Card>
            )}

            {showVotedToast ? (
              <Card
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  marginBottom: 8,
                }}
              >
                <Card.Title
                  title={t('CONTEST.VOTE_POSTED')}
                  style={{
                    fontSize: 15,
                    fontFamily: 'Lexend Deca',
                    color: '#FFFFFF',
                  }}
                  subtitle={t('CONTEST.NEXT_VOTING', { time: hours })}
                  subtitleStyle={{
                    fontSize: 12,
                    fontFamily: 'Lexend Deca',
                    color: '#CCCCCC',
                  }}
                  left={() => (
                    <Icon
                      name='ThumbsUpIcon'
                      height={24}
                      width={24}
                      color='#EDA600'
                    />
                  )}
                />
              </Card>
            ) : (
              <View style={styles.socialIcons}>
                {selectedArtist.youtubeUrl && (
                  <IconButton
                    style={styles.socialIcon}
                    icon='youtube'
                    iconColor={MD3Colors.neutral80}
                    size={20}
                    onPress={() => Linking.openURL(selectedArtist.youtubeUrl)}
                  />
                )}
                {selectedArtist.spotifyUrl && (
                  <IconButton
                    style={styles.socialIcon}
                    icon='spotify'
                    iconColor={MD3Colors.neutral80}
                    size={20}
                    onPress={() => Linking.openURL(selectedArtist.spotifyUrl)}
                  />
                )}
                {selectedArtist.instagramUrl && (
                  <IconButton
                    style={styles.socialIcon}
                    icon='instagram'
                    iconColor={MD3Colors.neutral80}
                    size={20}
                    onPress={() => Linking.openURL(selectedArtist.instagramUrl)}
                  />
                )}
                {selectedArtist.website && (
                  <IconButton
                    style={styles.socialIcon}
                    icon='link'
                    iconColor={MD3Colors.neutral80}
                    size={20}
                    onPress={() => Linking.openURL(selectedArtist.website)}
                  />
                )}
              </View>
            )}

            <View>{renderButtonVote()}</View>
          </View>
        </ImageBackground>
      </Surface>
    </Container>
  )
}
