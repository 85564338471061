import { View, TouchableOpacity, Platform } from 'react-native'
import React from 'react'
import { Surface, Text } from 'react-native-paper'
import { Icon } from '@nowon/icons'
import ImageSlider from '../../components/ImageSlider/ImageSlider'

export const FestiveMap = (props) => {
  const { navigation, colors, t } = props

  const handleBackNavigation = () => {
    if (Platform.OS === 'web') {
      window.history.go(-1)
    } else {
      navigation.goBack()
    }
  }
  const images = [
    'https://nowon-assets.s3.eu-west-1.amazonaws.com/mapImage1.jpeg',
    'https://nowon-assets.s3.eu-west-1.amazonaws.com/mapImage2.jpeg',
  ]
  return (
    <Surface elevation={0} style={{ backgroundColor: '#16181F' }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 22,
        }}
      >
        <Text
          variant='headlineLarge'
          style={{ color: colors.primary, paddingVertical: 20 }}
        >
          {t('FESTIVAL_MAP')}
        </Text>
        <TouchableOpacity
          onPress={handleBackNavigation}
          style={{
            width: 32,
            height: 32,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 50,
          }}
        >
          <Icon name='CloseIcon' color='white' width={14} />
        </TouchableOpacity>
      </View>
      <ImageSlider images={images} />
    </Surface>
  )
}
