import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgDnaIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 17 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M9.468 6.941c.27.175.327.965.334 1.054l.006.1H8.15a.542.542 0 0 1 .045.074.826.826 0 0 1 .033.594c-.108.375-.419.617-.692.538a.42.42 0 0 1-.158-.09.477.477 0 0 1-.113-.152.744.744 0 0 1-.072-.328c0-.093.013-.184.039-.272.097-.336.357-.564.606-.551h1.44a4.413 4.413 0 0 0-.053-.348 1.093 1.093 0 0 0-.119-.37c-.054-.09-.155-.034-.163-.03a.996.996 0 0 0-.164.152.078.078 0 0 1-.055.027.077.077 0 0 1-.057-.024.098.098 0 0 1-.01-.125c.02-.018.416-.505.811-.249Z'
      fill='#fff'
    />
    <Path
      d='M9.468 5.308c.27.175.327.965.334 1.054l.006.1H8.15a.542.542 0 0 1 .045.074.826.826 0 0 1 .033.594c-.108.375-.419.617-.692.538a.42.42 0 0 1-.158-.09.477.477 0 0 1-.113-.152.744.744 0 0 1-.072-.328c0-.093.013-.184.039-.272.097-.336.357-.564.606-.551h1.44a4.413 4.413 0 0 0-.053-.348 1.093 1.093 0 0 0-.119-.37c-.054-.09-.155-.034-.163-.03a.997.997 0 0 0-.164.152.078.078 0 0 1-.055.027.077.077 0 0 1-.057-.023.098.098 0 0 1-.01-.125c.02-.02.416-.506.811-.25ZM9.468 15.922c.27.175.327.965.334 1.054l.006.1H8.15a.547.547 0 0 1 .045.074.827.827 0 0 1 .033.594c-.108.375-.419.616-.692.538a.42.42 0 0 1-.158-.09.478.478 0 0 1-.113-.152.744.744 0 0 1-.072-.329c0-.092.013-.183.039-.27.097-.338.357-.565.606-.552h1.44a4.408 4.408 0 0 0-.053-.348 1.093 1.093 0 0 0-.119-.37c-.054-.09-.155-.034-.163-.03a.997.997 0 0 0-.164.151.078.078 0 0 1-.055.027.077.077 0 0 1-.057-.023.098.098 0 0 1-.01-.125c.02-.019.416-.506.811-.249Z'
      fill='#fff'
    />
    <Path
      d='M9.468 14.29c.27.174.327.964.334 1.053l.006.1H8.15a.542.542 0 0 1 .045.074.826.826 0 0 1 .033.594c-.108.375-.419.616-.692.538a.422.422 0 0 1-.158-.09.478.478 0 0 1-.113-.152.744.744 0 0 1-.072-.329c0-.092.013-.183.039-.27.097-.338.357-.565.606-.552h1.44a4.402 4.402 0 0 0-.053-.348 1.092 1.092 0 0 0-.119-.37c-.054-.09-.155-.034-.163-.03a.997.997 0 0 0-.164.152.078.078 0 0 1-.055.026.077.077 0 0 1-.057-.023.098.098 0 0 1-.01-.125c.02-.019.416-.506.811-.249Z'
      fill='#fff'
    />
    <Path
      d='M4.9 16.223c-.008-.316.05-.63.167-.91s.29-.517.502-.69l2.202-1.82-.797-.574-1.856 1.533a2.738 2.738 0 0 0-.776 1.069 3.46 3.46 0 0 0-.261 1.406c.01.488.121.966.322 1.386.2.42.483.77.821 1.014l1.86 1.34.762-.629-2.202-1.587a1.695 1.695 0 0 1-.532-.649 2.19 2.19 0 0 1-.211-.89Z'
      fill='#fff'
    />
    <Path
      d='M3.967 6.654a3.472 3.472 0 0 0 .154 1.724c.195.541.52.991.932 1.287l6.497 4.684c.17.12.319.283.437.477s.204.416.251.653c.048.236.057.482.026.723-.03.241-.1.472-.203.678a1.704 1.704 0 0 1-.464.574L8.353 20.12a.529.529 0 0 0-.176.324.618.618 0 0 0 .059.385.42.42 0 0 0 .26.22.344.344 0 0 0 .307-.074l3.227-2.649c.262-.204.489-.472.667-.787.178-.315.304-.67.37-1.047.095-.584.04-1.19-.156-1.732-.197-.542-.527-.993-.941-1.287l-2.691-1.94 2.623-2.16c.44-.361.767-.9.928-1.526a3.557 3.557 0 0 0-.045-1.916c-.183-.603-.53-1.106-.981-1.42l-1.86-1.34-.762.628 2.206 1.59c.219.155.402.378.533.647.13.269.202.575.21.89.007.314-.05.625-.167.904-.117.278-.29.514-.501.685l-2.974 2.448-3.023-2.18a1.675 1.675 0 0 1-.617-.862 2.25 2.25 0 0 1-.042-1.23c.1-.405.31-.752.594-.984l3.14-2.583a.529.529 0 0 0 .175-.324.618.618 0 0 0-.058-.384.42.42 0 0 0-.26-.22.344.344 0 0 0-.308.073L4.997 4.814a2.643 2.643 0 0 0-.664.79 3.299 3.299 0 0 0-.366 1.05Z'
      fill='#fff'
    />
  </Svg>
)

export default SvgDnaIcon
