import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { useIsFocused } from '@react-navigation/native'
import { Container } from '@nowon/container'
import { updateChallengeStatusByQrCode } from '../../apiGraphql'
import { appUrl } from '../../config'

export const Scanner = (props) => {
  const { navigation, user } = props
  const [shouldRender, setShouldRender] = useState(false)
  const isQR = props?.route?.params?.isQR
  const challengeId = props?.route?.params?.challengeId
  const isFocused = useIsFocused()

  useEffect(() => {
    if (!user) return
    const url = `${appUrl}/scanner?challengeId=${challengeId}&isQR=${isQR}`
    if (isQR === 'true' && user) {
      updateUserProfile(url)
    }
  }, [isQR, user])

  async function updateUserProfile(qrCode) {
    try {
      const qrCodeResponse = await updateChallengeStatusByQrCode(
        user?.userId,
        qrCode,
      )
      if (qrCodeResponse?.challengeId !== null) {
        navigation.navigate('challengeCompleted', {
          challengeId: qrCodeResponse?.challengeId,
          status: qrCodeResponse?.status,
          challengeType: qrCodeResponse?.challengeType,
        })
      } else {
        setShouldRender(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleScan = async (data) => {
    if (data) {
      setShouldRender(false)
      updateUserProfile(data)
    }
  }

  const handleError = async (err) => {
    console.error(err)
  }

  useEffect(() => {
    if (!isFocused) {
      setShouldRender(false)
    } else {
      setShouldRender(true)
    }
  }, [isFocused, user])

  return (
    <Container>
      {!isQR && shouldRender && (
        <QrReader
          facingMode='environment'
          onScan={(data) => handleScan(data)}
          onError={(err) => handleError(err)}
          style={{ width: '100%' }}
        />
      )}
    </Container>
  )
}
