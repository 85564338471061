import React, { useState } from 'react'
import { View, Image, TouchableOpacity } from 'react-native'
import { Surface } from 'react-native-paper'
import { Text } from '@nowon/components'
import { MemoriesPopup } from '@nowon/patterns'

const MemoriesPage = (props) => {
  const { imageArray, colors, t } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [modalImage, setModalImage] = useState('')
  const [imageArrayState, setImageArrayState] = useState(true)
  const handleTouch = (index) => {
    setModalImage(imageArray[index])
    setModalVisible(!modalVisible)
  }
  return (
    <>
      <Surface elevation={0} style={{ gap: 26, marginBottom: 35 }}>
        <Text variant='headlineLarge' style={{ color: colors.primary }}>
          {t('RAPCITY_MEMORY')}
        </Text>
        <Text variant='subTitles' style={{ color: '#CCCCCC' }}>
          {t('RAPCITY_MEMORIES')}
        </Text>
      </Surface>
      <View style={{ gap: 20 }}>
        {imageArrayState &&
          imageArray.map(
            (item, index) =>
              item.name && (
                <TouchableOpacity onPress={() => handleTouch(index)}>
                  <Image
                    source={item.url}
                    style={{
                      height: 203,
                      width: '100%',
                      borderRadius: 15,
                    }}
                    resizeMode='cover'
                  />
                </TouchableOpacity>
              ),
          )}
      </View>
      {modalVisible && (
        <MemoriesPopup
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          colors={colors}
          t={t}
          modalImage={modalImage}
          setImageArrayState={setImageArrayState}
        />
      )}
    </>
  )
}

export default MemoriesPage
