export default {
  LOGIN: 'Anmeldung',
  SIGN_IN_WITH: 'Melden Sie sich mit an',
  WELCOME: 'Nowon DE',
  ACTIONS: {
    CONTINUE: 'Weiter',
    SIGN_UP: 'Registrieren',
    BACK: 'Zurück',
    SCAN_QR: 'Scan QR Code',
    CANCEL: 'Abbrechen',
    RETRIEVE: 'weiter',
    VIEW_MAP: 'Map anzeigen',
    GET_STARTED: 'Leg los.',
    OK: 'Ok',
    REDEEM: 'Einlösen',
    KNOW_MORE: 'mehr erfahren',
    BUY_TICKET: `Ticket kaufen`,
    UPDATE: 'Aktualisieren',
    SKIP: 'Skip',
    NEXT: 'Weiter',
    FOLLOW: 'Folgen',
    INVITE: 'Einladen',
    VISIT_SPONSOR: 'Sponsor besuchen',
    YES: 'Ja',
    NO: 'Nein',
  },
  NAME: 'Name',
  EMAIL_ID: 'E-Mail Adresse',
  PASSWORD: 'Passwort',
  DELIVERED: 'Geliefert',
  TICKET_NUMBER: 'Ticket Nummer',
  PRODUCT_COUNTER_NAME: 'Product name/Counter name',
  CONFIRMATION_CODE: 'Bestätigungscode',
  WELCOME_ONBOARD: 'Willkommen in der Rap City Familie',
  CHALLENGES: 'Challenges',
  NEW_CHALLENGE: 'Neue Challenge',
  RAPCITY_MEMORY: 'Rap City Erinnerungen',
  RAPCITY_MEMBER: 'Rap City member',
  WELCOME_RAPCITY: 'Welcome to Rapcity',
  ABOUT_RAPCITY: 'Über Rap City',
  FOLLOW_RAPCITY: 'Rap City folgen',
  COPY_RIGHT: 'Copyright @ Rapcity 2022',
  SKIP_FOR_NOW: 'Überspringen',
  EMAIL: 'Email',
  LOG_IN: 'Login',
  PROFILE: 'Profil',
  TO_THE_OFFERS: `Zu den Preisen`,
  OFFERS_AVAILABLE: 'Angebote',
  BY_CLICKING: 'Indem du auf „Registrieren“ klickst, stimmst du den ',
  TERMS_CONDITION: `Nutzungsbedingungen`,
  USE: ` zu.`,
  GET_BACK_SOON: 'Wir arbeiten daran. Die Fotos sind bald für dich verfügbar.',
  PRIVACY_POLICY: 'Datenschutzbestimmungen',
  MOBILE_NUMBER: 'Telefon Nummer',
  DATE_OF_BIRTH: 'Geburtsdatum',
  ALL_CHALLENGES: 'Alle Challenges',
  OVER_ALL_PROGRESS: 'Gesamtfortschritt',
  REWARD_POINTS: 'Punkte',
  SIGN_UP_WITH:
    'Melde dich kostenlos an und geniesse das volle Rap City Erlebnis ',
  READ_MORE: 'read more',
  COUPON_WON: 'Gutscheine',
  COUPON_CODE: 'Rabatt Code',
  REGISTER: 'Registrieren',
  SUPPORT: 'Support',
  LOG_OUT: 'Ausloggen',
  YOUR_POINT: 'Punkte',
  YOUR_POINTS: 'Punkte',
  YOUR_COUPONS: 'Deine Rabattcodes',
  COUPON_EXPIRING: 'Rabattcode läuft bald ab',
  COUPONS_INFO: 'Finde hier all deine Rabattcodes',
  THANK_YOU: 'Vielen Dank!',
  YOUR_TICKET: 'Dein Rapcity Ticket',
  CONCERT_TICKET: 'Concert ticket',
  TICKET_IMAGE: 'Ticket image placeholder',
  ADD_LATER: 'später hinzufügen',
  ENTER_TICKET_NUMBER: 'Bitte trage hier deine Ticket Nummer ein',
  VERIFICATION_COMPLETER: `Verifizierung abgeschlossen!`,
  PASSWORD_VALIDATION: `Das Passwort muss mindestens ein Grossbuchstaben, ein Kleinbuchstaben, eine Nummer und ein Sonderzeichen enthalten.`,
  PREFERENCE: `Vervollständige dein Profil`,
  YOUR_PREFERENCES: 'Deine Präferenzen',
  PERFERENCES: 'Präferenzen',
  POINTS: `Erhalte `,
  CHALLENGE_OVERVIEW: `Challenge Übersicht`,
  EVENT_OVERVIEW: 'Event overview',
  TOTAL_CHALLENGES: `Total Challenges`,
  ACHIEVED: `Abgeschlossen`,
  PENDING: `Ausstehend`,
  PTS: 'Punkte',
  TIME_TABLE: `Timetable`,
  DOORS_DJ: `Doors + DJ set`,
  VIEW_EVENTS: `View events`,
  FESTIVAL_MAP: 'Festival Map',
  VIEW_FESTIVE: `Festival Map ansehen`,
  NOT_JOINED: `Noch kein Rap City Ticket?`,
  ALREADY_USER: 'Hast du bereits ein Konto?',
  JOINED: `Schon beigetreten?`,
  INVITE: `Einladen`,
  INVITE_FRIENDS: `Freunde einladen`,
  INVITATION_ACCEPTED: 'Einladung angenommen',
  ACCEPTED_CHALLENGE: 'Delwar Hossain has accepted your challenge',
  INVITE_JOIN: `Du kennst jemanden, der Rap City genauso feiert wie du?`,
  HELLO: `Yo`,
  VISIT_LIDL: 'Visit Finelli',
  VISIT_LIDL_CONTENT: 'Visit Finelli store near you and scan the QR code',
  PHOTO_CHALLENGE_COMPLETED:
    'Mach dich bereit um deine Errinerung festzuhalten.',
  PHOTO_CHALLENGE_MESSAGE:
    'Teile deine registrierte E-Mail-ID mit Rap City an der Foto Booth damit du deine Bilder erhältst.',
  INVITE_YOUR_FRIENDS: 'Freunde zu dieser Challenge einladen',
  CHALLENGE_COMPLETED: 'Whoa! Du hast die Challenge erfolgreich abgeschlossen.',
  GO_TO_CHALLENGE: 'Zu den Challenges',
  GO_TO_HOME: 'Home',
  VISIT_METRO: 'Visit Metro',
  REDEEM: 'Bist du sicher, dass du deine Punkte einlösen möchtest für:',
  REDEEM_PRESS: 'Herzlichen Glückwunsch! Deine Punkte werden eingelöst für',
  CONGRATS: 'Herzlichen Glückwunsch!',
  REWARD_CLAIM: 'Erhaltener Reward',
  REDEEMED_POINTS: 'Eingelöste Punkte',
  REDEEM_POINTS:
    'Deine Punkte kannst du für diverse Verlosungen und Angebote einlösen.',
  REWARD_POINT_INFO: 'Finde hier all deine Belohnungspunkte',
  REDEEM_CODE: 'Deine Fotos werden bald verfügbar sein',
  REDEEM_POINT: 'Punkte einlösen',
  REDEEM_OFFERTICKET_RAFFLE:
    'Du wirst per E-Mail kontaktiert sobald die Verlosung vorbei ist',
  REDEEM_OFFERTICKET_NORMAL: 'eingelöst',
  POINTS_EARNED: 'Total Punkte erhalten',
  FORGOT_PASS: 'Passwort vergessen',
  CHANGE_PASSWORD: 'Change password',

  RESET_PASSWORD: 'zurück zur Anmeldeseite',
  PASSWORD_SENT: 'Passwort zurücksetzten',
  PASSWORD_UPDATE: 'Passwort aktualisiert',
  EMAIL_INVALID: 'E-Mail Adresse ungültig!',
  BACK_LOGIN: 'Zurück zum login',
  PASSWORD_UPDATE_SUCCESSFULLY: 'Dein Passwort wurde erfolgreich aktualisiert.',
  INVITE_REWARD:
    'Du kennst jemanden, der Rap genauso feiert wie du? Dann lade sie oder ihn jetzt zur Rap City Community ein!',
  LOREM_IPSUM:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempori1oiw incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam.',
  LOREM_DOLAR: 'Lorem ipsum dolar',
  NEW_USER: 'New user?',
  SIGN_IN_GOOGLE: 'Mit Google anmelden',
  SIGN_UP_GOOGLE: 'Mit Google registrieren',
  CONTINUE_FACEBOOK: 'Continue with Facebook',
  WITH_EMAIL: 'Oder mit deiner E-Mail registrieren',
  PASSWORD_INVALID: 'Password is invalid!',
  RESET_PASSWORD_LINK:
    'Falls ein Rap City Konto mit der eingegeben E-Mail-Adresse existiert, wurde eine E-Mail an diese Adresse gesendet. Bitte folgen Sie den Anweisungen in der E-Mail, um Ihr Passwort zurückzusetzen.',
  NOTIFICATION: 'Benachrichtugen',
  PHOTO_CHALLENGE: 'Foto Challenge',
  PHOTO_BOOTH:
    'Visit the photo booth at the Rapcity event. Scan the QR code near the booth and capture your memories',
  FIRST_NAME: 'Vorname',
  LAST_NAME: 'Nachname',
  UPDATE_PROFILE: 'Profil aktualisieren',
  POINTS_AVAILABLE: 'Verfügbare Punkte',
  POINTS_REQUIRED: 'Benötigte Punkte',
  AVAILABLE_POINTS: 'Verfügbare Punkte',
  FRIEND_INVITE:
    'Your friend has invited you for a <Rapcity/Rapcity challenge>. Sign in with Rapcity & check out your challenge',
  PHOTO_UPDATE:
    'Your Photos have been updated. Thanks for shooting a Photo at Rap City and do not forget to mark @rapcity in if you post the Photo to Instagram',
  GET_STARTED_BY: 'Punkte, in dem du uns mehr über dich erzählst.',
  GET: 'Erhalte',
  COMPLETED_ONBOARDING: `Du hast `,
  HELP_AND_SUPPORT: 'Hilfe & Support',
  INSUFFICIENT_CARD_TITLE: 'Unzureichende Punkte',
  INSUFFICIENT_CARD_CONTENT: `Du hast nicht genügend Punkte. Bitte erledige die ausstehende Challenges,um mehr Punkte zu sammeln.`,
  ACCOUNT_SUCCESSFULLY:
    'Wir haben dir ein Bestätigungsmail zugestellt. Bitte verifiziere dein Profil über den Link und melde dich mit deinem Account an. Bitte prüfe auch den SPAM-Ordner, falls du in den nächsten Minuten keine Bestätigung erhalten haben!',
  ACCOUNT_DELETION: 'Bist du sicher, dass du sein Profil löschen möchtests?',
  DELETE_ACCOUNT: `Konto löschen`,
  ALMOST_DONE: `Fast geschafft!`,
  Q_USER_NAME: 'Wie sollen wir dich nennen?',
  Q_D0B: 'Wie lautet dein Geburtdatum?',
  Q_GENDER: 'Was ist dein Geschlecht?',
  MALE: 'Männlich',
  FEMALE: 'Weiblich',
  VARIOUS: 'divers',
  Q_NUMBER: 'Wie lautet deine Telefonnummer',
  Q_ADDRESS: 'Woher kommst du?',
  STREET: 'Strasse',
  NR: 'Nr',
  ZIP: 'PLZ',
  CITY: 'Ort',
  LAND: 'Land',
  USER_NAME: 'Username',
  VISIT_RAPCITY: 'Schau dir deine Bilder vom Rap City an',
  OFFER: 'off',
  CODE: 'code',
  AUTHORIZE_US: 'and authorize us to lorem ipsum',
  INTO_SLIDE_1_TITLE: 'Welcome to the Rap City Fam! ',
  INTO_SLIDE_1_DESCRIPTION:
    'Du hast dich erfolgreich für das Rap City Community Portal registriert und nimmst nun automatisch an der Verlosung für den Rap City Lifetime-Pass teil.',
  INTO_SLIDE_2_TITLE: 'Punkte & Belohnungen',
  INTO_SLIDE_2_DESCRIPTION:
    'Erfülle verschiedene Challenges und erhalte dafür Punkte sowei Rabatt-Codes. Im Shop kannst du mit deinen Punkten diverse Belohnungen abholen. Je mehr Punkte du gesammelt hast, desto lukrativer die Preise',
  PHOTO_BOOTH_CHALLENGE_SUCCESS_MESSAGE: `Halte den Code bereit bevor du das Foto schiesst.`,
  ARTIST_NAME_1: '1. Künstler',
  ARTIST_NAME_2: '2. Künstler',
  ARTIST_NAME_3: '3. Künstler',
  CHALLENGE_ALREADY_COMPLETED: 'Challenge bereits erfüllt',
  CHALLENGE_TYPE: 'Challenge Type',
  GERMANY: 'Deutschland',
  SWITZERLAND: 'Schweiz',
  FRANCE: 'Frankreich',
  ITALY: 'Italien',
  ENGLAND: 'England (UK)',
  USA: 'USA',
  INPROGRESS: 'läuft',
  PRIVACY_POLICY_ERROR: 'Bitte stimmen Sie den Nutzungsbestimmungen zu',
  INVITE_FRIEND_TO_CHALLENGE: 'Einladung zur Rap City Challenge: ',
  REDEEMED: 'eingelöst',
  RAFFLE:
    'Du nimmst nun an der Verlosung teil und wirst per E-Mail kontaktiert, falls du gewonnen hast.',
  NOT_A_RAFFLE: 'Melde dich am Rap City Stand, um deinen Preis abzuholen.',
  POP_UP: 'Du wirst per Mail kontaktiert, sobald das Ticketing online ist.',
  AWESOME: 'Stabil',
  RECEIVE: 'erhalten.',
  RAPCITY_MEMORIES:
    'Hier sind deine Schnappschüsse aus der Fotobooth! Die Bilder sind echt cool geworden, oder? Mit der «Teilen»-Funktion kannst du sie direkt auf Instagram posten. Nicht vergessen @rapcity.ch zu markieren – unseren Like hast du auf sicher.',
  NOT_ACHIEVED: 'Not achieved',
  SHARE_FEEDBACK: `Share feedback and win a gift voucher`,
  FEEDBACK_CONTENT: `Share your valuable feedback about the rapcity event and win a gift voucher lorem ipsum dolar`,
  VIEW: 'Öffnen',
  IMAGE_SIZE: 'Image size is large. please upload another image',
  TOP: 'Top',
  COMMUNITY_SONGS: 'Community Songs',
  COMMUNITY_DNA: 'Community Music DNA ',
  MATCH_SONGS: 'Matches Songs',
  MATCH_GENRE: 'Matches Genre',
  MATCH_ARTISTS: 'Matches Artist',
  OVERALL_DNA: 'Overall DNA Matches',
  DNA: 'DNA',
  DISCONNECT_SPOTIFY: 'Disconnect Spotify',
  SPOTIFY_DISCONNECTION: 'Do You want to disconnect spotify?',
  DNA_CARD: 'VERGLEICHE DEINE DNA MIT UNS',
  DNA_CARD_SPOTIFY: 'Verbinde deinen Spotify account',

  // New version
  HOME: {
    VOTE_CONTEST_TITLE: 'Rap City Contest',
    GET_INTO_THE_CONTEST: 'Gib deinen täglichen Vote ab!',
    MUSIQ_QUIZ_TITLE: 'Nimm am MUSIQ QUIZ teil',
    GET_POINT: 'Erhalte {{point}} Punkte'
  },
  CONTEST: {
    ABOUT_CONTEST: 'Über den Rap City Contest',
    WELCOME: 'Willkommen beim Rap City Contest! Gib täglich deine Stimme für deine Lieblingskünstler ab und entscheide mit, wer den Contest gewinnt.',
    RULE_24H: 'Du kannst einmal pro Tag abstimmen.',
    RULE_PER_DAY: 'Du kannst nur für einen Künstler pro Tag abstimmen.',
    RULE_END: 'Das Voting endet am 7. Juli um 12:00 Uhr',
    RULE_FAKE: 'Fake Votes durch Temp Mails o.ä. werden nicht gezählt.',
    RAPCITY_CONTEST: 'Rap City Contest',
    VOTING: 'Voting',
    VOTED_FOR: 'Du hast gevotet für',
    NEXT_VOTING: 'Du kannst deinen nächsten Vote in {{time}} Stunden abgeben.',
    GIVE_DAILY: 'Gib deinen täglichen Vote ab!',
    HOURS: 'Stunden',
    MINUTES: 'Minuten',
    SECONDS: 'Sekunden',
    DAYS: '{{day}} Tagen',
    ENDIND_IN: 'Voting endet in',
    YOUR_ARTIST_OF_DAY: 'Zu den Contest Teilnehmern',
    OTHERS: '& {{number}} weitere',
    MOSTEST_VOTES: 'Meist gevoteter Künstler des Tages',
    CONTEST_INFO: 'Contest Informationen',
    KNOW_ALL: 'Erfahre mehr über den Rap City Contest',
    VOTE_FOR: 'Vote für deinen Artist',
    VOTE_FOR_FAV: 'Vote für deinen Artist',
    YOU_CAN_VOTE: 'Du kannst einmal pro Tag abstimmen.',
    MORE_INFO: 'Weitere Infos',
    OVER_TALENTS: "Beim diesjährigen Rap City Contest bewarben sich über 130 Talente. Pablo, Jerome und ZZ Amparo bilden die Jury. Entscheide mit!",
    SCHEDULE: 'Ablauf',
    THE_24_RAPPERS_LINE_1: "Sechs Leute schaffen es in den Halbfinal am Openair Frauenfeld – drei via Wildcard der Jury und drei via Online-Voting.",
    THE_24_RAPPERS_LINE_2: "Am Frauenfeld wird das Feld dann nochmals halbiert, sodass die drei Finalisten feststehen.",
    THE_24_RAPPERS_LINE_3: "Sie werden dann am 23. November an der Rap City Season 06 im Hallenstadion um den Sieg kämpfen.",
    WIN: 'Gewinn',
    DER_ORDER: "Der oder die Gewinnerin erhält eine zweitägige Session im Studio von Sektion Züri direkt am Hauptbahnhof Zürich im Wert von 1600 Franken. Zudem wird der Sieger als Act an der Rap City 2025 gebucht. Ausserdem wird der Sieger eine EP mit Sony Music Schweiz veröffentlichen dürfen und wird als Act an der Rap City 2025 gebucht.",
    VOTE_POSTED: 'Dein Vote wurde abgegeben',
    WINNERS: 'Contest Winners'
  },
  VERIFY_EMAIL: 'E-Mail verifizieren',
  VERIRED_EMAIL: 'Dein Account wurde erfolgreich verifiziert. Melde dich jetzt mit deinem Account an.',
  INVALID_EMAIL: 'Temporäre E-Mail Adressen sind für das Voting nicht zugelassen',
  TEMP_EMAIL_ERROR: 'Temp Mails können sich beim Community Portal nicht registrieren',
}
