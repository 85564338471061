import { TouchableOpacity, View } from 'react-native'
import React from 'react'
import { Surface, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import CircularProgress from '@nowon/circularProgress'
import { Text } from '@nowon/components'

const ChallengeOverView = (props) => {
  const { totalChallengesCount = 0, achievedCount = 0, challengePage } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()

  const minTwoDigits = (n) => (n < 10 ? '0' : '') + n

  return (
    <Surface
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: 24,
        backgroundColor: colors.backgroundSurface,
      }}
    >
      <TouchableOpacity
        style={{
          paddingHorizontal: 24,
          paddingVertical: 17,
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          width: '100%',
        }}
        onPress={() => {
          // eslint-disable-next-line no-unused-expressions
          challengePage ? null : navigation.navigate('challenges')
        }}
      >
        <Surface elevation={0}>
          <Text style={{ fontFamilfy: 'Nunito Sans', fontSize: 20, marginBottom: 8 }} colorVariant='textPrimary'>
            {t('CHALLENGE_OVERVIEW')}
          </Text>
        </Surface>
        <Surface
          elevation={0}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 10,
            width: '100%',
          }}
        >
          <CircularProgress
            bgColor='black'
            pgColor='rgba(110, 228, 228, 1)'
            size={94}
            strokeWidth={3}
            progressPercent={(achievedCount / totalChallengesCount) * 100}
          />
          <Surface elevation={0} style={{ justifyContent: 'space-around' }}>
            <Surface
              elevation={0}
              style={{
                flexDirection: 'row',
                gap: '13px',
                alignItems: 'center',
              }}
            >
              <Text variant='headlineSmall' colorVariant='primaryVariant'>
                {(!!totalChallengesCount &&
                  minTwoDigits(totalChallengesCount)) ||
                  '00'}
              </Text>
              <Text variant='bodyMedium' colorVariant='primaryVariant'>
                {t('TOTAL_CHALLENGES')}
              </Text>
            </Surface>
            <Surface
              elevation={0}
              style={{
                flexDirection: 'row',
                gap: '13px',
                alignItems: 'center',
              }}
            >
              <Text variant='headlineSmall' colorVariant='primaryOn'>
                {(!!achievedCount && minTwoDigits(achievedCount)) || '00'}
              </Text>
              <Text variant='bodyMedium' colorVariant='primaryOn'>
                {t('ACHIEVED')}
              </Text>
            </Surface>
          </Surface>
        </Surface>
      </TouchableOpacity>
    </Surface>
  )
}

export default ChallengeOverView
