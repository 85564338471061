import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Card, Avatar } from 'react-native-paper'
import { Text } from '@nowon/components'
import { getVotesByDate } from '../../apiGraphql'
import {
  getBeginningOfDate,
  getHighestVotedOfDay,
  getNextVoteAt,
} from '../../utils/contestHelper'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: 24,
  },
  cardTitle: {
    justifyContent: 'flex-end',
    margin: 0,
    color: '#CCCCCC',
    height: 50,
    minHeight: 50,
  },
  cardSubtitleStyle: { color: '#CCCCCC', fontSize: 16 },
  cardContentMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContentAvatar: {
    marginRight: 16,
  },
  cardContentArtistName: {
    fontSize: 20,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  cardContentArtistDescription: { fontSize: 12 },
  cardContentVotesView: { position: 'absolute', right: 5, bottom: 0 },
  cardContentVotesCount: { fontSize: 14, color: '#FF4C77' },
})

const MostVotedArtistCard = (props) => {
  const { artistProfiles } = props
  const { t } = useTranslation()
  const [highestVoteArtists, setHighestVoteArtists] = useState([])
  const [highestVotes, setHighestVotes] = useState(0)

  const artistNames = () => {
    const names = highestVoteArtists.map((artist) => artist.name)
    const firstOfThree = names.splice(0, 3)
    return firstOfThree.join(', ') + ` & ${names.length} others`
  }

  useEffect(() => {
    const getVotes = async () => {
      const response = await getVotesByDate(
        getBeginningOfDate(),
        getNextVoteAt(),
      )

      if (response) {
        const { artistIds, count } = getHighestVotedOfDay(response)
        const artistH = artistProfiles?.filter((x) =>
          artistIds.includes(x.guid),
        )
        setHighestVoteArtists(artistH)
        setHighestVotes(count)
      }
    }

    if (artistProfiles && artistProfiles.length > 0) {
      getVotes()
    }
  }, [artistProfiles, artistProfiles.length])

  if (highestVoteArtists.length === 0) {
    return <></>
  }

  return (
    <Card style={styles.card}>
      <Card.Title
        style={styles.cardTitle}
        subtitleStyle={styles.cardSubtitleStyle}
        subtitle={`🎉  ${t('CONTEST.MOSTEST_VOTES')}`}
      />
      <Card.Content>
        <View style={styles.cardContentMain}>
          {highestVoteArtists.length === 1 ? (
            <>
              {' '}
              <View style={styles.cardContentAvatar}>
                <Avatar.Image
                  size={40}
                  source={{ uri: highestVoteArtists[0]?.avatarUrl }}
                ></Avatar.Image>
              </View>
              <View>
                <Text style={styles.cardContentArtistName}>
                  {highestVoteArtists[0]?.name}
                </Text>
                <Text style={styles.cardContentArtistDescription}>
                  {highestVoteArtists[0]?.songNames[0]}
                </Text>
              </View>
            </>
          ) : (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 8,
                }}
              >
                {highestVoteArtists.map((artist, index) => (
                  <Avatar.Image
                    style={{
                      position: index > 0 ? 'absolute' : 'relative',
                      left: index * 12,
                    }}
                    size={24}
                    source={{ uri: artist.avatarUrl }}
                  ></Avatar.Image>
                ))}
              </View>
              <Text style={{ fontSize: 12, color: '#CCCCCC' }}>
                {artistNames()}
              </Text>
            </View>
          )}

          <View style={styles.cardContentVotesView}>
            <Text style={styles.cardContentVotesCount}>
            {/* {highestVotes} votes*/}
            </Text>
          </View>
        </View>
      </Card.Content>
    </Card>
  )
}

export default MostVotedArtistCard
