import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { Text } from '@nowon/components'
import { Container } from '@nowon/container'
import { Surface, Card } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { store } from '../../store/storeProvider'
import VoteCount from '../../components/Contest/VoteCount'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  breadcumView: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#CCCCCC',
  },
  subBreadcum: {
    fontFamily: 'Lexend Deca',
    fontSize: 12,
    color: '#6EC1E4',
  },
  pageTitle: {
    fontFamily: 'Lexend Deca',
    fontSize: 32,
    color: '#6EC1E4',
    paddingRight: 30,
  },
  pageDesc: {
    fontFamily: 'Lexend Deca',
    fontSize: 14,
    paddingRight: 80,
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 12,
  },
  cardVoted: {
    backgroundColor: 'rgba(255, 76, 119, 0.15)',
    borderRadius: 12,
  },
  cardUnVoted: {
    backgroundColor: 'rgba(255, 255, 255)',
    opacity: 0.5,
    borderRadius: 12,
  },
  voteCount: {
    fontSize: 15,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  votedCount: {
    color: 'rgba(255, 76, 119, 1)',
    fontSize: 15,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  cardContentMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContentAvatar: {
    marginRight: 16,
  },
  cardContentAvatarImage: {
    height: 90,
    width: 80,
    borderRadius: 4,
  },
  cardContentArtistInfo: {
    alignSelf: 'flex-start',
    display: 'flex',
    height: '100%',
  },
  cardContentArtistName: {
    fontSize: 24,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  cardContentArtistNameWildcard: {
    fontSize: 24,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    color: '#F1AD33',
  },
  cardContentArtistDescription: {
    fontSize: 12,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
  },
  voteIcon: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 40,
    height: 40,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  wildcard: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    color: '#F1AD33',
  },
})

export const ContestWinners = (props) => {
  const { user } = props
  const navigation = useNavigation()
  const { t } = useTranslation()
  const [winners, setWinners] = useState([])
  const { artistProfiles, votedArtist, voteTracker, isEnded } = useSelector(
    (state) => state?.contestReducer,
  )
  const onSelectArtist = (artist) => {
    store.dispatch({
      type: 'SET_SELECTED_ARTIST',
      payload: artist,
    })
  }

  useEffect(() => {
    const _winners = artistProfiles.filter(x => x.isWinner || x.isWildcard)
    setWinners(_winners)
  }, [artistProfiles])

  return (
    <Container>
      <Surface elevation={0} style={{ gap: '22px', paddingBottom: 27 }}>
        <View style={styles.breadcumView}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('contest')
            }}
          >
            <Text style={styles.breadcum}>
              {t('CONTEST.RAPCITY_CONTEST')} &gt;
            </Text>
          </TouchableOpacity>

          <Text style={styles.subBreadcum}> Artist</Text>
        </View>
        <Text style={styles.pageTitle}>{t('CONTEST.WINNERS')}</Text>

        {winners &&
          winners.map((artist) => (
            <TouchableOpacity
              onPress={() => {
                onSelectArtist(artist)
                navigation.navigate('contestArtistDetails')
              }}
            >
              <Card
                style={
                  !!votedArtist
                    ? votedArtist.guid === artist.guid
                      ? styles.cardVoted
                      : styles.cardUnVoted
                    : styles.card
                }
              >
                <Card.Content>
                  <View style={styles.cardContentMain}>
                    <View style={styles.cardContentAvatar}>
                      <Image
                        style={styles.cardContentAvatarImage}
                        source={{ uri: artist.avatarUrl }}
                      />
                    </View>

                    <View style={styles.cardContentArtistInfo}>
                      <View style={{ flexGrow: 1 }}>
                        <Text
                          style={
                            artist.isWildcard
                              ? styles.cardContentArtistNameWildcard
                              : styles.cardContentArtistName
                          }
                        >
                          {artist.name}
                        </Text>
                        <Text style={styles.cardContentArtistDescription}>
                          {artist.albumName}
                        </Text>
                      </View>

                      {!artist.isWildcard && (
                        <View>
                          <VoteCount
                            artist={artist}
                            styleInput={
                              !!votedArtist && votedArtist.guid === artist.guid
                                ? styles.votedCount
                                : styles.voteCount
                            }
                          />
                        </View>
                      )}
                    </View>

                    {!artist.isWildcard ? (
                      <></>
                    ) : (
                      <Text style={styles.wildcard}>Wildcard Winner</Text>
                    )}
                  </View>
                </Card.Content>
              </Card>
            </TouchableOpacity>
          ))}
      </Surface>
    </Container>
  )
}
