import React from 'react'
import { AppRegistry } from 'react-native'
import { App } from 'core/src/App'
import { Amplify } from 'aws-amplify'
import awsConfig from '../awsConfig'

Amplify.configure(awsConfig)
const NowonApp = () => {
  return <App />
}

AppRegistry.registerComponent('nowon', () => NowonApp)
AppRegistry.runApplication('nowon', {
  rootTag: document.getElementById('root'),
})
