const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getCurrentDate = () => {
  const date = new Date()
  return `${date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`
}

export const getMonthNumber = (monthName) =>
  monthNames.findIndex((mN) => mN === monthName) + 1
