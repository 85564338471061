import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { useNavigation } from '@react-navigation/native'
import { setItemAsync } from '../../utils/SecureStore'

const useLogin = () => {
  const navigation = useNavigation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [continueClicked, setContinueClicked] = useState(false)
  const signIn = async () => {
    setContinueClicked(true)
    if (isLoading) return
    setIsLoading(true)
    try {
      const response = await Auth.signIn(email, password)
      await setItemAsync(
        'SIGNING_RESPONSE',
        JSON.stringify(response?.attributes),
      )
      setEmail('')
      setPassword('')
      if (response?.attributes?.sub) {
        navigation.navigate('launchHome')
      }
    } catch (error) {
      setErrorMsg(error?.message)
    }
    setIsLoading(false)
  }

  const facebookSignIn = async () => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const googleSignIn = async () => {
    try {
      Auth.federatedSignIn({ provider: 'Google' })
    } catch (error) {
      console.log({ error })
    }
  }

  return {
    signIn,
    email,
    password,
    setEmail,
    setPassword,
    errorMsg,
    setErrorMsg,
    googleSignIn,
    facebookSignIn,
    isLoading,
    continueClicked,
    setContinueClicked,
  }
}

export default useLogin
