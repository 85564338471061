import React, { useEffect, useState } from 'react'
import { FAB, Surface, Text } from 'react-native-paper'
import { TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { Container } from '@nowon/container'
import { useIsFocused } from '@react-navigation/native'
import { ChallengesCard, ChallengeOverView } from '@nowon/card'
import { Icon } from '@nowon/icons'
import { getAllChallengesByUserId, getUserById } from '../../apiGraphql'

export const Challenges = (props) => {
  const { user, navigation, colors, t } = props
  const isRendered = useIsFocused()
  const [challengesDisplay, setChallengesDisplay] = useState([])
  const { data: challengeData } = useQuery(
    ['getAllChallengesByUserId'],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      return response?.filter((item) => item?.challengeType !== 'INVITE_FRIEND')
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  const { data: userData } = useQuery(
    ['getUserById'],
    async () => {
      const response = await getUserById(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  const [achievedCount, setArchiveCount] = useState()
  const [totalChallengesCount, setTotalChallengesCount] = useState()
  const [availablePoints, setAvailablePoints] = useState('')

  useEffect(() => {
    if (!userData) return
    setAvailablePoints(
      Number(userData?.earnedPoints) - Number(userData?.redeemedPoints),
    )
  }, [userData, isRendered])
  useEffect(() => {
    if (challengeData?.length >= 1) {
      const totalCount = challengeData?.length
      const archive = challengeData?.filter(
        (c) => c.status === 'COMPLETED',
      )?.length
      const displayChallenge = challengeData?.filter(
        (cH) => cH.status !== 'NOT_ACHIEVED',
      )
      setChallengesDisplay(displayChallenge)
      setTotalChallengesCount(totalCount)
      setArchiveCount(archive)
    } else {
      setTotalChallengesCount(0)
      setArchiveCount(0)
    }
  }, [challengeData])

  const minTwoDigits = (n) => {
    if (n > 0) return (n < 10 ? '0' : '') + n
  }
  return (
    <Container>
      <Surface elevation={0} style={{ gap: '12px', paddingBottom: 27 }}>
        <Surface elevation={0}>
          <Text variant='headlineLarge' style={{ color: colors.primary, fontFamily: 'Adopekid-Kanit-RC-Stencil' }}>
            {t('CHALLENGES')}
          </Text>
        </Surface>
        <ChallengeOverView
          totalChallengesCount={totalChallengesCount}
          achievedCount={achievedCount}
          challengePage
        />
        <Surface
          elevation={0}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 20,
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 15,
              paddingVertical: 10,
              backgroundColor: 'rgb(37, 35, 42)',
              flex: 1,
              gap: 15,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 8,
            }}
            onPress={() => navigation.navigate('your-points')}
          >
            <View>
              <Icon name='StarIcon' width={24} height={24} color='#fff' />
            </View>
            <View style={{}}>
              <Text
                variant='headlineSmall'
                style={{
                  color: colors.onTertiaryContainer,
                }}
              >
                {availablePoints || '00'}
              </Text>
              <Text
                variant='labelMedium'
                style={{
                  color: colors.onTertiaryContainer,
                  opacity: 0.5,
                }}
              >
                {t('REWARD_POINTS')}
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              paddingHorizontal: 15,
              paddingVertical: 10,
              flex: 1,
              gap: 15,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
              backgroundColor: 'rgb(37, 35, 42)',
            }}
            onPress={() => navigation.navigate('your-coupons')}
          >
            <View>
              <Icon name='CouponsWon' width={24} height={24} color='#fff' />
            </View>
            <View style={{}}>
              <Text
                variant='headlineSmall'
                style={{
                  color: colors.onTertiaryContainer,
                }}
              >
                {minTwoDigits(userData?.couponsWon) || '00'}
              </Text>
              <Text
                variant='labelMedium'
                style={{
                  color: colors.onTertiaryContainer,
                  opacity: 0.5,
                }}
              >
                {t('COUPON_WON')}
              </Text>
            </View>
          </TouchableOpacity>
        </Surface>
        <Surface
          elevation={0}
          style={{
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <Text variant='titleLarge' style={{ color: colors.neutralLight }}>
            {t('ALL_CHALLENGES')}
          </Text>
        </Surface>
        {challengesDisplay?.length !== undefined &&
          challengesDisplay?.map((c) => <ChallengesCard item={c} />)}
      </Surface>
      <FAB
        icon='line-scan'
        style={{
          position: 'fixed',
          right: 24,
          bottom: 24,
          height: 40,
          borderRadius: 50,
          backgroundColor: colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        color='#FFFFFF'
        onPress={() => navigation.navigate('scanner')}
        label='Scan'
      />
    </Container>
  )
}
