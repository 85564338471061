import React from 'react'
import { StyleSheet, View } from 'react-native'

const BlurWidget = (props) => {
  const { borderRadius } = props
  return <View style={[styles.blur, { borderRadius }]} />
}

export default BlurWidget

const styles = StyleSheet.create({
  blur: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(247, 247, 247, 0.04)',
    backdropFilter: 'blur(10px)',
  },
})
