import React, { useEffect, useState } from 'react'
import { Card, FAB, Surface } from 'react-native-paper'
import { TouchableOpacity, StyleSheet, Image, View } from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { Container } from '@nowon/container'
import {
  ChallengeOverView,
  ChallengesCard,
  CompleteProfileCard,
} from '@nowon/card'
import { useIsFocused } from '@react-navigation/native'
import { IntroSlide } from '@nowon/patterns'
import { Text } from '@nowon/components'
import {
  getAllChallengesByUserId,
  getAllEvents,
  getUserById,
  getImagesByFolderId,
} from '../../apiGraphql'
import {
  InviteCard,
  EventCard,
  MemoriesCard,
  TicketCard,
  FestiveCard,
} from '../../components'
import ContestCard from '../../components/Home/ContestCard'
import {
  CLIENT_ID,
  REDIRECT_URI,
  AUTH_ENDPOINT,
  RESPONSE_TYPE,
  SCOPES,
} from '../../config'
import SpotifyLogo from '../../assets/spotifyLogo.png'
import { isProduction } from '../../config'

export const Home = (props) => {
  const { user, navigation, colors, t, setUser, setProfileImg } = props
  const isRendered = useIsFocused()
  const [modalVisible, setModalVisible] = useState(false)
  const [PhotoBoothChallenge, setPhotoBoothChallenge] = useState()
  const [challengesList, setChallengeList] = useState()
  const [inviteChallenge, setInviteChallenge] = useState()
  const [achievedCount, setArchiveCount] = useState()
  const [totalChallengesCount, setTotalChallengesCount] = useState()
  const [availablePoints, setAvailablePoints] = useState('')
  const { data: challengeData } = useQuery(
    ['getAllChallengesByUserId'],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isRendered,
    },
    {
      initialData: [],
    },
  )
  useEffect(() => {
    if (challengeData?.length > 1) {
      const challengeResponse = challengeData?.find(
        (item) => item?.challengeType === 'QR_PHOTOBOOTH',
      )
      setPhotoBoothChallenge(challengeResponse)
      const challengeListResponse = challengeData?.filter(
        (cD) =>
          cD?.status !== 'COMPLETED' && cD?.challengeType !== 'INVITE_FRIEND',
      )
      const inviteChallengeResponse = challengeData?.find(
        (item) => item?.challengeType === 'INVITE_FRIEND',
      )
      setInviteChallenge(inviteChallengeResponse)
      const totalCount = challengeData?.filter(
        (item) => item?.challengeType !== 'INVITE_FRIEND',
      ).length
      const archive = challengeData?.filter(
        (c) => c.status === 'COMPLETED',
      )?.length
      setTotalChallengesCount(totalCount)
      setChallengeList(challengeListResponse)
      setArchiveCount(archive)
    } else {
      setPhotoBoothChallenge([])
      setChallengeList([])
      setTotalChallengesCount(0)
      setArchiveCount(0)
    }
  }, [challengeData])

  const { data: eventsData } = useQuery(
    ['getAllEvents'],
    async () => {
      const response = await getAllEvents()
      return response.filter((r) => {
        const EventDate = new Date(r?.eventDate)
        const CurrentDate = new Date()
        return EventDate > CurrentDate
      })
    },
    {
      enabled: !!user && isRendered,
    },
    {
      initialData: [],
    },
  )
  const { data: userData } = useQuery(
    ['getUserById'],
    async () => {
      const response = await getUserById(user?.userId)
      return response
    },
    {
      enabled: !!user && isRendered,
    },
    {
      initialData: [],
    },
  )

  useEffect(() => {
    ;(async () => {
      const tempProfileImage = await getImagesByFolderId(user?.photoFolderCode)
      console.log('tempProfile', tempProfileImage)
      const profileImageArr = tempProfileImage?.filter(
        (e) => e.name === 'PROFILE-IMG.JPG',
      )
      setProfileImg(profileImageArr[0]?.url)
    })()
  }, [])

  useEffect(() => {
    function findNewUser() {
      const isNewUser = user?.isNewUser
      setModalVisible(isNewUser === false)
    }
    if (!modalVisible) findNewUser()
  }, [user])

  useEffect(() => {
    if (!userData) return
    setAvailablePoints(
      Number(userData?.earnedPoints) - Number(userData?.redeemedPoints),
    )
  }, [userData, isRendered])

  const spotifySignIn = async () => {
    window.open(
      `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPES.join(
        '%20',
      )}`,
      '_self',
    )
  }

  return (
    <Container>
      <Surface elevation={0} style={{ gap: '22px', paddingBottom: 27 }}>
        <Text
          variant='headlineLarge'
          style={{
            color: colors.primary,
            marginBottom: 10,
          }}
        >
          {t('HELLO')}{' '}
          {user?.userName || user?.firstName || t('RAPCITY_MEMBER')}
        </Text>

        <TicketCard />
        <ContestCard />
        <ChallengeOverView
          totalChallengesCount={totalChallengesCount}
          achievedCount={achievedCount}
        />
        {challengesList
          ?.slice(0, 1)
          ?.map((c) =>
            c.challengeType === 'ONBOARDING' && c.status === 'PENDING' ? (
              <CompleteProfileCard navigation={navigation} challengeData={c} />
            ) : (
              c.status === 'PENDING' && <ChallengesCard item={c} isFromHome />
            ),
          )}
        
        <Card
          style={{
            borderRadius: 24,
            paddingTop: 12,
            paddingBottom: 18,
            paddingHorizontal: 24,
            backgroundColor: colors.backgroundSurface,
          }}
          onPress={() => navigation.navigate('your-points')}
        >
          <Text variant='brandBodyLarge' colorVariant='textPrimary'>
            {t('TO_THE_OFFERS')}
          </Text>
          <Text
            variant='brandtTitleLarge'
            colorVariant='textPrimary'
            style={{ paddingTop: 13 }}
          >
            {availablePoints} {t('YOUR_POINTS')}
          </Text>
        </Card>
        {!isProduction && (
          <TouchableOpacity
            style={styles.cardStyle(colors)}
            onPress={() =>
              userData?.isSpotifyConnected
                ? navigation.navigate('profile-comparison')
                : spotifySignIn()
            }
          >
            {userData?.isSpotifyConnected ? (
              <Text
                variant='brandBodyLarge'
                colorVariant='textPrimary'
                style={{ padding: 13, textAlign: 'center' }}
              >
                {t('DNA_CARD')}
              </Text>
            ) : (
              <View style={{ gap: 10 }}>
                <Text
                  variant='brandBodyLarge'
                  colorVariant='textPrimary'
                  style={{ textAlign: 'center' }}
                >
                  {t('DNA_CARD_SPOTIFY')}
                </Text>

                <Image
                  source={SpotifyLogo}
                  style={{
                    resizeMode: 'contain',
                    height: 40,
                    width: '100%',
                  }}
                />
              </View>
            )}
          </TouchableOpacity>
        )}

        <InviteCard user={user} inviteChallenge={inviteChallenge} />

       

        {PhotoBoothChallenge?.status === 'COMPLETED' && <MemoriesCard />}
        {eventsData?.length > 0 && <EventCard item={eventsData[0]} />}
        {/* {eventsData?.length > 0 || <FeedBackCard />} */}
        {eventsData?.length > 0 && <FestiveCard />}
      </Surface>

      <FAB
        icon='line-scan'
        style={{
          position: 'fixed',
          right: 24,
          bottom: 24,
          height: 40,
          borderRadius: 50,
          backgroundColor: colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        color='#FFFFFF'
        onPress={() => navigation.navigate('scanner')}
        label='Scan'
      />
      {modalVisible && (
        <IntroSlide
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          colors={colors}
          t={t}
          userId={user?.userId}
          setUser={setUser}
        />
      )}
    </Container>
  )
}

const styles = StyleSheet.create({
  cardStyle: (colors) => ({
    paddingVertical: 30,
    paddingHorizontal: 20,
    backgroundColor: colors.backgroundSurface,
    borderRadius: 24,
    alignItems: 'center',
  }),
})
