import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgLogOutIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='m14.95 13.03 1.01 1.01L20 10l-4.04-4.04-1.011 1.01 2.316 2.316H7.143v1.428h10.122l-2.316 2.317Z'
      fill={props.color}
    />
    <Path
      d='M16.049 16.061a8.514 8.514 0 0 1-6.06 2.51 8.516 8.516 0 0 1-6.062-2.51 8.504 8.504 0 0 1-2.498-6.06c0-2.29.879-4.443 2.498-6.062a8.516 8.516 0 0 1 6.061-2.51 8.515 8.515 0 0 1 6.388 2.857h1.819A9.987 9.987 0 0 0 9.988 0C4.465 0 0 4.478 0 10c0 5.523 4.465 10 9.988 10 3.398 0 6.4-1.695 8.207-4.286h-1.819a8.647 8.647 0 0 1-.327.347Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgLogOutIcon
