import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgInviteFriendsIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 31 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M23.5 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4Zm-14 0c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5Zm18 2h-8c-.4 0-.781.082-1.13.225A3.98 3.98 0 0 1 19.5 15v5H28c1.5 0 2.5-1 2.5-2v-3c0-1.654-1.346-3-3-3ZM16 22H3.5c-.552 0-3-1-3-3v-4c0-1.654 1.346-3 3-3h12c1.654 0 3 1.346 3 3v5c0 1-1 2-2.5 2Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgInviteFriendsIcon
