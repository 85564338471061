import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgInstagramSolidIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M9 6.933a2.067 2.067 0 1 0 0 4.133 2.067 2.067 0 0 0 0-4.133Zm8.275-2.042a5.438 5.438 0 0 0-.35-1.883 3.332 3.332 0 0 0-1.934-1.933 5.441 5.441 0 0 0-1.883-.35C12.033.667 11.716.667 9 .667c-2.717 0-3.034 0-4.109.058a5.442 5.442 0 0 0-1.883.35 3.333 3.333 0 0 0-1.933 1.933 5.442 5.442 0 0 0-.35 1.883C.667 5.966.667 6.284.667 9c0 2.716 0 3.033.058 4.108.009.646.127 1.286.35 1.892.166.438.425.836.758 1.166a3.24 3.24 0 0 0 1.175.759c.602.227 1.24.345 1.883.35 1.075.058 1.392.058 4.109.058 2.716 0 3.033 0 4.108-.058a5.437 5.437 0 0 0 1.883-.35 3.24 3.24 0 0 0 1.175-.759c.334-.33.592-.728.759-1.166a5.499 5.499 0 0 0 .35-1.892c.058-1.075.058-1.392.058-4.108 0-2.717 0-3.034-.058-4.109Zm-2.117 6.667a4.777 4.777 0 0 1-.325 1.5 3.219 3.219 0 0 1-1.775 1.775 4.773 4.773 0 0 1-1.508.292h-5.1a4.773 4.773 0 0 1-1.509-.292 2.926 2.926 0 0 1-1.091-.716 2.925 2.925 0 0 1-.683-1.059 4.576 4.576 0 0 1-.284-1.508v-5.1a4.575 4.575 0 0 1 .284-1.509c.153-.413.398-.786.716-1.091.3-.3.661-.533 1.058-.683a4.775 4.775 0 0 1 1.509-.292h5.1a4.775 4.775 0 0 1 1.508.292c.413.153.787.398 1.092.716.302.3.535.66.683 1.058.178.484.277.994.292 1.509V9c0 1.716.058 1.892.033 2.55v.008Zm-1.333-6.2a1.983 1.983 0 0 0-1.175-1.175A3.333 3.333 0 0 0 11.5 4h-5a3.333 3.333 0 0 0-1.15.216A1.983 1.983 0 0 0 4.175 5.35c-.121.371-.18.76-.175 1.15v5c.008.393.081.781.216 1.15a1.983 1.983 0 0 0 1.175 1.175c.356.13.73.204 1.109.216h5a3.332 3.332 0 0 0 1.15-.216 1.982 1.982 0 0 0 1.175-1.175c.14-.368.213-.757.216-1.15v-5a3.15 3.15 0 0 0-.216-1.15v.008ZM9 12.183A3.175 3.175 0 0 1 5.825 9 3.183 3.183 0 1 1 9 12.183Zm3.333-5.742a.75.75 0 0 1 0-1.491.75.75 0 0 1 0 1.491Z'
      fill='#000'
    />
  </Svg>
)

export default SvgInstagramSolidIcon
