import * as React from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

const SvgHeadPhoneIcon = (props) => (
  <Svg
    width={props.width}
    height={props.height}
    viewBox='0 0 29 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Circle cx={14.5} cy={14.5} r={14.5} fill='#3B3A42' />
    <Path
      d='M14.5 8.356a6.267 6.267 0 0 0-6.144 6.371v2.845a2.458 2.458 0 1 0 2.457-2.457c-.433.001-.857.12-1.228.344v-.732A5.038 5.038 0 0 1 14.5 9.585a5.038 5.038 0 0 1 4.915 5.142v.732a2.402 2.402 0 0 0-1.229-.345 2.457 2.457 0 1 0 2.458 2.458v-2.845A6.267 6.267 0 0 0 14.5 8.356Z'
      fill='#1DE9B6'
    />
  </Svg>
)

export default SvgHeadPhoneIcon
