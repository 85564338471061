import React, { useEffect, useState } from 'react'
import { useTheme } from 'react-native-paper'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Header } from '@nowon/components'
import { Container } from '@nowon/container'
import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'
import { getItemAsync, setItemAsync } from '../utils/SecureStore'
import {
  getUserById,
  getAllChallengesByUserId,
  updateChallengeStatusToCompletedById,
  getImagesByFolderId,
  updateReferredByForUser,
  updateIsSpotifyConnected,
} from '../apiGraphql'
import { spotifyLogin } from '../api'
import { ChallengeCompleted } from '../screens/challengeCompleted'
import { Challenges } from '../screens/challenges'
import { FollowChallenge } from '../screens/followChallenge'
import { ForgotPassword } from '../screens/forgotPassword'
import { Home } from '../screens/home'
import { Login } from '../screens/login'
import { LogOut } from '../screens/logout'
import { Menu } from '../screens/menu'
import { Notification } from '../screens/notification'
import { Onboard } from '../screens/onboard'
import { OnboardComplete } from '../screens/onboardcomplete'
import { PhotoChallenge } from '../screens/photoChallenge'
import { Profile } from '../screens/profile'
import { ProfileUpdate } from '../screens/profileUpdate'
import { Register } from '../screens/register'
import { ResetPassword } from '../screens/resetPassword'
import { Scanner } from '../screens/scanner'
import { Splash } from '../screens/splash'
import { Verification } from '../screens/verification'
import { YourCoupon } from '../screens/coupon'
import { Events } from '../screens/events'
import { InviteFriend } from '../screens/inviteFriends'
import { Redeem } from '../screens/redeem'
import { EventMemories } from '../screens/eventMemories'
import { FestiveMap } from '../screens/festiveMap'
import { YourPoints } from '../screens/yourPoints'
import { InviteeHome } from '../screens/inviteehome'
import { VerificationComplete } from '../screens/verificationEmail'
import { VoteChallenge } from '../screens/voteChallenge'
import { PlaylistTrack } from '../screens/playListTrack'
import { ProfileComparison } from '../screens/profileComparison'
import { Contest } from '../screens/contest'
import { ContestArtists } from '../screens/contestArtists'
import { ContestInfo } from '../screens/contestInfo'
import { ContestArtistDetails } from '../screens/contestArtistDetails'
import ContestLayout from '../layout/contest'
import { ContestWinners } from '../screens/contestWinners'

const LaunchHome = (props) => {
  const { setUser } = props
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const [token, setToken] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      await setItemAsync('code', props.route?.params?.code)
      const { hash } = window.location
      let token = window.localStorage.getItem('code')
      if (!token && hash) {
        token = hash
          .substring(1)
          .split('&')
          .find((elem) => elem.startsWith('code'))
          .split('=')[1]

        window.location.hash = ''
      }
      const spotifyCode = await getItemAsync('code')
      setCode(spotifyCode)
      setToken(token)
      const getTempProfile = await getItemAsync('SIGNING_RESPONSE')
      const authResponse = await Auth.currentAuthenticatedUser()
      const tempProfile = await JSON.parse(getTempProfile)
      const userId =
        tempProfile?.sub ||
        authResponse?.signInUserSession?.accessToken?.payload?.sub
      if (userId && spotifyCode !== 'undefined') {
        const res = await spotifyLogin(userId, spotifyCode)
        if (res === 200) {
          const isNewUserResponse = await updateIsSpotifyConnected({
            userId,
            isSpotifyConnected: true,
          })
        }
      }
      if (
        tempProfile?.sub ||
        authResponse?.signInUserSession?.accessToken?.payload?.sub
      ) {
        const response = await getUserById(
          tempProfile?.sub ||
            authResponse?.signInUserSession?.accessToken?.payload?.sub,
        )
        const referalResponse = await getItemAsync('referralCode')

        if (
          referalResponse &&
          response?.referredBy === 'EMPTY' &&
          !response?.isNewUser
        ) {
          await updateReferredByForUser(
            tempProfile?.sub ||
              authResponse?.signInUserSession?.accessToken?.payload?.sub,
            referalResponse,
          )
        }

        await setItemAsync('USER_PROFILE', JSON.stringify(response))
        setUser(response)
        if (response?.userId) {
          const challengeResponse = await getAllChallengesByUserId(
            response?.userId,
          )
          const regChallenge = challengeResponse.find(
            (cr) => cr?.challengeType === 'REGISTRATION',
          )
          if (regChallenge?.status === 'PENDING') {
            await updateChallengeStatusToCompletedById({
              userId: response?.userId,
              challengeId: regChallenge.challengeId,
            })
          }
          const filteredChallenge = challengeResponse.find(
            (cr) => cr?.challengeType === 'ONBOARDING',
          )
          if (filteredChallenge?.status === 'COMPLETED') {
            navigation.navigate('home')
          } else {
            navigation.navigate('onboard')
          }
        }
      }
    })()
  }, [isFocused])
  return <Container />
}

const Launch = () => {
  const navigation = useNavigation()
  useEffect(() => {
    ;(async () => {
      try {
        const authResponse = await Auth?.currentAuthenticatedUser()
        if (authResponse?.signInUserSession?.accessToken?.payload?.sub) {
          navigation.navigate('home')
        } else {
          navigation.navigate('register')
        }
      } catch (error) {
        navigation.navigate('register')
        console.log({ error })
      }
    })()
  }, [])

  return <Container />
}

export const Stacks = (Stack) => {
  const [user, setUser] = useState(null)
  const [questionId, setQuestionId] = useState(0)
  const [profileImg, setProfileImg] = useState(null)
  const [spotifyUser, setSpotifyUser] = useState(null)
  const [accessCode, setAccessCode] = useState(null)

  useEffect(() => {
    ;(async () => {
      const getTempProfile = await getItemAsync('SIGNING_RESPONSE')
      const tempProfile = await JSON.parse(getTempProfile)
      const response = await getUserById(tempProfile?.sub)
      await setItemAsync('USER_PROFILE', JSON.stringify(response))
      const tempProfileImage = await getImagesByFolderId(
        response?.photoFolderCode,
      )
      const profileImageArr = tempProfileImage?.filter(
        (e) => e.name === 'PROFILE-IMG.JPG',
      )
      console.log(profileImageArr[0]?.url)
      setUser(response)
      setProfileImg(profileImageArr[0]?.url)
      const getAuthCode = await getItemAsync('code')
      setAccessCode(getAuthCode)
    })()
  }, [])

  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack.Group>
      <Stack.Screen name='login'>
        {(screenProps) => (
          <Login {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='launch'>
        {(screenProps) => (
          <Launch {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='register'>
        {(screenProps) => (
          <Register {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='verification'>
        {(screenProps) => (
          <Verification {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='splash'>
        {(screenProps) => (
          <Splash {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='forgotPassword'>
        {(screenProps) => (
          <ForgotPassword {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='resetPassword'>
        {(screenProps) => (
          <ResetPassword {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='profile'>
        {(screenProps) => (
          <>
            <Header hasSmallLogo hasRightClose hasHeaderLogo={false} />
            <Profile
              {...screenProps}
              colors={colors}
              user={user}
              t={t}
              setProfileImg={setProfileImg}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='notification'>
        {(screenProps) => (
          <>
            <Header hasSmallLogo hasRightClose hasHeaderLogo={false} />
            <Notification {...screenProps} colors={colors} user={user} t={t} />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='updateProfile'>
        {(screenProps) => (
          <>
            <Header
              hasSmallLogo
              hasRightClose
              hasHeaderLogo={false}
              profileImg={profileImg}
            />
            <ProfileUpdate
              {...screenProps}
              colors={colors}
              user={user}
              t={t}
              setUser={setUser}
              setProfileImg={setProfileImg}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='menu'>
        {(screenProps) => (
          <>
            <Header hasClose profileImg={profileImg} />
            <Menu {...screenProps} colors={colors} user={user} t={t} />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='onboard'>
        {(screenProps) => (
          <Onboard
            {...screenProps}
            colors={colors}
            user={user}
            t={t}
            questionId={questionId}
            setQuestionId={setQuestionId}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
      <Stack.Screen name='logout'>
        {(screenProps) => (
          <LogOut {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='onboardComplete'>
        {(screenProps) => (
          <OnboardComplete {...screenProps} colors={colors} user={user} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='home'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <Home
              {...screenProps}
              colors={colors}
              user={user}
              t={t}
              setUser={setUser}
              setProfileImg={setProfileImg}
              spotifyUser={spotifyUser}
              getCode={accessCode}
            />
          </>
        )}
      </Stack.Screen>

      <Stack.Screen name='contest'>
        {(screenProps) => (
          <ContestLayout user={user}>
            <>
              <Header profileImg={profileImg} />
              <Contest
                {...screenProps}
                colors={colors}
                user={user}
                t={t}
                setUser={setUser}
                setProfileImg={setProfileImg}
                spotifyUser={spotifyUser}
                getCode={accessCode}
              />
            </>
          </ContestLayout>
        )}
      </Stack.Screen>

      <Stack.Screen name='contestArtists'>
        {(screenProps) => (
          <ContestLayout user={user}>
            <>
              <Header profileImg={profileImg} />
              <ContestArtists
                {...screenProps}
                colors={colors}
                user={user}
                t={t}
                setUser={setUser}
                setProfileImg={setProfileImg}
                spotifyUser={spotifyUser}
                getCode={accessCode}
              />
            </>
          </ContestLayout>
        )}
      </Stack.Screen>

      <Stack.Screen name='contestWinners'>
        {(screenProps) => (
          <ContestLayout user={user}>
            <>
              <Header profileImg={profileImg} />
              <ContestWinners
                {...screenProps}
                colors={colors}
                user={user}
                t={t}
                setUser={setUser}
                setProfileImg={setProfileImg}
                spotifyUser={spotifyUser}
                getCode={accessCode}
              />
            </>
          </ContestLayout>
        )}
      </Stack.Screen>

      <Stack.Screen name='contestInfo'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <ContestInfo
              {...screenProps}
              colors={colors}
              user={user}
              t={t}
              setUser={setUser}
              setProfileImg={setProfileImg}
              spotifyUser={spotifyUser}
              getCode={accessCode}
            />
          </>
        )}
      </Stack.Screen>

      <Stack.Screen name='contestArtistDetails'>
        {(screenProps) => (
          <ContestLayout user={user}>
            <>
              <Header profileImg={profileImg} />
              <ContestArtistDetails
                {...screenProps}
                colors={colors}
                user={user}
                t={t}
                setUser={setUser}
                setProfileImg={setProfileImg}
                spotifyUser={spotifyUser}
                getCode={accessCode}
              />
            </>
          </ContestLayout>
        )}
      </Stack.Screen>

      <Stack.Screen name='followChallenge'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <FollowChallenge
              {...screenProps}
              colors={colors}
              user={user}
              t={t}
            />
          </>
        )}
      </Stack.Screen>

      <Stack.Screen name='challengeCompleted'>
        {(screenProps) => (
          <ChallengeCompleted
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
          />
        )}
      </Stack.Screen>
      <Stack.Screen name='photoChallenge'>
        {(screenProps) => (
          <PhotoChallenge {...screenProps} user={user} colors={colors} t={t} />
        )}
      </Stack.Screen>
      <Stack.Screen name='playlist-tracks'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <PlaylistTrack
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              spotifyUser={spotifyUser}
              getCode={accessCode}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='profile-comparison'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <ProfileComparison
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              spotifyUser={spotifyUser}
              getCode={accessCode}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='challenges'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <Challenges {...screenProps} user={user} colors={colors} t={t} />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='your-coupons'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <YourCoupon {...screenProps} user={user} colors={colors} t={t} />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen
        name='scanner'
        options={{
          title: 'scanner',
          headerStyle: {
            backgroundColor: '#f4511e',
          },
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}
      >
        {(screenProps) => (
          <>
            <Header hasSmallLogo hasRightClose hasHeaderLogo={false} />
            <Scanner
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='launchHome'>
        {(screenProps) => (
          <LaunchHome
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
      <Stack.Screen name='events'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <Events
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='verificationComplete'>
        {(screenProps) => (
          <VerificationComplete
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
      <Stack.Screen name='inviteFriends'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <InviteFriend
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='event-memories'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <EventMemories
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='redeem'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <Redeem
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='festivalMap'>
        {(screenProps) => (
          <FestiveMap
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
      <Stack.Screen name='inviteeHome'>
        {(screenProps) => (
          <InviteeHome
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
      <Stack.Screen name='your-points'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <YourPoints
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
      <Stack.Screen name='voteChallenge'>
        {(screenProps) => (
          <>
            <Header profileImg={profileImg} />
            <VoteChallenge
              {...screenProps}
              user={user}
              colors={colors}
              t={t}
              setUser={setUser}
            />
          </>
        )}
      </Stack.Screen>
    </Stack.Group>
  )
}
