import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigation, CommonActions } from '@react-navigation/native'
import { Container } from '@nowon/container'
import { deleteItemAsync } from '../../utils/SecureStore'

export const LogOut = (props) => {
  const navigation = useNavigation()

  const logout = async () => {
    await Auth.signOut()
    await deleteItemAsync('SIGNING_RESPONSE')
    await deleteItemAsync('USER_PROFILE')
    await deleteItemAsync('referralCode')
    await deleteItemAsync('code')
    await deleteItemAsync('ACCESS_TOKEN')
    await deleteItemAsync('REFRESH_TOKEN')
    await deleteItemAsync('EXPIRE_IN')
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'login' }],
      }),
    )
  }

  useEffect(() => {
    logout()
  }, [props])

  return <Container />
}
