import React, { useEffect, useState } from 'react'
import { Container } from '@nowon/container'
import { Text } from 'react-native-paper'
import { useIsFocused } from '@react-navigation/native'
import { View, StyleSheet } from 'react-native'
import { DNAcard, UserCard } from '@nowon/card'
import { useQuery } from '@tanstack/react-query'
import { getMatchedPercentage } from '../../apiGraphql'
import { SPOTIFY_ADMIN_USER_ID } from '../../config'

export const ProfileComparison = (props) => {
  const { colors, t, user } = props
  const [userData, setUserData] = useState([])
  const [adminData, setAdminData] = useState([])
  const [allUserData, setAllUserData] = useState()
  const isRendered = useIsFocused()

  const { data: matchedUsers } = useQuery(
    ['getMatchedPercentage'],
    async () => {
      const response = await getMatchedPercentage()
      setUserData(response.filter((item) => item?.userId === user?.userId))
      setAdminData(
        response.filter((item) => item?.userId === SPOTIFY_ADMIN_USER_ID),
      )

      return response.filter(
        (e) =>
          e?.userId !== user?.userId && e?.userId !== SPOTIFY_ADMIN_USER_ID,
      )
    },
    {
      enabled: !!user?.userId,
      initialData: [],
    },
  )
  useEffect(() => {
    matchedUsers.sort(
      (a, b) =>
        b.matchedPercentages[0].totalPercentage -
        a.matchedPercentages[0].totalPercentage,
    )
    setAllUserData(matchedUsers)
  }, [matchedUsers])

  const {
    totalPercentage,
    matchedSongsPercentage,
    matchedGenrePercentage,
    matchedArtistPercentage,
  } = userData[0]?.matchedPercentages[0] || ''

  return (
    <Container>
      {userData.length > 0 ? (
        <UserCard
          userImage={userData[0]?.spotifyUserImage[0]?.url}
          adminImage={adminData[0]?.spotifyUserImage[0]?.url}
          userName={userData[0]?.spotifyUserName}
          percentage={totalPercentage}
          matchedSongs={matchedSongsPercentage}
          matchedGenres={matchedGenrePercentage}
          matchedArtist={matchedArtistPercentage}
        />
      ) : (
        <Text style={styles.textStyle} variant='headlineMedium'>
          connect spotify
        </Text>
      )}

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <Text
          variant='titleMedium'
          style={{
            fontWeight: 600,
            color: colors.textPrimary,
          }}
        >
          {t('COMMUNITY_DNA')}
        </Text>{' '}
        <View
          style={{
            height: 1,
            width: '37%',

            borderColor: 'rgba(255, 255, 255, 0.2)',
            borderWidth: 0.4,
            marginVertical: 10,
          }}
        />
      </View>
      {matchedUsers.map((e) => (
        <View style={{ paddingVertical: 5 }}>
          <DNAcard
            matchedArtists={e?.matchedPercentages[0]?.matchedArtistPercentage}
            matchedSongs={e?.matchedPercentages[0]?.matchedSongsPercentage}
            matchedGenres={e?.matchedPercentages[0]?.matchedGenrePercentage}
            totalPercentage={e?.matchedPercentages[0]?.totalPercentage}
            name={e?.spotifyUserName}
            image={e?.spotifyUserImage[0]?.url}
          />
        </View>
      ))}
    </Container>
  )
}

const styles = StyleSheet.create({
  textStyle: {
    flexDirection: 'row',
    color: '#5C5C5C',
    fontFamily: 'Adopekid-Kanit-RC-Stencil',
    alignSelf: 'center',
    paddingVertical: 50,
  },
})
