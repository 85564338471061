import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgTickIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 57 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='m2 15.865 16.054 19.703L56 2'
      stroke={props.color}
      strokeWidth={3}
    />
  </Svg>
)

export default SvgTickIcon
