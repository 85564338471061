import React from 'react'
import { Card, useTheme } from 'react-native-paper'
import { Image, View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@nowon/circularProgress'
import { Text } from '@nowon/components'
import ProfileImage from 'core/src/assets/profileImage.png'

const DNAcard = (props) => {
  const {
    matchedArtists,
    matchedGenres,
    matchedSongs,
    name,
    image,
    totalPercentage,
  } = props
  const { t } = useTranslation()
  const { colors } = useTheme()

  const matchPercentArray = [
    {
      title: 'MATCH_SONGS',
      icon: 'MusicCordIcon',
      percentage: matchedSongs,
      circleColor: '#880EAE',
    },
    {
      title: 'MATCH_GENRE',
      icon: 'GenreIcon',
      percentage: matchedGenres,
      circleColor: '#B64D53',
    },
    {
      title: 'MATCH_ARTISTS',
      icon: 'ArtistIcon',
      percentage: matchedArtists,
      circleColor: '#2E7BA1',
    },
  ]

  return (
    <Card
      style={{
        borderRadius: 15,
        paddingHorizontal: 12,
        paddingVertical: 12,
        width: '100%',
        backgroundColor: colors.backgroundSurface,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 12,
            width: '50%',
          }}
        >
          <Image
            source={image || ProfileImage}
            style={{
              height: 49,
              width: 49,
              borderRadius: 100,
            }}
          />
          <View style={{ width: '70%' }}>
            <Text variant='labelLarge'>{name}</Text>
          </View>
        </View>
        <View style={{ paddingVertical: 5, gap: 4 }}>
          <Text variant='labelUltraSmall' style={{ color: '#ffffff33' }}>
            {t('OVERALL_DNA')}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 10,
            }}
          >
            <Text variant='labelMedium'>{totalPercentage?.toFixed(0)}%</Text>
            <CircularProgress
              bgColor='rgba(255, 255, 255, 0.2)'
              pgColor='rgba(110, 228, 228, 1)'
              size={30}
              strokeWidth={3}
              iconName='DnaIcon'
              progressPercent={totalPercentage}
            />
          </View>
        </View>
      </View>
      <View
        style={{
          borderColor: 'rgba(255, 255, 255, 0.2)',
          borderWidth: 0.4,
          marginVertical: 10,
        }}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {matchPercentArray.map((item) => (
          <View style={{ gap: 10, alignItems: 'center' }}>
            <Text variant='labelMedium' style={{ color: '#ffffff33' }}>
              {t(item.title)}
            </Text>
            <View style={styles.matchPercent}>
              <Text variant='labelLarge'>{item?.percentage?.toFixed(0)}%</Text>
              <CircularProgress
                bgColor='rgba(255, 255, 255, 0.2)'
                pgColor={item?.circleColor}
                size={38}
                strokeWidth={3}
                iconName={item?.icon}
                progressPercent={item?.percentage}
              />
            </View>
          </View>
        ))}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  matchPercent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
})

export default DNAcard
