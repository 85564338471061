import React from 'react'
import { Surface, useTheme } from 'react-native-paper'
import { View, TouchableOpacity, Linking } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icon } from '@nowon/icons'
import { useTranslation } from 'react-i18next'
import { Text } from '@nowon/components'

const ChallengesCard = (props) => {
  const { item, isFromHome } = props
  const navigation = useNavigation()
  const { colors } = useTheme()
  const { t } = useTranslation()

  const getDescription = () => {
    if (item?.status === 'COMPLETED') {
      if (item?.totalPoints && item?.discount)
        return `${t('ACHIEVED')} | ${item?.totalPoints} ${t('PTS')} + ${
          item?.discount
        }`
      if (item?.totalPoints)
        return `${t('ACHIEVED')} | ${item?.totalPoints} ${t('PTS')}`
      if (item?.discount) return `${t('ACHIEVED')} | ${item?.discount}`
    } else {
      if (item?.totalPoints && item?.discount)
        return `${t('PENDING')} | ${item?.totalPoints} ${t('PTS')} + ${
          item?.discount
        }`
      if (item?.totalPoints)
        return `${t('PENDING')} | ${item?.totalPoints} ${t('PTS')}`
      if (item?.discount) return `${t('PENDING')} | ${item?.discount}`
    }
  }

  const getIcon = () => {
    if (item?.challengeType === 'FOLLOW_INSTAGRAM') {
      return 'InstagramLogo'
    }
    return 'FlagIcon'
  }

  const handleNavigation = () => {
    if (item?.challengeType === 'ONBOARDING') {
      return navigation.navigate('onboard')
    }
    if (item?.challengeType === 'COMPLETE_SURVEY')
      return Linking.openURL(
        'https://docs.google.com/forms/d/e/1FAIpQLSemDilXqZqK4aZQZ6BvaDD3Q3VjkBWKSs7xVtQQZXjDSDlfaw/viewform',
      )
    if (item?.challengeType === 'VOTE3FAVORITE') {
      return navigation.navigate('voteChallenge', {
        challengeId: item?.challengeId,
      })
    }
    console.log(item?.status)
    return navigation.navigate('followChallenge', {
      challengeId: item?.challengeId,
    })
  }

  const cardColor = () => {
    if (item?.status === 'PENDING') return 'rgba(161,180,255,0.2)'
    if (item?.status === 'COMPLETED') return 'rgba(110, 228, 228, 0.1)'
    return 'rgba(51, 51, 55, 1)'
  }
  return (
    <TouchableOpacity
      onPress={handleNavigation}
      disabled={item?.status === 'COMPLETED' || item?.status === 'NOT_ACHIEVED'}
    >
      <Surface
        elevation={1}
        style={{
          flexDirection: 'row',
          borderRadius: 8,
          marginTop: 8,
          backgroundColor: cardColor(),
          paddingHorizontal: 20,
          paddingVertical: 8,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flex: 1,
            marginRight: 30,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            gap: 20,
          }}
        >
          <View
            style={{
              backgroundColor: 'rgba(255, 251, 251, 0.1)',
              width: 32,
              height: 32,
              borderRadius: 50,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon name={getIcon()} color={colors.textPrimary} width={14} />
          </View>

          <View style={{ width: '80%' }}>
            <Text
              variant={isFromHome ? 'brandBodyLarge' : 'titleMedium'}
              colorVariant='primaryVariant'
              style={{ fontWeight: 600 }}
            >
              {item?.title}
            </Text>

            {item?.status === 'NOT_ACHIEVED' ? (
              <Text variant='labelLarge' style={{ color: '#99B5D4' }}>
                {t('NOT_ACHIEVED')}
              </Text>
            ) : (
              <Text variant='labelLarge' colorVariant='primaryOn'>
                {getDescription()}
              </Text>
            )}
          </View>
        </View>
        <View
          style={{
            paddingVertical: 5,
            paddingHorizontal: 7,
            alignSelf: 'center',
          }}
        >
          {item?.status === 'NOT_ACHIEVED' || (
            <Surface
              style={{
                borderRadius: 50,
                width: 21,
                height: 21,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.primary,
              }}
              elevation={0}
            >
              <Icon
                name='ChevronRightIcon'
                height={11}
                color={colors.textPrimary}
              />
            </Surface>
          )}
        </View>
      </Surface>
    </TouchableOpacity>
  )
}

export default ChallengesCard
