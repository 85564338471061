import React from 'react'
import { Text } from 'react-native-paper'
import { Container } from '@nowon/container'
import { useQuery } from '@tanstack/react-query'
import { useIsFocused } from '@react-navigation/native'
import { NotificationCard } from '@nowon/card'
import { TouchableOpacity } from 'react-native'
import {
  getAllNotificationsByUserId,
  updateNotificationStatusToRead,
} from '../../apiGraphql'

export const Notification = (props) => {
  const { t, user } = props

  const isFocussed = useIsFocused()
  const { data: notificationData, refetch } = useQuery(
    ['getAllNotificationsByUserId'],
    async () => {
      const response = await getAllNotificationsByUserId(user?.userId)
      return response
    },
    {
      enabled: !!user?.userId && isFocussed,
    },
    {
      initialData: [],
    },
  )

  const handleRead = async (item) => {
    const response = await updateNotificationStatusToRead({
      userId: user?.userId,
      notificationId: item?.notificationId,
    })
    if (response) {
      refetch()
    }
  }

  console.log(notificationData)

  return (
    <Container>
      <Text variant='titleLarge' style={{ color: '#6EC1E4' }}>
        {t('NOTIFICATION')}
      </Text>
      {notificationData?.map((nD) => (
        <TouchableOpacity
          onPress={() => handleRead(nD)}
          key={nD.notificationId}
        >
          <NotificationCard item={nD} />
        </TouchableOpacity>
      ))}
    </Container>
  )
}
