import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgGiftIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 28 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M12.293 5.705V3.5C9.646.53 6.088.059 5.323 0c-.706.853-1 2.059-1.117 3.176 1.146-.059 4.44-.03 8.087 2.53Z'
      fill='#5C5C5C'
    />
    <Path
      d='M11.352 5.97h.294c-3.47-2.293-6.588-2.264-7.5-2.205-.029.735-.029 1.47.06 2.205h7.146ZM12.881 3.647h2.235V5.97h-2.235V3.647ZM23.792 3.176C23.674 2.059 23.351.882 22.645 0a11.246 11.246 0 0 0-6.94 3.47v2.206c3.646-2.53 6.94-2.559 8.087-2.5Z'
      fill='#5C5C5C'
    />
    <Path
      d='M16.351 5.97h7.411c.089-.735.089-1.47.06-2.205-.913-.06-4.03-.088-7.47 2.205ZM16.057 11.529v-4.97H11.94v4.97h4.117ZM1.853 12.117v15.498c0 .323.265.618.618.618h9.88V12.116H1.854ZM11.352 6.559H.412A.402.402 0 0 0 0 6.97v4.117c0 .235.176.412.412.412h10.94V6.558ZM27.585 6.559h-10.94v4.97h10.94a.402.402 0 0 0 .412-.412V7c.03-.236-.176-.441-.412-.441ZM15.645 12.117v16.116h9.881a.621.621 0 0 0 .618-.618V12.117H15.645ZM12.94 12.117h2.118v16.116H12.94V12.117Z'
      fill='#5C5C5C'
    />
  </Svg>
)

export default SvgGiftIcon
