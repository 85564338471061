import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgFaceBookSolidIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 10 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M7.6 3.433h1.567V.783A21.758 21.758 0 0 0 6.883.667c-2.266 0-3.816 1.383-3.816 3.916v2.184H.508v2.966h2.559v7.6h3.066v-7.6h2.55l.384-2.966H6.133V4.875c0-.875.234-1.442 1.467-1.442Z'
      fill='#000'
    />
  </Svg>
)

export default SvgFaceBookSolidIcon
