import React from 'react'
import { Surface } from 'react-native-paper'
import { ImageBackground, Image } from 'react-native'

const BackgroundContainer = ({
  children,
  backgroundColor = 'rgba(255,255,255, 0.15)',
}) => (
  <ImageBackground
    source={require('../assets/backgroundPrimary.jpg')}
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      paddingBottom: '50px',
    }}
  >
    <Image
      source={require('../assets/logo.png')}
      style={{
        height: '40px',
        width: '150px',
        marginVertical: '20px',
      }}
    />
    <Surface
      style={{
        backgroundColor,
        borderRadius: 24,
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingTop: '16px',
        paddingBottom: '21px',
        maxWidth: '350px',
        width: '100%',
      }}
      elevation={0}
    >
      {children}
    </Surface>
  </ImageBackground>
)

export default BackgroundContainer
