import React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { Surface } from 'react-native-paper'
import RapCitySampleTicket from 'core/src/assets/RapCitySampleTicket.png'

const ToolTip = ({ visible }) =>
  visible ? (
    <Surface style={styles.tooltipContainer}>
      <Surface
        elevation={0}
        style={{
          flex: 1,
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          source={RapCitySampleTicket}
          style={{ width: 274, height: 171 }}
          resizeMode='contain'
        />
      </Surface>
      <View
        style={{
          height: 20,
          width: 20,
          backgroundColor: '#FFFFFF',
          transform: [{ rotate: '45deg' }],
          position: 'absolute',
          right: 15,
          bottom: -5,
        }}
      />
    </Surface>
  ) : (
    <> </>
  )

const styles = StyleSheet.create({
  tooltipContainer: {
    height: 171,
    width: '90%',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: -128,
  },
})

export default ToolTip
