import React from 'react'
import { useTheme } from 'react-native-paper'
import { ActivityIndicator, Modal, View } from 'react-native'
import { useSelector } from 'react-redux'

const Loader = (props) => {
  const { colorVariant = 'primary' } = props
  const { isLoading } = useSelector((state) => state?.loading)
  const { colors } = useTheme()
  return (
    <Modal
      visible={isLoading}
      transparent
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <View
        style={{
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator color={colors[colorVariant]} size='large' />
      </View>
    </Modal>
  )
}
export default Loader
