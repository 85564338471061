import React from 'react'
import { Text, Button, useTheme, Surface } from 'react-native-paper'
import { Linking, View } from 'react-native'
import { EmailTextInput } from '@nowon/components'
import { BackgroundContainer } from '@nowon/container'
import BlurWidget from '@nowon/components/src/Blur'
import UseForgotPassword from './useForgotPassword'
import { supportMail } from '../../config'

export const ForgotPassword = (props) => {
  const { colors } = useTheme()
  const { t, navigation } = props
  const { email, setEmail, forgotPassword } = UseForgotPassword()

  return (
    <BackgroundContainer>
      <>
        <BlurWidget borderRadius={16} />
        <View style={{ gap: 200 }}>
          <View style={{ gap: '12px' }}>
            <Text
              variant='headlineLarge'
              style={{
                color: colors.primary,
              }}
            >
              {t('FORGOT_PASS')}
            </Text>
            <EmailTextInput
              mode='outlined'
              placeholder={t('EMAIL_ID')}
              style={{
                backgroundColor: colors.neutralLight,
                width: '287px',
              }}
              value={email}
              onChangeText={(text) => setEmail(text.toLowerCase().trim())}
              // onValidate={(isValid) => {
              //   console.log({ isValid })
              // }}
              errorMessage={t('EMAIL_INVALID')}
            />
          </View>
          <View style={{ gap: 12 }}>
            <Button
              mode='contained'
              contentStyle={{ height: 48, backgroundColor: colors.primary }}
              style={{
                borderRadius: 8,
              }}
              labelStyle={{
                color: 'white',
                fontSize: '16px',
                fontWeight: 400,
              }}
              onPress={() => {
                forgotPassword()
              }}
            >
              {t('ACTIONS.RETRIEVE')}
            </Button>
            <Surface
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              elevation={0}
            >
              <Text
                variant='labelLarge'
                style={{
                  color: colors.primary,
                  fontSize: 18,
                  lineHeight: 17,
                  fontWeight: 400,
                }}
                onPress={() => Linking.openURL(`mailto:${supportMail}`)}
              >
                {t('SUPPORT')}
              </Text>
              <Text
                variant='labelLarge'
                style={{
                  color: colors.primary,
                  cursor: 'pointer',
                  fontSize: 18,
                  lineHeight: 17,
                  fontWeight: 400,
                }}
                onPress={() => {
                  navigation.navigate('login')
                }}
              >
                {' '}
                {t('BACK_LOGIN')}
              </Text>
            </Surface>
          </View>
        </View>
      </>
    </BackgroundContainer>
  )
}
