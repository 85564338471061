import { createStore, applyMiddleware, compose } from 'redux'
import AsyncStorage from '@react-native-community/async-storage'
import { persistStore, persistReducer } from 'redux-persist'

// Imports: Redux
import rootReducer from './reducers/index'

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage: AsyncStorage,
  // Whitelist (Save Specific Reducers)
  whitelist: ['user'],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
}

// Redux dev tools
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)
// Redux: Store
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware()))
// Middleware: Redux Persist Persister
const persistor = persistStore(store)
// Exports
export { store, persistor }
