/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Container } from '@nowon/container'
import { Button, Surface, TextInput } from 'react-native-paper'
import { TouchableOpacity } from 'react-native-web'
import { useQuery } from '@tanstack/react-query'
import { Text } from '@nowon/components'
import { useIsFocused } from '@react-navigation/native'
import {
  getAllChallengesByUserId,
  updateChallengeStatusToCompletedById,
  updateUserArtistsPreference,
} from '../../apiGraphql'

export const VoteChallenge = (props) => {
  const { navigation, user, colors, t } = props
  const challengeId = Number(props?.route?.params?.challengeId)
  const [favouriteArtists, setFavouriteArtists] = useState([])
  const [selectedCountry, setSelectedCountry] = useState([])
  const [question, setQuestion] = useState()
  const [challengeCompleted, setChallengeCompleted] = useState()
  const country = [
    t('GERMANY'),
    t('SWITZERLAND'),
    t('FRANCE'),
    t('ITALY'),
    t('ENGLAND'),
    t('USA'),
  ]
  const [questionId, setQuestionId] = useState(0)
  const isRendered = useIsFocused()
  const { data: challengeDetail } = useQuery(
    ['getAllChallengesByUserId'],
    async () => {
      const response = await getAllChallengesByUserId(user?.userId)
      if (response) {
        const filteredData = response?.find(
          (r) => r.challengeId === challengeId,
        )
        return filteredData
      }
    },
    {
      enabled: !!user?.userId && !!challengeId && isRendered,
    },
    {
      initialData: [],
    },
  )
  useEffect(() => {
    if (challengeDetail?.length > 0) {
      setChallengeCompleted(challengeDetail?.status === 'COMPLETED')
    }
  }, [challengeDetail, isRendered])

  useEffect(() => {
    if (challengeDetail) {
      setQuestion(challengeDetail?.description?.split('2.'))
    }
  }, [challengeDetail, isRendered])

  function handleInputText(value, index) {
    const artistArray = favouriteArtists
    artistArray[index] = value
    setFavouriteArtists(artistArray)
  }

  function handleSelectedCountry(item) {
    if (selectedCountry.length + 1 < 4) {
      setSelectedCountry([...selectedCountry, item])
    }
    if (selectedCountry.includes(item)) {
      const newSelectedCountry = selectedCountry.filter(
        (countryName) => countryName !== item,
      )
      setSelectedCountry(newSelectedCountry)
    }
  }

  function getCtaText() {
    if (questionId === 0) return t('ACTIONS.CONTINUE')
    if (questionId === 1) return t('ACTIONS.OK')
  }
  async function handleSubmission() {
    if (questionId === 0) setQuestionId(1)
    if (!challengeCompleted) {
      if (questionId === 1) {
        try {
          const response = await updateUserArtistsPreference({
            userId: user?.userId,
            favouriteArtists,
            preferredArtistRegion: selectedCountry,
          })
          const updateChallengeResponse =
            await updateChallengeStatusToCompletedById({
              userId: user?.userId,
              challengeId,
            })
          if (response && updateChallengeResponse)
            navigation.navigate('challengeCompleted', {
              challengeId,
              status: updateChallengeResponse?.status,
              challengeType: updateChallengeResponse?.challengeType,
            })
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
  return (
    <Container>
      <Surface elevation={0} style={{ height: '100vh', flex: 1 }}>
        <Surface elevation={0} style={{ gap: 20, marginBottom: 20 }}>
          <Text variant='bodyMedium' colorVariant='textPrimary'>
            <TouchableOpacity onPress={() => navigation.navigate('challenges')}>
              {t('CHALLENGES')}
            </TouchableOpacity>
            {'>'} {challengeDetail?.title}
          </Text>
          <Text variant='headlineLarge' style={{ color: colors.primary }}>
            {challengeDetail?.title}
          </Text>
        </Surface>
        <Surface
          elevation={0}
          style={{ gap: 10, alignItems: 'center', justifyContent: 'center' }}
        >
          {challengeCompleted && (
            <Text
              variant='labelLarge'
              colorVariant='textPrimary'
              style={{
                marginTop: 20,
                alignSelf: 'flex-start',
              }}
            >
              {t('CHALLENGE_ALREADY_COMPLETED')}
            </Text>
          )}
          <Text
            variant='headlineSmall'
            style={{ color: colors.primary, marginBottom: 20 }}
          >
            {question !== undefined
              ? questionId === 0
                ? question[0]
                : `2.${question[1]}`
              : ''}
          </Text>
          {questionId === 0 ? (
            <>
              <TextInput
                placeholder={t('ARTIST_NAME_1')}
                style={{
                  height: 40,
                  width: 320,
                  borderRadius: 4,
                  backgroundColor: 'rgba(255, 251, 255, 0.1)',
                  paddingRight: 17,
                  marginBottom: 16,
                  position: 'relative',
                }}
                mode='outlined'
                onChange={(value) => handleInputText(value.target.value, 0)}
                activeOutlineColor={colors.primary}
                outlineColor='transparent'
                placeholderTextColor='rgba(246, 246, 246, 0.5)'
                disabled={challengeCompleted}
                value={
                  challengeCompleted
                    ? user?.favouriteArtists[0]
                    : favouriteArtists[0]
                }
              />
              <TextInput
                placeholder={t('ARTIST_NAME_2')}
                style={{
                  height: 40,
                  width: 320,
                  borderRadius: 4,
                  backgroundColor: 'rgba(255, 251, 255, 0.1)',
                  paddingRight: 17,
                  marginBottom: 16,
                  position: 'relative',
                }}
                mode='outlined'
                onChange={(value) => handleInputText(value.target.value, 1)}
                activeOutlineColor={colors.primary}
                outlineColor='transparent'
                placeholderTextColor='rgba(246, 246, 246, 0.5)'
                disabled={challengeCompleted}
                value={
                  challengeCompleted
                    ? user?.favouriteArtists[1]
                    : favouriteArtists[1]
                }
              />
              <TextInput
                placeholder={t('ARTIST_NAME_3')}
                style={{
                  height: 40,
                  width: 320,
                  borderRadius: 4,
                  backgroundColor: 'rgba(255, 251, 255, 0.1)',
                  paddingRight: 17,
                  marginBottom: 16,
                  position: 'relative',
                }}
                mode='outlined'
                onChange={(value) => handleInputText(value.target.value, 2)}
                activeOutlineColor={colors.primary}
                outlineColor='transparent'
                placeholderTextColor='rgba(246, 246, 246, 0.5)'
                disabled={challengeCompleted}
                value={
                  challengeCompleted
                    ? user?.favouriteArtists[2]
                    : favouriteArtists[2]
                }
              />
            </>
          ) : (
            <Surface
              elevation={0}
              style={{
                flexDirection: 'row',
                marginBottom: 23,
                flex: 1,
                gap: 15,
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {country?.map((item) => (
                <TouchableOpacity
                  onPress={() => handleSelectedCountry(item)}
                  key={item}
                >
                  <Surface
                    elevation={0}
                    style={{
                      borderRadius: 8,
                      borderColor: colors.primary,
                      borderWidth: 1,
                      backgroundColor:
                        selectedCountry?.includes(item) ||
                        user?.preferredArtistRegion?.includes(item)
                          ? colors.primary
                          : 'transparent',
                      paddingLeft: 23,
                      paddingTop: 10,
                      height: 86,
                      width: 156,
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      variant='titleLarge'
                      style={{
                        color:
                          selectedCountry?.includes(item) ||
                          user?.preferredArtistRegion?.includes(item)
                            ? colors.textPrimary
                            : '#6F8DB6',
                        marginBottom: 16,
                        width: 120,
                      }}
                    >
                      {t(item)}
                    </Text>
                  </Surface>
                </TouchableOpacity>
              ))}
            </Surface>
          )}
          <Button
            mode='outlined'
            contentStyle={{ height: 32 }}
            style={{
              borderRadius: 50,
              width: 195,
            }}
            labelStyle={{
              color: 'white',
              fontSize: '16px',
              fontWeight: 400,
            }}
            onPress={() => handleSubmission()}
            disabled={challengeCompleted && questionId === 1}
          >
            {getCtaText()}
          </Button>
        </Surface>
      </Surface>
    </Container>
  )
}
