import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgFaceBookIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.5 12.07C24.5 5.405 19.127 0 12.5 0S.5 5.404.5 12.07c0 6.026 4.388 11.02 10.125 11.925V15.56H7.578v-3.49h3.047V9.412c0-3.025 1.791-4.696 4.533-4.696 1.313 0 2.686.236 2.686.236v2.97H16.33c-1.491 0-1.956.93-1.956 1.885v2.265h3.328l-.532 3.489h-2.796v8.435c5.737-.906 10.125-5.9 10.125-11.924Z'
      fill='#1877F2'
    />
  </Svg>
)

export default SvgFaceBookIcon
