import React from 'react'
import { View } from 'react-native'
import { Card, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@nowon/components'

const EventCard = (props) => {
  const { item } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  const navigation = useNavigation()
  return (
    <Card
      elevation={0}
      style={{
        width: '100%',
        borderRadius: 24,
        paddingVertical: 22,
        paddingHorizontal: 28,
        backgroundColor: colors.backgroundSurface,
      }}
      onPress={() => navigation.navigate('events')}
    >
      <Text variant='brandBodyLarge' colorVariant='textPrimary'>
        {t('TIME_TABLE')}
      </Text>
      <Text
        variant='brandtTitleLarge'
        colorVariant='textPrimary'
        style={{ marginTop: 15 }}
      >
        {item?.eventName}
      </Text>
      <Text
        variant='compactLarge'
        colorVariant='textPrimary'
        style={{ marginTop: 10 }}
      >
        {item?.startTime?.hours} :{' '}
        {item?.startTime?.minutes?.toString()?.padStart(2, '0')}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text variant='compactLarge' colorVariant='textPrimary'>
          {item?.eventDate}
        </Text>
        <Text variant='compactLarge' colorVariant='textPrimary'>
          {item?.eventDuration}
        </Text>
      </View>
      {/* <Button
        mode='outlined'
        style={{
          backgroundColor: '#fff',
          alignSelf: 'baseline',
          height: 32,
          marginTop: 14,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('VIEW_EVENTS')}
      </Button> */}
    </Card>
  )
}

export default EventCard
