import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgWaveIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 40 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M.76 7.43a.762.762 0 0 1-.51-1.324L6.78.197a.759.759 0 0 1 1.06.04l5.095 5.352L18.379.22A.763.763 0 0 1 19.43.207l5.843 5.453L31.85.179a.76.76 0 0 1 .986.01l6.586 5.742a.76.76 0 0 1-1 1.146L32.327 1.76l-6.594 5.495a.76.76 0 0 1-1.006-.028l-5.8-5.413-5.475 5.4a.852.852 0 0 1-.545.218.76.76 0 0 1-.54-.236L7.25 1.824 1.268 7.237a.76.76 0 0 1-.508.194Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgWaveIcon
