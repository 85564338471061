import React from 'react'
import { Card, Button, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Icon } from '@nowon/icons'
import { Text } from '@nowon/components'

const MemoriesCard = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  return (
    <Card
      style={{
        borderRadius: 24,
        padding: 24,
        width: '100%',
        backgroundColor: colors.backgroundSurface,
      }}
      onPress={() => navigation.navigate('event-memories')}
    >
      <Icon
        name='CameraIcon'
        width={40}
        height={45}
        color={colors.textPrimary}
      />
      <Text
        variant='brandBodyLarge'
        colorVariant='textPrimary'
        style={{
          marginTop: 20,
        }}
      >
        {t('VISIT_RAPCITY')}
      </Text>
      <Button
        mode='outlined'
        style={{
          backgroundColor: '#fff',
          width: '45%',
          height: 32,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 16,
        }}
        onPress={() => navigation.navigate('event-memories')}
      >
        {t('VIEW')}
      </Button>
    </Card>
  )
}

export default MemoriesCard
