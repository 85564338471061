export const instagramPage = process.env.REACT_APP_INSTAGRAM_URL
export const supportMail = process.env.REACT_APP_SUPPORT_MAIL
export const privacyPolicyPage = process.env.REACT_APP_PRIVACY_POLICY
export const appUrl = process.env.REACT_APP_APP_URL
export const buyTicketUrl = process.env.REACT_APP_TICKET_URL
export const apiUrl = process.env.REACT_APP_API_URL
export const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID
export const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI
export const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT
export const RESPONSE_TYPE = 'code'

export const SCOPES = [
  'streaming',
  'user-read-email',
  'user-read-private',
  'user-top-read',
]

export const SPOTIFY_ADMIN_USER_ID = process.env.REACT_APP_SPOTIFY_USER_ID_ADMIN
export const isProduction = process.env.REACT_APP_ENV === 'production'
