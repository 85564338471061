import { TouchableOpacity, StyleSheet } from 'react-native'
import React from 'react'
import { Surface, Text } from 'react-native-paper'
import { Icon } from '@nowon/icons'

const SocialButton = (props) => {
  const { onPress, iconName, label, style } = props
  return (
    <TouchableOpacity onPress={onPress}>
      <Surface style={[styles.authButton, style]}>
        <Icon name={iconName} width={24} height={24} />
        <Text
          variant='labelLarge'
          style={{
            color: '#757575',
            marginLeft: 8,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 18,
          }}
        >
          {label}
        </Text>
      </Surface>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  authButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: 44,
  },
})

export default SocialButton
