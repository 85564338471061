import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const SvgThumbsUpIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox='0 0 48 56'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    accessibilityRole='image'
    {...props}
  >
    <Path
      d='M46.161 27.06a8.297 8.297 0 0 0-6.652-3.297h-7.364l1.64-5.8a6.477 6.477 0 0 0-1.077-5.713 6.476 6.476 0 0 0-5.203-2.588 6.533 6.533 0 0 0-5.333 2.762l-8.006 11.338H6.45a6.328 6.328 0 0 0-6.321 6.32v19.526c0 3.489 2.84 6.328 6.328 6.328h28.78c3.744 0 7.059-2.52 8.056-6.132l4.275-15.453a8.292 8.292 0 0 0-1.406-7.29ZM3.503 49.607V30.08a2.946 2.946 0 0 1 2.944-2.944h6.91v25.424h-6.9a2.958 2.958 0 0 1-2.954-2.955ZM44.314 33.45 40.04 48.904a4.998 4.998 0 0 1-4.805 3.657H16.733V25.979L24.93 14.37c1.163-1.647 3.869-1.694 5.087-.083.6.795.792 1.8.52 2.758l-2.245 7.945a1.687 1.687 0 0 0 1.624 2.147h9.593c1.569 0 3.015.716 3.965 1.966a4.935 4.935 0 0 1 .84 4.347ZM27.662 6.33c.932 0 1.688-.756 1.688-1.688V1.75a1.688 1.688 0 0 0-3.375 0v2.893c0 .934.755 1.688 1.687 1.688ZM19.11 8.886c.33.33.76.495 1.194.495.433 0 .864-.165 1.194-.495.66-.658.66-1.727 0-2.385L19.45 4.454a1.686 1.686 0 0 0-2.387 0 1.684 1.684 0 0 0 0 2.385l2.046 2.047Zm15.915.495c.43 0 .864-.165 1.194-.495l2.047-2.047c.66-.658.66-1.727 0-2.385a1.686 1.686 0 0 0-2.386 0L33.83 6.5a1.684 1.684 0 0 0 0 2.385c.328.33.76.495 1.193.495Z'
      fill={props.color}
    />
  </Svg>
)

export default SvgThumbsUpIcon
