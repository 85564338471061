import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`

const SepartorContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin: 0 0 10px 0px;
`

const Separtor = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &: first-child {
    margin-left: 0;
  }
`

const Title = styled.span`
  font-size: 12px;
  margin-bottom: 5px;
  color: white;
`

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 8px;
`

const SingleDigit = styled.div`
  font-family: Adopekid-Kanit-RC-Stencil;
  position: relative;
  display: flex;
  flex: 0 1 25%;
  height: 20px;
  width: 20px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  padding: 10px 12px;
  color: white;
  justify-content: center;
  z-index: 0;
  &:first-child {
    margin-right: 2px;
  }
`

function Digit({ value, title }) {
  const leftDigit = value >= 10 ? value.toString()[0] : '0'
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString()
  return (
    <Container>
      <DigitContainer>
        <SingleDigit>{leftDigit}</SingleDigit>
        <SingleDigit>{rightDigit}</SingleDigit>
      </DigitContainer>
      <Title>{title}</Title>
    </Container>
  )
}

export default function TimerStyled({ seconds, minutes, hours }) {
  const { t } = useTranslation()

  return (
    <TimerContainer>
      <Digit value={hours} title={ t('CONTEST.HOURS') } addSeparator />
      <Digit value={minutes} title={ t('CONTEST.MINUTES') } addSeparator />
      <SepartorContainer>
        <Separtor />
        <Separtor />
      </SepartorContainer>
      <Digit value={seconds} title={ t('CONTEST.SECONDS') } />
    </TimerContainer>
  )
}
